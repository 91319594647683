import React, {useEffect} from 'react';
import './App.css';
import "./custom-font.css";
import { CreatePOI } from "./components/Map/CreatePOI";
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {ErrorPage} from "./components/ErrorPage";
import {CardListPage} from "./components/Card/Cards";
import {CardPage} from "./components/Card/Card";
import {CreateCardPage} from "./components/Card/CreateCard";
import { HomePage } from './components/HomePage';
import { StyledEngineProvider } from '@mui/material/styles';
import {VisualizePOIs} from "./components/Map/VisualizePOIs";
import {UpdatePOI} from "./components/Map/UpdatePOI";
import {POIOverview} from "./components/Information/POIOverview";
import {Navbar} from "./components/Navbar";
import {Help} from "./components/Help";
import {Box} from "@mui/material";
import { useKeyword } from "./hooks";
import {Login} from "./components/Authentication/Login";
import {PrivateRoutes} from "./components/Authentication/PrivateRoutes";
import AuthProvider from "./components/contexts/AuthContext";
import {KeywordHighlightMap} from "./components/Map/KeywordHighlightMap";
import MeetPage from './components/MeetPage';
import ContactPage from './components/ContactPage'
import { UpdateCardPage } from './components/Card/UpdateCard';
import { ToolList } from './components/ToolList';
import { Health } from './components/Health';

const App: React.FC = () => {
    const {keywords, getAllKeywords} = useKeyword();

    useEffect(() => {
        getAllKeywords();
    }, [getAllKeywords]);

    return (
		<Box className='websiteBox'>
			<StyledEngineProvider injectFirst> {/*to avoid overwrites from mui styles*/}
				<Router>
					<AuthProvider>
						<Box className = 'navbarBox'>
							<Navbar/>
						</Box>
						<Box className = 'pageBox'>
							<Routes>
								<Route path='/' element={<HomePage keywords={keywords}/>}/>
								<Route path="/map" element={<VisualizePOIs keywords={keywords} isPreview={false} />}/>
								<Route path="/map/:filterkeyword" element={<VisualizePOIs keywords={keywords} isPreview={false}/>}/>
								<Route path="/:id/overview" element={<POIOverview/>}/>
								<Route path="/:keyword/:poiName/highlighted" element={<KeywordHighlightMap/>}/>
								<Route element={<PrivateRoutes />}>
									<Route path="/point-of-interest/create" element={<CreatePOI keywords={keywords} getAllKeywords={getAllKeywords}/>}/>
									<Route path="/point-of-interest/update/:tab" element={<UpdatePOI keywords={keywords} getAllKeywords={getAllKeywords}/>}/>
									<Route path="/point-of-interest/update" element={<Navigate to="0" replace />}/>
									<Route path='/cards/create' element={<CreateCardPage keywords={keywords}/>}/>
									<Route path='/card/update/:id' element={<UpdateCardPage keywords={keywords} getAllKeywords={getAllKeywords} />}/>
								</Route>
								<Route path='/cards' element={<CardListPage unFilteredKeywords={keywords} isPreview={false}/>}/>
								<Route path='/cards/:keyword' element={<CardListPage unFilteredKeywords={keywords} isPreview={false}/>}/>
								<Route path='/card/:id' element={<CardPage/>}/>
								<Route path='/toollist' element={<ToolList/>}/>
								<Route path="/meet" element={<MeetPage/>}/>
								<Route path="/join" element={<ContactPage/>}/>
								<Route path="/help" element={<Help/>}/>
								<Route path="/health" element={<Health/>}/>
								<Route path="/login" element={<Login/>}/>
								<Route path="*" element={<ErrorPage/>}/>
							</Routes>
						</Box>
					</AuthProvider>
				</Router>
			</StyledEngineProvider>
		</Box>
	);
};

export default App;

import React, {ChangeEvent, useEffect, useState} from 'react';

import {
    Button,
    Box,
    Typography,
    Container,
    Grid,
    TextField,
    Link,
    Input,
    Divider,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Autocomplete,
    CircularProgress
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import {DialogProps} from "../ConfirmationDialog";
import {useCard, usePointOfInterest} from "../../hooks";
import { CardLevel, CardType, TeFields, TimeUnits, KsFields, SsFields } from './Card';
import { IFile, IFileUpdate, IKeyword, IPointOfInterest, IVideo} from "../../interfaces";
// import background from "../../assets/general_background.png";
import { Snackbar } from '@mui/material';
import {Add, Clear, Delete} from '@mui/icons-material';
import {IHyperlink} from "../../interfaces/IHyperlink";
import { hyperlinkTypes } from './Card';
import { IAuthor } from '../../interfaces/IAuthor';

const snackBarMessage = {
    cardCreated: 'Card Created!',
    emptyTitle: 'Please write a title for the card.',
    emptyBrief: 'Please write a briefing.',
    emptyTime: 'Please define the time needed to complete the card.',
    emptyTimeUnit: 'Please define the time unit.',
    emptyLevel: 'Please define the level for the card.',
    emptyType: 'Please define the type of the card.',
    emptyToolsAndEquipment: 'Please define the tools and equipment or resources needed for the card.',
    emptyTeField: 'Please define if you want tools and equipment or resources for the field.',
    emptyKnowledgeAndSkills: 'Please define the Knowledge and Skills or the Linked to Pedagogical Theories of the card.',
    emptyKsField: 'Please define if you want Knowledge and Skills or the Linked to Pedagogical Theories for the field.',
    emptyMaterials: 'Please define the materials needed for the card.',
    emptyStepByStep: 'Please define at least one step for the card or one item in the Timeline.',
    emptySsField: 'Please define if you want Step by Step or Timeline for the field.',
    emptyHyperlink: 'Please write the hyperlink to add first.',
    emptyTitleHyperlink: 'Please give the hyperlink a title first.',
    emptyKeyword: 'Please write or select a keyword to add first.',
    incompleteInfo: 'Please fill the identification info before creating the card.',
    noKeywords: 'Please add at least one keyword to the card.',
    noImages: 'Please add at least one image to the card.',
    repeatedKeyword: 'Cannot add the same keyword twice.',
    repeatedHyperlink: 'Cannot add the same hyperlink twice.',
    repeatedHlTitle: 'Cannot add the same hyperlink title twice.',
    noHyperlinks: 'Please add at least one Inspiration and one Next Step hyperlink.',
    cardExists: 'A card with this name already exists.',
    maxImages: 'The maximum number of images allowed is 9.',
    videos: 'Videos updated successfully!',
    maxVideos: 'The maximum number of videos allowed is 7.',
    noVideos: 'Please add at least one video to the card.',
    videoDeleted: 'Video was deleted successfully!',
    noContent: 'Please select an image on all the images that you want to add.',
    videoWrongUrlFormat: 'Please put video url in the original format (with embed)',
    videoUpdateWrongUrl: 'Please make sure you only update videos that have a valid url.',
    noAuthor: 'Please make sure you add the name and email of the author or the author\'s insitution.'
}

interface Props {
    keywords: IKeyword[]
}

export const CreateCardPage: React.FC<Props> = ({keywords}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [title,setTitle] = useState<string>('');
    const [brief, setBrief] = useState<string>('');
    const [timeToComplete, setTimeToComplete] = useState<number>(0);
    const [timeUnit, setTimeUnit] = useState<string>(TimeUnits.DAYS);
    const [level, setLevel] = useState(CardLevel.BEGINNER);
    const [type, setType] = useState(CardType.ACTIVITY);
    const [teField, setTeField] = useState<string>(TeFields.TOOLS);
    const [toolsAndEquipment, setToolsAndEquipment] = useState<string>('');
    const [ksField, setKsField] = useState<string>(KsFields.KNOWLEDGE);
    const [knowledgeAndSkills, setKnowledgeAndSkills] = useState<string>('');
    const [materials, setMaterials] = useState<string>('');
    const [ssField, setSsField] = useState<string>(SsFields.STEP);
    const [stepByStep, setStepByStep] = useState<string[]>(['']);
    const [hyperlinks, setHyperlinks] = useState<IHyperlink[]>([]);
    const [images, setImages] = useState<IFileUpdate[]>([]);
    const [imagesBlobs, setImagesBlobs] = useState<File[]>([]);
    const [videos, setVideos] = useState<IVideo[]>([]);
    const [cardKeywords, setCardKeywords] = useState<string[]>([]);
    const [cardPois, setCardPois] = useState<IPointOfInterest[]>([]); 
    const [selectedAuthor, setSelectedAuthor] = useState<IAuthor>({name:'',email:''});

    const [authors, setAuthors] = useState<IAuthor[]>([]);
    /**/
    const [openSnackbar,setOpenSnackbar] = useState<boolean>(false);
    const [messageInfo, setMessageInfo] = useState('');
    const [openVideoSnackbar, setOpenVideoSnackbar] = useState(false);
    const [videoSnackbarMsg, setVideoSnackbarMsg] = useState('');

    
    const {createCard, getAllAuthors} = useCard();
    const {poisName, getAllPointsOfInterestName} = usePointOfInterest();

    const [currentKeyword,setCurrentKeyword] = useState<string>('');
    const [currentCOHyperlink,setCurrentCOHyperlink] = useState<string>('');
    const [currentCOHlTitle, setCurrentCOHlTitle] = useState<string>('');
    const [currentCOHlType, setCurrentCOHlType] = useState<string>('');
    const [currentNSHyperlink,setCurrentNSHyperlink] = useState<string>('');
    const [currentNSHlTitle, setCurrentNSHlTitle] = useState<string>('');
    const [currentPoi, setCurrentPoi] = useState<IPointOfInterest>();

    useEffect(() => {
        const fetchAuthors = async () => {
            const result = await getAllAuthors();
            
            // Use Array.prototype.filter to remove authors with null fields
            const filteredAuthors = result.filter((author: IAuthor) => author && author.name !== null && author.email !== null);
            
            setAuthors(filteredAuthors);
        };
        fetchAuthors();
        getAllPointsOfInterestName();
    }, [getAllAuthors, getAllPointsOfInterestName]);    

    const resetFields = () => {
        setTitle('');
        setBrief('');
        setTimeToComplete(0);
        setLevel(CardLevel.BEGINNER);
        setType(CardType.ACTIVITY);
        setTeField(TeFields.RESOURCES);
        setToolsAndEquipment('');
        setKsField(KsFields.KNOWLEDGE);
        setKnowledgeAndSkills('');
        setMaterials('');
        setSsField(SsFields.STEP);
        setStepByStep(['']);
        setHyperlinks([]);
        setImages([]);
        setVideos([]);
        setCardKeywords([]);
        setCardPois([]);
        setSelectedAuthor({name:'',email:''});
    }

    const addVideoToList = () => {
        if (videos.length < 7)
            setVideos([...videos, {url: '', title: '', credits: ''}]);

        else {
            setVideoSnackbarMsg(snackBarMessage.maxVideos);
            setOpenVideoSnackbar(true);
        }
    }

    const updateUrlField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.url = newValue;
        setVideos(newArr);
    }

    const updateTitleField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.title = newValue;
        setVideos(newArr);
    }

    const updateCreditsField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.credits = newValue;
        setVideos(newArr);
    }

    const removeFromArray = (index:number) => {
        videos.splice(index,1)
        setVideos([...videos]);
    }

    useEffect(() => {
        if (videos) {
          const youtubeRegExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
          const customRegExp = /^.*\/embed\?bgcolor=.*&txtcolor=.*&tracks=(\w+).*&share-token=.*$/;
      
          videos.forEach((video) => {
            if (video.url.match(youtubeRegExp)) {
              const match = video.url.match(youtubeRegExp);
              if (match && match[2].length === 11) {
                const videoId = match[2];
                const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                video.url = embedUrl;
              } else {
                video.url = "";
                setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                setOpenVideoSnackbar(true);
              }
            } else if (video.url.match(customRegExp)) {
              const match = video.url.match(customRegExp);
              if (match && match[1] === 'true') {
                const updatedUrl = video.url.replace(/tracks=true/, 'tracks=false');
                video.url = updatedUrl;
              }
            } else {
              video.url = "";
              setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
              setOpenVideoSnackbar(true);
            }
          });
      
          setVideos(videos);
        }
      }, [videos]);

    
    const addImageToList = () => {
        if (images.length < 9) {
            setImages([...images, {caption: '', source: '', url: '', name: '', isOld:false}]);
            setImagesBlobs([...imagesBlobs, new File([""], "", {type: "image/png, image/jpeg"})]);
        }
        else {
            setMessageInfo(snackBarMessage.maxImages);
            setOpenSnackbar(true);
        }
    }

    const removeImage = (index:number) => {
        if (images[index].url === "") {
            URL.revokeObjectURL(images[index].url);
        }
        imagesBlobs.splice(index,1);
        setImagesBlobs([...imagesBlobs]);
        images.splice(index,1);
        setImages([...images]);
    }

    const updateCaptionField = (index: number, newValue: string) => {
        let newArr = [...images];
        let newObj = newArr[index];
        newObj.caption = newValue;
        setImages(newArr);
    }

    const updateSourceField = (index: number, newValue: string) => {
        let newArr = [...images];
        let newObj = newArr[index];
        newObj.source = newValue;
        setImages(newArr);
    }

    const selectedFileHandler = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const rawFile: File | null | undefined = event.currentTarget.files?.item(0);
    
        if (rawFile) {
            let tmpArr = [...imagesBlobs];
            tmpArr[index] = rawFile;
            setImagesBlobs (tmpArr);

            let newArr = [...images];
            let newObj = {...newArr[index]};
            newObj.name = rawFile.name;
            newObj.url = URL.createObjectURL(rawFile);
            newArr[index] = newObj;
            setImages(newArr);
        }
    }

    const filesUpdateToFiles = (images: IFileUpdate[]) => {
        let newImages: IFile[] = [];

        if(images.length > 0)
            images.forEach((image) => newImages.push({caption:image.caption, source:image.source, uuid:""}))
        
        return newImages
    }

    const clickOnFile = (index: number) => {
        document.getElementById(`file-input${index}`)?.click();
    }

    const handleSendCard = async () => {
        if (title === "" ) {
            setMessageInfo(snackBarMessage.emptyTitle);
            setOpenSnackbar(true);
        }
        else if ( brief === "" ) {
            setMessageInfo(snackBarMessage.emptyBrief);
            setOpenSnackbar(true);
        }
        else if ( timeToComplete === 0 ) {
            setMessageInfo(snackBarMessage.emptyTime);
            setOpenSnackbar(true);
        }
        else if ( timeUnit === "" ) {
            setMessageInfo(snackBarMessage.emptyTimeUnit);
            setOpenSnackbar(true);
        }
        else if ( level === "" ) {
            setMessageInfo(snackBarMessage.emptyLevel);
            setOpenSnackbar(true);
        }
        else if ( type === "" ) {
            setMessageInfo(snackBarMessage.emptyType);
            setOpenSnackbar(true);
        }
        else if ( toolsAndEquipment === "" ) {
            setMessageInfo(snackBarMessage.emptyToolsAndEquipment);
            setOpenSnackbar(true);
        }
        else if ( teField === "" ) {
            setMessageInfo(snackBarMessage.emptyTeField);
            setOpenSnackbar(true);
        }
        else if ( knowledgeAndSkills === "" ) {
            setMessageInfo(snackBarMessage.emptyKnowledgeAndSkills);
            setOpenSnackbar(true);
        }
        else if ( ksField === "" ) {
            setMessageInfo(snackBarMessage.emptyKsField);
            setOpenSnackbar(true);
        }
        else if ( materials === "" ) {
            setMessageInfo(snackBarMessage.emptyMaterials);
            setOpenSnackbar(true);
        }
        else if ( stepByStep.length === 0 ) {
            setMessageInfo(snackBarMessage.emptyStepByStep);
            setOpenSnackbar(true);
        }
        else if ( ssField === "" ) {
            setMessageInfo(snackBarMessage.emptySsField);
            setOpenSnackbar(true);
        }

        // else if ( hyperlinks.length === 0) {
        //     setMessageInfo(snackBarMessage.noHyperlinks);
        //     setOpenSnackbar(true);
        // }

        // else if (images.length === 0) {
        //     setMessageInfo(snackBarMessage.noImages);
        //     setOpenSnackbar(true);
        // }

        else if (cardKeywords.length === 0) {
            setMessageInfo(snackBarMessage.noKeywords);
            setOpenSnackbar(true);
        }

        // else if (videos.length === 0) {
        //     setMessageInfo(snackBarMessage.noVideos);
        //     setOpenSnackbar(true);
        // }

        // else if (selectedAuthor.name === '' || selectedAuthor.email === '' ){
        //     setMessageInfo(snackBarMessage.noAuthor);
        //     setOpenSnackbar(true);
        // }
        else {
            setLoading(true);
            createCard({
                id: -1,
                title: title,
                brief: brief,
                keywords: cardKeywords,
                timeToComplete: timeToComplete,
                timeUnit: timeUnit,
                level: level,
                type: type,
                toolsAndEquipment: toolsAndEquipment,
                teField: teField,
                knowledgeAndSkills: knowledgeAndSkills,
                ksField: ksField,
                materials: materials,
                stepByStep: stepByStep,
                ssField: ssField,
                hyperlinks: hyperlinks,
                images: filesUpdateToFiles(images),
                videos: videos,
                pois: cardPois,
                author: selectedAuthor
            }, imagesBlobs).then((createdCard) => {
                setMessageInfo(snackBarMessage.cardCreated);
                setOpenSnackbar(true);
                resetFields();
                setLoading(false);
            }).catch( ({response}) => {
                if (response.status === 409) {
                    setMessageInfo(snackBarMessage.cardExists);
                    setOpenSnackbar(true);
                }
            });
        }
    }

    const handleAddKeyword = () => {
        if (currentKeyword === '') {
            setMessageInfo(snackBarMessage.emptyKeyword);
            setOpenSnackbar(true);
        }
        else if (cardKeywords.includes(currentKeyword)) {
            setMessageInfo(snackBarMessage.repeatedKeyword);
            setOpenSnackbar(true);
        }
        else {
            setCardKeywords([...cardKeywords, currentKeyword]);
            setCurrentKeyword('');
        }
    }

    const removeKeyword = (index:number) => {
        cardKeywords.splice(index,1);
        setCardKeywords([...cardKeywords]);
    }

    const handleAddStep = () => {
        setStepByStep([...stepByStep, '']);
    };

    const handleRemoveStep = (index: number) => {
        setStepByStep(stepByStep.filter((_, i) => i !== index));
    };

    const handleStepChange = (e: { target: { value: string; }; }, index: number) => {
        const newSteps = [...stepByStep];
        newSteps[index] = e.target.value;
        setStepByStep(newSteps);
    };
      
    const handleAddHyperlink = (currentHyperlink: string, setCurrentHyperlink: React.Dispatch<React.SetStateAction<string>>, currentHlTitle: string, setCurrentHlTitle: React.Dispatch<React.SetStateAction<string>>, field: string) => {
        if (currentHyperlink === '') {
            setMessageInfo(snackBarMessage.emptyHyperlink);
            setOpenSnackbar(true);
        }
        else if (currentHlTitle === '') {
            setMessageInfo(snackBarMessage.emptyTitleHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find((hl) => hl.url === currentHyperlink && hl.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find((hl) => hl.title === currentHlTitle && hl.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHlTitle);
            setOpenSnackbar(true);
        }
        else {
            setHyperlinks([...hyperlinks, {title: currentHlTitle, url: currentHyperlink, field: field}]);
            setCurrentHyperlink('');
            setCurrentHlTitle('');
        }
    }

    const removeHyperlink = (hl: IHyperlink) => {
        hyperlinks.splice(hyperlinks.indexOf(hl), 1);
        setHyperlinks([...hyperlinks]);
    }

    const handleAddPoi = (poi: IPointOfInterest) => {
        if (poi && !cardPois.find((currentPoi) => currentPoi.id === poi.id)) {
            setCardPois((prevCardPois) => [...prevCardPois, poi]);
        }
    };

    const handleRemovePoi = (poi: IPointOfInterest) => {
        setCardPois((prevCardPois) => prevCardPois.filter((p) => p !== poi));
    };

    const handleAuthorChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        if (value !== null) {
            const selectedAuthor = authors.find((author) => author.name === value);
            if (selectedAuthor) {
                setSelectedAuthor(selectedAuthor);
            }
        }
      };

    // const handleAuthorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const authorName = event.target.value;
    //     const author = authors.find((author) => author.name === authorName);

    //     setSelectedAuthor(author ? author : { name: authorName, email: selectedAuthor.email });
    // };
    

    const handleClose = () => {
        setOpenSnackbar(false)
    }
    if (loading) 
    return (
      <Box sx={{ paddingTop: '67px' }}>
        <Grid item xs={5} height={'91vh'} overflow={'auto'}>
          <Container maxWidth={"xl"}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
              <Typography variant="h5" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold', marginBottom: '1rem' }}>
                Create a Card
              </Typography>
              <Container className='loadingContainerCard'>
                <div className='loadingDiv'>
                  <CircularProgress color="inherit" />
                  <div>Loading...</div>
                </div>
              </Container>
            </Box>
          </Container>
        </Grid>
      </Box>
    );
    else
        return (
        <Box sx={{paddingTop:'67px'}}>
            <Grid item xs={5} height={'91vh'} overflow={'auto'}>
                <Container maxWidth={"xl"}>
                    <Box justifyContent={"center"} sx={{ marginTop: '2%', height:'5%', display: 'flex', width:'100%'}}>
                        <Typography align='center' variant = "h5" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                            Create a Card
                        </Typography>
                    </Box>
                    <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                        Identification Info
                    </Typography>
                    <Box pt={1} height={'100%'} width={'100%'}>
                        <Box height={'56%'}>
                            <TextField
                                fullWidth
                                required
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.currentTarget.value)}
                            />

                            <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                Craft Specific Info
                            </Typography>
                            <Box pt={1}>
                                <TextField fullWidth
                                            multiline
                                            maxRows={10}
                                            label="Briefing"
                                            helperText="Introduce a briefing for the card."
                                            inputProps={{
                                                maxLength: 10000,
                                            }}
                                            value={brief}
                                            onChange={(e) => setBrief(e.currentTarget.value)}
                                />
                            </Box>

                            {keywords && <Box>
                                <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Keywords
                                </Typography>

                                <Box className={'keyword-box-w-ac'}>

                                    <Box width={'70%'} pt={1}>
                                        <Autocomplete
                                            disablePortal
                                            options={keywords.map((keyword) => { return (keyword.name)})}//.sort((a, b) => (a > b) ? 1 : -1)} //sort alphabetically
                                            value={currentKeyword}
                                            onChange={(_,value) => value && setCurrentKeyword(value)}
                                            isOptionEqualToValue={() => true} //to avoid warning since '' is not present in options
                                            renderInput={(params) => <TextField {...params} onChange={(e) => setCurrentKeyword(e.currentTarget.value)} label="Select or write a keyword" helperText={"Specific concept that describes the artist/place."}
                                            />}
                                        />
                                    </Box>
                                    <Button onClick={handleAddKeyword} variant={"contained"} color={"success"} className={'add-keyword-but'} sx={{height:'5%', marginTop: '1%', marginBottom: '1%'}}>Add Keyword</Button>

                                </Box>

                                {cardKeywords.length !== 0 && <Box>
                                    {cardKeywords.map((kw, index) => {
                                        return (
                                            <Box pt={1} width={'70%'} className={'same-row'} key={kw}>
                                                <Button variant={"contained"} color="secondary" sx={{cursor:'default', textTransform:'none',height:'5%', marginRight: '1%', borderRadius:'2em'}}>{kw}</Button>
                                                <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeKeyword(index)} />
                                            </Box>
                                        );
                                    })}
                                </Box>}
                            </Box>}

                            <Box pt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Time Value"
                                        helperText="Please insert the duration value."
                                        value={timeToComplete}
                                        onChange={(e) => setTimeToComplete(parseFloat(e.currentTarget.value))}
                                    />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Time Unit</InputLabel>
                                            <Select
                                            value={timeUnit}
                                            onChange={(e) => setTimeUnit(e.target.value as string)}
                                            >
                                                <MenuItem value={TimeUnits.DAYS}>Days</MenuItem>
                                                <MenuItem value={TimeUnits.WEEKS}>Weeks</MenuItem>
                                                <MenuItem value={TimeUnits.MONTHS}>Months</MenuItem>
                                                <MenuItem value={TimeUnits.SEMESTERS}>Semesters</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Box pt={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Level</InputLabel>
                                    <Select
                                    value={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                    >
                                    <MenuItem value={CardLevel.BEGINNER}>{CardLevel.BEGINNER}</MenuItem>
                                    <MenuItem value={CardLevel.INTERMEDIATE}>{CardLevel.INTERMEDIATE}</MenuItem>
                                    <MenuItem value={CardLevel.ADVANCED}>{CardLevel.ADVANCED}</MenuItem>
                                    <MenuItem value={CardLevel.EXPERT}>{CardLevel.EXPERT}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box pt={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    >
                                    <MenuItem value={CardType.ACTIVITY}>{CardType.ACTIVITY}</MenuItem>
                                    <MenuItem value={CardType.LECTURE}>{CardType.LECTURE}</MenuItem>
                                    <MenuItem value={CardType.TUTORIAL}>{CardType.TUTORIAL}</MenuItem>
                                    <MenuItem value={CardType.PROJECT}>{CardType.PROJECT}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box pt={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tools and Equipment or Resources</InputLabel>
                                        <Select
                                        value={teField}
                                        onChange={(e) => setTeField(e.target.value as string)}
                                        >
                                            <MenuItem value={TeFields.TOOLS}>Tools and Equipment</MenuItem>
                                            <MenuItem value={TeFields.RESOURCES}>Resources</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <TextField fullWidth
                                            multiline
                                            maxRows={2}
                                            label={teField}
                                            helperText="Please insert the tools and equipment or resources needed for this card."
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            value={toolsAndEquipment}
                                            onChange={(e) => setToolsAndEquipment(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pt={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Knowledge and Skills or Linked to Pedagogical Theories</InputLabel>
                                        <Select
                                        value={ksField}
                                        onChange={(e) => setKsField(e.target.value as string)}
                                        >
                                            <MenuItem value={KsFields.KNOWLEDGE}>Knowledge and Skills</MenuItem>
                                            <MenuItem value={KsFields.PEDAGOGICAL}>Linked to Pedagogical Theories</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <TextField fullWidth
                                            multiline
                                            maxRows={2}
                                            label={ksField}
                                            helperText="Please insert the knowledge and skills needed for this card or the linked to pedagogical theories."
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            value={knowledgeAndSkills}
                                            onChange={(e) => setKnowledgeAndSkills(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pt={2}>
                                <TextField fullWidth
                                            multiline
                                            maxRows={2}
                                            label="Materials"
                                            helperText="Please insert the materials needed for this card."
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            value={materials}
                                            onChange={(e) => setMaterials(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pt={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Step by step or Timeline</InputLabel>
                                        <Select
                                        value={ssField}
                                        onChange={(e) => setSsField(e.target.value as string)}
                                        >
                                            <MenuItem value={SsFields.STEP}>Step by Step</MenuItem>
                                            <MenuItem value={SsFields.TIMELINE}>Timeline</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {stepByStep.map((step, index) => { 
                                    return (
                                        <Box key={index} display="flex" alignItems="center" mb={2}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                maxRows={2}
                                                label={`Step ${index + 1}`}
                                                helperText="Please insert a step for this card."
                                                inputProps={{
                                                maxLength: 1000,
                                                }}
                                                value={step}
                                                onChange={(e) => handleStepChange(e, index)}
                                            />
                                            <IconButton
                                                edge="end"
                                                color="inherit"
                                                onClick={() => handleRemoveStep(index)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                )})}
                                <Box>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddStep}
                                    >
                                    Add Step
                                    </Button>
                                </Box>
                            </Box>

                            <Box className={'hyperlink-box-checkout'}>
                                <br></br>
                                <Typography height={'20%'} variant = "h6" className={"subtitle"} > Inspiration </Typography>
                                <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                                    Please insert link to other content that can serve as inspiration for this educational card. It can be related videos, documents, websites, or any other type of content.
                                </Typography>
                                <Box width={'70%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                    label="Checkout Hyperlink Title"
                                                    value={currentCOHlTitle}
                                                    inputProps={{
                                                        maxLength: 255,
                                                    }}
                                                    onChange={(e) => setCurrentCOHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                    label="Checkout Hyperlink"
                                                    helperText="Clickable hyperlink to add to the inspiration field."
                                                    value={currentCOHyperlink}
                                                    inputProps={{
                                                        maxLength: 255,
                                                    }}
                                                    onChange={(e) => setCurrentCOHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>Checkout type</InputLabel>
                                                <Select
                                                value={currentCOHlType}
                                                onChange={(e) => setCurrentCOHlType(e.target.value as string)}
                                                >
                                                    <MenuItem value={hyperlinkTypes.checkoutvideo}>Video</MenuItem>
                                                    <MenuItem value={hyperlinkTypes.checkoutdocument}>Document</MenuItem>
                                                    <MenuItem value={hyperlinkTypes.checkoutwebsite}>Website</MenuItem>
                                                    <MenuItem value={hyperlinkTypes.checkoutothers}>Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentCOHyperlink, setCurrentCOHyperlink, currentCOHlTitle, setCurrentCOHlTitle, currentCOHlType)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginTop: '1%', marginBottom: '1%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {hyperlinks.filter((hl) => (hl.field === hyperlinkTypes.checkoutvideo || hl.field === hyperlinkTypes.checkoutdocument || hl.field === hyperlinkTypes.checkoutwebsite || hl.field === hyperlinkTypes.checkoutothers)).map((lmhl, index) => {
                                return (
                                    <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                        <Box>
                                            <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {lmhl.title}</Typography>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    window.open(lmhl.url, '_blank');
                                                }}
                                            >
                                                {lmhl.url}
                                            </Link>
                                        </Box>
                                        <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                    </Box>
                                );
                            })}

                            <Box className={'hyperlink-box-next-steps'}>
                                <br></br>
                                <Typography height={'20%'} variant = "h6" className={"subtitle"} > Next Steps </Typography>

                                <Box width={'70%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                label="Next Step Hyperlink Title"
                                                value={currentNSHlTitle}
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => setCurrentNSHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                label="Next Step Hyperlink"
                                                helperText="Clickable hyperlink to add as a next step."
                                                value={currentNSHyperlink}
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => setCurrentNSHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentNSHyperlink, setCurrentNSHyperlink, currentNSHlTitle, setCurrentNSHlTitle, hyperlinkTypes.nextSteps)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginTop: '1%', marginBottom: '1%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {hyperlinks.filter((hl) => hl.field === hyperlinkTypes.nextSteps).map((lmhl, index) => {
                            return (
                                <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                    <Box>
                                        <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {lmhl.title}</Typography>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                window.open(lmhl.url, '_blank');
                                            }}
                                        >
                                            {lmhl.url}
                                        </Link>
                                    </Box>
                                    <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                </Box>
                            );
                            })}

                        </Box>
                    
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <br></br>
                            <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                    Add Images To Your Card
                                </Typography>
                                <Button size={'small'} onClick={addImageToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                            </Box>
                            <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                                The number of files should be a maximum of <b>9</b> and have the format <b>JPEG</b> or <b>PNG</b>.
                                <br/>
                                {/* The size limit for the images is <b>2240</b> (width) x <b>1680</b> (height) px. */}
                            </Typography>
                            <Box width={'100%'}>
                                {images.length !== 0 && images.map((file:IFileUpdate, index)=>{
                                    return (
                                        <Box key={index}>
                                            <Box className={'title-with-icon'}>
                                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                                    Image {index + 1}
                                                </Typography>
                                                {/* TO DO*/}
                                                <Delete onClick={() => removeImage(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                            required
                                                            label="Caption"
                                                            value={file.caption}
                                                            onChange={(e) => updateCaptionField(index,e.currentTarget.value)}
                                                            InputProps={{
                                                                readOnly: file.isOld
                                                            }}
                                                />
                                            </Box>
                                                <TextField fullWidth
                                                            required
                                                            label="Source"
                                                            value={file.source}
                                                            onChange={(e) => updateSourceField(index,e.currentTarget.value)}
                                                            InputProps={{
                                                                readOnly: file.isOld
                                                            }}
                                                />

                                            <Box className={'button-with-icon-below'}>
                                                {file.url!=="" && <img src={file.url}
                                                        height={64}
                                                        width={64}
                                                        alt={'this is a preview'}
                                                        style={{borderRadius:2, marginTop:'1%', alignSelf:'center'}}
                                                        //  srcSet={`${file.url}?w=64&h=64&fit=crop&auto=format&dpr=2 2x`}
                                                        loading="lazy"
                                                />}                                                                                                                                                                    {/*maybe not working*/}
                                                {!file.isOld && <Input onChange={(e: ChangeEvent<HTMLInputElement>) => selectedFileHandler(index, e)} id={`file-input${index}`} type="file" inputProps={{accept:"image/png, image/jpeg"}} sx={{height: 0, width: 0, display: 'none'}}/>}
                                                {!file.isOld && <Button onClick={() => clickOnFile(index)} variant={"contained"} sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}    } >Choose Image</Button>}
                                            </Box>
                                            <Divider sx={{marginTop:'2%', marginBottom:'2%', borderBottom:'solid', color:'gray', opacity:'0.7'}}/>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <br></br>
                            <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                    Add Videos To Your Card
                                </Typography>
                                <Button size={'small'} onClick={addVideoToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                            </Box>
                            <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                                The number of videos should be a maximum of 7 and they should be already uploaded on youtube.
                            </Typography>
                            {videos.length !== 0 && <Box width={'100%'}>
                                {videos.map((video:IVideo, index)=>{
                                    return (
                                        <Box key={index}>
                                            <Box className={'title-with-icon'}>
                                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                                    Video {index + 1}
                                                </Typography>
                                                <Delete onClick={() => removeFromArray(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                        required
                                                        label="Youtube Video URL"
                                                        value={video.url}
                                                        onChange={(e) => updateUrlField(index,e.currentTarget.value)}
                                                />
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                        required
                                                        label="Title"
                                                        value={video.title}
                                                        onChange={(e) => updateTitleField(index,e.currentTarget.value)}
                                                />
                                            </Box>

                                            <TextField fullWidth
                                                    required
                                                    label="Credits"
                                                    value={video.credits}
                                                    onChange={(e) => updateCreditsField(index,e.currentTarget.value)}
                                            />

                                            <Box className={'box-with-video-preview'}>
                                                {video.url !== '' && (video.url.includes('watch?v=') || video.url.includes('embed/')) &&
                                                    <iframe
                                                        width="200"
                                                        height="150"    //check if it is in the format    //sometimes google adds chanel= in url so it would be prefered for people to paste directly with the embed format or without this additional chanel=
                                                        title='youtube video'
                                                        src={video.url.includes('embed/') ? video.url : video.url.replace('watch?v=', 'embed/')} //transform in embed format
                                                        allow="autoplay; encrypted-media"
                                                        allowFullScreen
                                                        style = {{border: '0'}}>
                                                    </iframe>
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>}
                            <Snackbar
                                open={openVideoSnackbar}
                                autoHideDuration={2000}
                                message={videoSnackbarMsg}
                                onClose = {() => setOpenVideoSnackbar(false)}
                            />
                        </Box>

                        <Box>
                                <Typography variant="h6" pt={1} align="left" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Associated Points of Interest
                                </Typography>

                                <Box className="poi-association-box">
                                    <Box width="70%" pt={1}>
                                    <Autocomplete
                                        disablePortal
                                        options={poisName}
                                        getOptionLabel={(option) => option.name}
                                        value={undefined}
                                        onChange={(_, value) => value && setCurrentPoi(value)}
                                        isOptionEqualToValue={() => true}
                                        renderInput={(params) => (
                                        <TextField {...params} label="Select Points of Interest" helperText="Choose points of interest to associate with the card" />
                                        )}
                                    />
                                    </Box>
                                    <Button
                                    onClick={() => {
                                        // Perform any necessary action with the selected cards, such as saving them to the backend
                                        if(currentPoi)
                                            handleAddPoi(currentPoi);
                                    }}
                                    variant="contained"
                                    color="success"
                                    className="add-poi-button"
                                    sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}
                                    >
                                    Add Card
                                    </Button>
                                </Box>

                                {cardPois.length !== 0 && (
                                    <Box>
                                    {cardPois.map((poi, index) => (
                                        <Box pt={1} width="70%" className="same-row" key={"poi"+index}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ cursor: 'default', textTransform: 'none', height: '5%', marginRight: '1%', borderRadius: '2em' }}
                                        >
                                            {poi.name}
                                        </Button>
                                        <Clear cursor="pointer" fontSize="large" color="action" className="center-icon" onClick={() => handleRemovePoi(poi)} />
                                        </Box>
                                    ))}
                                    </Box>
                                )}
                                </Box>

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Box>
                                <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Author or author's institution
                                </Typography>
                                <Box className={'author-box-w-ac'}>
                                    {/* <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        value={selectedAuthor.name || ''}
                                        options={authors}
                                        onChange={handleAuthorChange}
                                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label="Name"
                                            onChange={(e) =>
                                                setSelectedAuthor({ name: e.currentTarget.value, email: selectedAuthor.email })
                                            }
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                            />
                                        )}
                                        />  */}
                                    {/* <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        value={selectedAuthor.email}
                                        options={authors.map((author) => author.email)}
                                        onChange={(_,value) => value && setSelectedAuthor({name:selectedAuthor.name,email:value})}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Email"
                                            onChange={(e) => setSelectedAuthor({name:selectedAuthor.name,email:e.currentTarget.value} )}
                                            InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            }}
                                        />
                                        )}
                                    /> */}
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        value={selectedAuthor.name}
                                        options={authors.map((author) => {return (author.name) })}
                                        onChange={handleAuthorChange}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Name"
                                            onChange={(e) => setSelectedAuthor({name:e.currentTarget.value, email:selectedAuthor.email} )}
                                            InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            }}
                                        />
                                        )}
                                    />
                                    <TextField
                                        label="Email"
                                        value={selectedAuthor.email}
                                        onChange={(e) => setSelectedAuthor({name:selectedAuthor.name,email:e.target.value})}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Button onClick={handleSendCard} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '1%'}}>Create</Button>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={2000}
                            message={messageInfo}
                            onClose = {handleClose}
                        />
                    </Box>
                    
                </Container>
            </Grid>
        </Box>
    );

};

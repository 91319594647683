import { Box, Container, Typography, Link, useTheme, Dialog, Paper, MobileStepper, Button, DialogActions, Grid, CardMedia, IconButton, Accordion, AccordionSummary, AccordionDetails, Card, CardActionArea, CardContent, CardActions, CircularProgress } from '@mui/material';
import {ChevronLeft, ChevronRight, KeyboardArrowLeft, KeyboardArrowRight, ArrowBack, ExpandMore} from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from 'react';
import { useCard } from '../../hooks/useCard';
import { ICard, IFile, IVideo } from '../../interfaces/';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useFile } from '../../hooks';
import "./Card.css";
import Footer from '../Footer';
import preview from "../../assets/default_poi_preview.jpg";
import { POIPreview } from '../Information/POIPreview';

const type = 'card';
const backend_url = process.env.REACT_APP_API_URL;

export const CardType = {
  TUTORIAL: 'Tutorial',
  LECTURE: 'Lecture',
  ACTIVITY: 'Activity',
  PROJECT: 'Project'
};

export const CardLevel = {
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
  EXPERT: 'Expert',
};

export const TimeUnits = {
  DAYS: 'Days',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
  SEMESTERS: 'Semesters',
};

export const TeFields = {
  TOOLS: 'Tools and Equipment',
  RESOURCES: 'Resources'
};

export const KsFields = {
  KNOWLEDGE: 'Knowledge and Skills',
  PEDAGOGICAL: 'Linked to Pedagogical Theories'
};

export const SsFields = {
  STEP: 'Step by Step',
  TIMELINE: 'Timeline'
};

export const hyperlinkTypes = {
  checkoutvideo: 'Checkout Video',
  checkoutdocument: 'Checkout Document',
  checkoutwebsite: 'Checkout Website',
  checkoutothers: 'Checkout Others',
  nextSteps: 'Next Steps'
}

export const CardPage: React.FC = () => {
  
  const { id } = useParams<{ id: string }>();

  const [card, setCard] = useState<ICard | null>(null);
  const { getCardById } = useCard();
  const {getFileUrl} = useFile();

  const [hovered, setHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  interface SubsectionProps {
    title: string;
    content: string;
    colorClass: string;
    isExpandable: boolean;
  }

  interface VideoGridProps {
    videos: IVideo[];
    onVideoClick: (index: number) => void;
  }  

  let imageSrc;

  if(card?.images.length === 0)
    imageSrc = preview;
  else 
    imageSrc = backend_url+`/card/image/${card?.images[currentImageIndex]?.uuid}/preview`;

  const handleNextImage = () => {
    if (card != null)
      setCurrentImageIndex((currentImageIndex + 1) % card.images.length);
  };
  
  const handlePrevImage = () => {
    if (card != null)
      setCurrentImageIndex((currentImageIndex + card.images.length - 1) % card.images.length);
  };

  function Subsection({title, content, colorClass, isExpandable}: SubsectionProps) {  
    return (
      <Accordion className='accordion-tabs' defaultExpanded={true} expanded={isExpandable ? undefined : false}>
        <AccordionSummary
          expandIcon={isExpandable ? <ExpandMore /> : null}
          aria-controls="panel1a-content"
          id={colorClass}
          className='accordionSummary'
        >
          <Typography className='titleAccordion'>{title}</Typography>
        </AccordionSummary>
        {content &&
        <AccordionDetails className='accordionDetails' id={colorClass}>
          <Typography className='contentAccordion'>
            {content}
          </Typography>
        </AccordionDetails>
        }
      </Accordion>
    );
  }

  interface ImageGridProps {
    images: IFile[];
    onImageClick: (index: number) => void;
  }

  const ImageGrid = ({ images, onImageClick }: ImageGridProps) => {  
    return (
      <Grid className='imageVideoGrid' container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={'image_grid_'+index} xs={12} sm={6} md={4}>
            <Card className='imageCard'>
              <CardActionArea onClick={() => onImageClick(index)}>
                <CardMedia
                  className='imageCardMedia'
                  component="img"
                  height="315"
                  src={backend_url + '/'+type+`/image/${image.uuid}/preview`}
                  title={image.caption}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {image.caption}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  interface SimpleDialogProps {
    open: boolean;
    closeDialog: () => void;
    videos: IVideo[];
    index: number;
  }
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<number>(0);

  const handleVideoClick = (index: number) => {
    setSelectedVideo(index);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const VideoGrid = ({ videos, onVideoClick }: VideoGridProps) => {
    return (
      <Grid container spacing={2}>
        {videos.map((video, index) => (
          <Grid item key={'video'+index} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea onClick={() => onVideoClick(index)}>
                <CardMedia
                  component="iframe"
                  height="315"
                  src={video.url}
                  title={video.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {video.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {video.credits}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link href={video.url} target="_blank" rel="noopener">
                    Source
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

    
  function SimpleDialog(props: SimpleDialogProps) {
    const { open, closeDialog, videos, index } = props;

    const close = () => {
        closeDialog()
    }

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(index);
    const maxSteps = videos.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Dialog className='dialogCont' open={open} maxWidth="lg" fullWidth={true} onClose={close}>
            <Box className='dialogIframeBox'>
                {videos.length !== 0 && <Paper
                    square
                    elevation={0}
                >
                    <iframe
                        className='iframeVideo'
                        title="youtube video"
                        src={videos[activeStep].url}
                        allow="autoplay; encrypted-media"
                        allowFullScreen>
                    </iframe>
                    <Typography variant='h5' className={"poiSubtitleImgTitle"}>{videos[activeStep]?.title}</Typography>
                    <Typography variant='caption' className={"poiSubtitleImgSource"}>{videos[activeStep]?.credits}</Typography>
                </Paper>}

                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </Box>
            <DialogActions>
                <Button autoFocus onClick={close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
  }  

  interface ImageDialogProps {
    open: boolean;
    closeDialog: () => void;
    images: IFile[];
    index: number;
  }

  const [imageOpen, setImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setSelectedImage(index);
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  function ImageDialog(props: ImageDialogProps) {
    const { open, closeDialog, images, index } = props;
  
    const close = () => {
        closeDialog()
    }
  
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(index);
    const maxSteps = images.length;
    
    const handleImageNext = useCallback(() => {
      if(activeStep < maxSteps - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }, [activeStep, maxSteps]);
    
    const handleImageBack = useCallback(() => {
      if(activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }, [activeStep]);
    
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'ArrowRight') {
            handleImageNext();
          } else if (event.key === 'ArrowLeft') {
            handleImageBack();
          }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
  }, [handleImageNext, handleImageBack]);
  
    return (
      <Dialog className='poiDialogImgCont' open={open} maxWidth="lg" fullWidth={true} onClose={close}>
        <Box className='poiDialogImgBox'>
          {images.length !== 0 && <Paper
              square
              elevation={0}
          >
            <img
              className='poiImg'
              // src={backend_url + `/card/image/${images[activeStep].uuid}`}
              src={backend_url + '/'+type+`/image/${images[activeStep].uuid}`}
              alt={images[activeStep].caption}
            />
            <Typography variant='h5' className={"poiSubtitleImgTitle"}>{images[activeStep]?.caption}</Typography>
            <Typography variant='caption' className={"poiSubtitleImgSource"}>{images[activeStep]?.source}</Typography>
          </Paper>}
  
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleImageNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleImageBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
        <DialogActions>
          <Button autoFocus onClick={close}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
    
  useEffect(() => {
    if (id) {
      getCardById(Number(id))
        .then((res: ICard) => {
          setCard(res);
        })
        .catch((err) => {
          console.error(err);
          setCard(null);
        });
    }
  }, [id, getCardById, getFileUrl]);

  if (!id) {
    return (
      <Container className='loadingContainer'>
        <div className='loadingDiv'>
          <div>No card specified!</div>
        </div>
      </Container>
    );
  }

  if (!card) {
    return (
      <Container className='loadingContainer'>
        <div className='loadingDiv'>
          <CircularProgress color="inherit" />
          <div>Loading...</div>
        </div>
      </Container>
    );
  }

  return (
    <Container
      sx={{paddingTop:'80px'}}
      className='main-container'
    >
      <Box className='cardPageWrap'>
        <Box className='gridWrapBox'>
          <Grid container className='gridContainer' spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={5}>
              <Box className='titleBriefBox'>
                <RouterLink className='goBackLink' to="/cards">
                  <ArrowBack className='arrowBack' />
                  <Typography variant="body1">Back to all cards</Typography>
                </RouterLink>
                <Typography variant="h3" className='title'>{card.title}</Typography>
                <Typography variant="caption" className='type'>{card.type}</Typography>
                <Box className='boxWithKeywords'>
                  {card.keywords.map((keyword, index) => (
                    <RouterLink key={index} className='keywordsLink' to={`/cards/${keyword}`}>
                      <Box className='keywordsBox'>{keyword}</Box>
                    </RouterLink>
                  ))}
                </Box>
                <Typography variant="body1" className='brief'>{card.brief}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={7} className='imageGrid'>
              <Box 
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className='imagesBox'>
                  <CardMedia
                    component="div"
                    className='imageCardMedia'
                  >
                    <CardActionArea onClick={() => handleImageClick(currentImageIndex)}>
                      <img
                        src={imageSrc}
                        alt=""
                        className='image'
                      />
                    </CardActionArea>
                    {card.images.length > 1 && hovered &&
                    <Box
                      className='arrowBox'
                    >
                      <IconButton onClick={handlePrevImage}>
                        <ChevronLeft />
                      </IconButton>
                      <IconButton onClick={handleNextImage}>
                        <ChevronRight />
                      </IconButton>
                    </Box>}
                  </CardMedia>
                <ImageDialog
                  open={imageOpen}
                  closeDialog={handleImageClose}
                  images={card.images}
                  index={selectedImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {card.type === CardType.PROJECT && card.images.length > 0 && 
        <Box className='imagesBoxContainer'> 
          <Typography variant="h4" className='sectionTitle'>Images</Typography>
          <ImageGrid images={card.images} onImageClick={handleImageClick} />
            <ImageDialog
              open={imageOpen}
              closeDialog={handleImageClose}
              images={card.images}
              index={selectedImage}
              />
        </Box>}
        {card.videos.length>0 && 
        <Box className='videosBoxContainer'> 
          <Typography variant="h4" className='sectionTitle'>Watch</Typography>
          <VideoGrid videos={card.videos} onVideoClick={handleVideoClick} />
            <SimpleDialog
              open={dialogOpen}
              closeDialog={handleDialogClose}
              videos={card.videos}
              index={selectedVideo}
              />
        </Box>
        }
        <Grid container className='gridContainer' spacing={0}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box className='accordion-container'>
              <Typography variant="h4" className='accordionTitle'>You will need</Typography>
              <Subsection
                title="Who is this for"
                content={card.level}
                colorClass='tab-color-1'
                isExpandable={true}
              />
              <Subsection
                title="Time"
                content={card.timeToComplete+' '+card.timeUnit}
                colorClass='tab-color-2'
                isExpandable={true}
              />
              <Subsection
                title="Materials"
                content={card.materials}
                colorClass='tab-color-3'
                isExpandable={true}
              />
              <Subsection
                title={card.teField}
                content={card.toolsAndEquipment}
                colorClass='tab-color-4'
                isExpandable={true}
              />
              <Subsection
                title={card.ksField}
                content={card.knowledgeAndSkills}
                colorClass='tab-color-4'
                isExpandable={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Box className='accordion-container'>
              <Typography variant="h4" className='accordionTitle'>{card.ssField}</Typography>
              {card.stepByStep.map((step, index) => {
                return(
                  <Subsection
                  key={`step${index}`}
                  title={`Step ${index+1}`}
                  content={step}
                  colorClass={`tab-color-${index+1}`}
                  isExpandable={true}
                />
                );
              })}
            </Box>
          </Grid>
        </Grid>

        {card.hyperlinks.filter((hl) => (hl.field === hyperlinkTypes.checkoutvideo || hl.field === hyperlinkTypes.checkoutdocument || hl.field === hyperlinkTypes.checkoutwebsite || hl.field === hyperlinkTypes.checkoutothers)).length !== 0 &&
          <Box className='accordion-container'>
          <Typography variant="h4" className='accordionTitle'>Inspiration</Typography>
          {card.hyperlinks.filter((hl) => (hl.field === hyperlinkTypes.checkoutvideo || hl.field === hyperlinkTypes.checkoutdocument || hl.field === hyperlinkTypes.checkoutwebsite || hl.field === hyperlinkTypes.checkoutothers)).map((link, index) => (
              <Link
                sx={{textDecoration:'none'}}
                key={'hl_ch'+index}
                onClick={() => {
                    window.open(link.url, '_blank');
                }}
                >
                <Subsection
                  title={link.title}
                  content={''}
                  colorClass={'tab-color-'+(index+1)}
                  isExpandable={false}
                  />
            </Link>
        ))}
        </Box>
        }

        {card.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.nextSteps).length !== 0 &&
          <Box className='accordion-container'>
          <Typography variant="h4" className='accordionTitle'>Next Steps</Typography>
          {card.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.nextSteps).map((link, index) => (
              <Link
                key={'hl_ns'+index}
                sx={{textDecoration:'none'}}
                onClick={() => {
                    window.open(link.url, '_blank');
                }}
                >
                <Subsection
                  title={link.title}
                  content={''}
                  colorClass={'tab-color-'+(index+1)}
                  isExpandable={false}
                  />
            </Link>
        ))}
        </Box>
        }

        <Box className='health-container'>
          <RouterLink className='goHealth' to="/health">
            <Typography variant="h4" className='healthTitle'>Health and Safety Information</Typography>
          </RouterLink>
        </Box>

        {card.pois.length > 0 &&
        <Box className='cardPoisBox'>
            <Typography variant="h4" className='regularTitle'>Associated Points of Interest</Typography>
            <Grid container spacing={10}>
                {card.pois.map((poi, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <POIPreview key={poi.name} poi={poi} description={false} />
                </Grid>
                ))}
            </Grid>
        </Box>
        }
      </Box>
      <Footer></Footer>
    </Container>
  );
};


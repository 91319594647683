import {AxiosError} from "axios";

export const errorInterceptor = (error: AxiosError) => {
    if (error.message === 'Network Error') {
        return Promise.reject(new Error('Network error.'));
    }

    if(error.response) {
        switch(error.response.status) {
            case 401:
                // auth error, you can perform certain action like redirecting to login
                break;
            case 404:
                // Not found error
                return Promise.reject(new Error('Requested resource not found.'));
            case 500:
                // Server error
                return Promise.reject(new Error('There is something wrong with the server.'));
            default:
                // Other errors
                return Promise.reject(new Error('Something went wrong.'));
        }
    }

    return Promise.reject(error);
}

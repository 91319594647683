import React, {useEffect, useState} from "react";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import {IPointOfInterest} from "../../interfaces";
import {useParams} from "react-router-dom";
import {useKeyword} from "../../hooks";
import L, {LatLng, Map} from "leaflet";

export const KeywordHighlightMap: React.FC = () => {
    let {keyword, poiName} = useParams<string>(); //can have the poi directly when clicking in the keyword. but maybe put if optional because

    const [keywordPois, setKeywordPois] = useState<IPointOfInterest[]>();
    
    const {getPoisByKeyword} = useKeyword();

    useEffect(() => {
        if (keyword != null) {
            getPoisByKeyword(keyword, setKeywordPois);
        }
    }, [getPoisByKeyword, keyword]);

    const MapEventComponent = () => {

        const mapRef: Map | null = useMap();

        useEffect(() => {
            if(keywordPois && mapRef) {
                let poiToHighlight = keywordPois.find((poi) => poi.name === poiName) as IPointOfInterest; //it is never undefined, it always finds it because the list given is of existing pois
                mapRef.flyTo(new LatLng(poiToHighlight.lat,poiToHighlight.lng), 7);
            }
        }, [mapRef]);
        

        /*useMapEvents({
            click(event) {
                const {lat, lng} = event.latlng

            },

        });*/

        return null;
    }

    return (
        <MapContainer center={[39.505, -8.09]} zoom={5}>
            {/* <TileLayer
                attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
                url="https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
            /> */}
            <TileLayer
                attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
                noWrap={true}
            />
            <MapEventComponent></MapEventComponent>

            {keywordPois?.map((poi) => {
                return (poi.name === poiName ? <Marker key={poi.name}
                    position={[poi.lat,poi.lng]}
                    interactive={true}
                    icon= {new L.Icon({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [25, 41], //these 4 are important properties because otherwise the map does not know the size of the marker
                        iconAnchor: [12, 41], //and it moves on zoom
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    })}
                />
                :
                <Marker key={poi.name}
                    position={[poi.lat,poi.lng]}
                    interactive={true}
                />
                )
            })

            }

        </MapContainer>
    );
};
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {useLocation, useNavigate} from "react-router-dom";

import logo from '../assets/blue2_DECODING-CERAMICS_sketch_02.png';
import logo_white from '../assets/white2_DECODING-CERAMICS_sketch_02.png';
import { TemporaryDrawer } from './Drawer';
import "./Navbar.css"
import { useEffect, useState } from 'react';

export const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [logoColor, setLogoColor] = useState<number>(1);
    
    useEffect(() => {
        const isHomePage = location.pathname === '/'; // Adjust the path to match your homepage path
        const isMeetPage = location.pathname === '/meet'; // Adjust the path to match your homepage path
        const isHelpPage = location.pathname === '/help'; // Adjust the path to match your homepage path
        const isHealthPage = location.pathname === '/health'; // Adjust the path to match your homepage path
      
        // Set the logo color based on whether it's the homepage or the meet page
        if (isHomePage) {
          setLogoColor(0);
        } else if (isMeetPage || isHelpPage || isHealthPage) {
          setLogoColor(2);
        } else {
          setLogoColor(1);
        }
      }, [location]);

    return (
        <AppBar className='navbar' position="static">
                <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
                    <Box
                        component="a"
                        onClick={() => navigate('/')}
                        sx={{
                            mr: 2,
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}
                        >
                        {logoColor===0?
                            <></>:
                            <img
                                src={logoColor===1?logo:logo_white}
                                alt="CRAFT Logo"
                                style={{
                                height: '30px', // Adjust the height as needed
                                width: 'auto'
                                }}
                                className='logo-image'
                            />
                        }     
                    </Box>
                    <TemporaryDrawer logoColor={logoColor}/>
                </Toolbar>
        </AppBar>
    );
};

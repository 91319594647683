import React, {useEffect, useState} from 'react';

import {MapContainer, TileLayer, Marker, useMapEvents} from 'react-leaflet';

import {
    Button,
    Box,
    Typography,
    Container,
    Grid,
    TextField,
    Tooltip,
    MenuItem,
    Autocomplete,
    Link,
    CircularProgress
} from "@mui/material";
import {usePointOfInterest, useCard} from "../../hooks";
import { ICard, IKeyword } from "../../interfaces";
// import background from "../../assets/general_background.png";
import "./CreatePOI.css";
import { Snackbar } from '@mui/material';
import {Clear, Mouse} from '@mui/icons-material';
import {MapComponent} from "./MapComponent";
import {IHyperlink} from "../../interfaces/IHyperlink";

import { customIcon } from '../Map/MarkerComponent'

const snackBarMessage = {
    poiCreated: 'Point of Interest Created!',
    emptyKeyword: 'Please write or select a keyword to add first.',
    emptyHyperlink: 'Please write a hyperlink to add first.',
    emptyTitleHyperlink: 'Please give the hyperlink a title first.',
    incompleteInfo: 'Please fill the identification info before creating the point.',
    noKeywords: 'Please add at least one keyword to the point.',
    repeatedKeyword: 'Cannot add the same keyword twice.',
    repeatedHyperlink: 'Cannot add the same hyperlink twice.',
    repeatedHlTitle: 'Cannot add the same hyperlink title twice.',
    poiExists: 'A POI with this name already exists.'
}

export const hyperlinkTypes = {
    profile: 'Profile',
    processTechniques: 'Process/Techniques',
    teachingTools: 'Teaching Tools',
    learnMore: 'Learn More'
}

export const poiTypes = [
    'Artist/Studio',
    'Manufacturer',
    'Museum/Collection',
    'Supplier',
    'Education',
    'Residency',
    'Gallery/Design Store',
    'Craft Councils'
]
// export const poiTypes = {
//     artist: 'Artist/Studio',
//     manufacturer: 'Manufacturer',
//     museum: 'Museum/Collection',
//     supplier: 'Supplier',
//     education: 'Education',
//     residency: 'Residency',
//     gallery: 'Gallery/Design Store',
//     councils: 'Craft Councils'
// }

interface Props {
    keywords: IKeyword[],
    getAllKeywords: () => Promise<void>
}

export const CreatePOI: React.FC<Props> = ({keywords, getAllKeywords}) => {

    const {pois, setPois, getAllPointsOfInterest, createPointOfInterest} = usePointOfInterest();
    const { cardsTitle, getAllCardsTitle } = useCard();

    const [loading, setLoading] = useState<boolean>(false);

    const [poiName,setPoiName] = useState<string>('');
    const [poiLat,setPoiLat] = useState<string>('');
    const [poiLng,setPoiLng] = useState<string>('');
    const [poiType, setPoiType] = useState<string>('');
    const [poiKeywords, setPoiKeywords] = useState<string[]>([]);
    const [poiProfile,setPoiProfile] = useState<string>('');
    const [hyperlinks, setHyperlinks] = useState<IHyperlink[]>([]);
    const [poiProcTec,setPoiProcTec] = useState<string>('');
    const [poiTeachTools,setPoiTeachTools] = useState<string>('');
    const [poiLMore,setPoiLMore] = useState<string>('');
    const [poiCards, setPoiCards] = useState<ICard[]>([]);
    /**/
    const [openSnackbar,setOpenSnackbar] = useState<boolean>(false);
    const [messageInfo, setMessageInfo] = useState('');

    const [currentKeyword,setCurrentKeyword] = useState<string>('');
    const [currentProfHyperlink,setCurrentProfHyperlink] = useState<string>('');
    const [currentProfHlTitle, setCurrentProfHlTitle] = useState<string>('');
    const [currentPTHyperlink,setCurrentPTHyperlink] = useState<string>('');
    const [currentPTHlTitle, setCurrentPTHlTitle] = useState<string>('');
    const [currentTTHyperlink,setCurrentTTHyperlink] = useState<string>('');
    const [currentTTHlTitle, setCurrentTTHlTitle] = useState<string>('');
    const [currentLMHyperlink,setCurrentLMHyperlink] = useState<string>('');
    const [currentLMHlTitle, setCurrentLMHlTitle] = useState<string>('');
    const [currentCard, setCurrentCard] = useState<ICard>();

    useEffect(() => {
        getAllPointsOfInterest();
        getAllCardsTitle();
    }, [getAllPointsOfInterest, getAllCardsTitle]);

    const MapEventComponent = () => {
        useMapEvents({
            click(event) {
                const {lat, lng} = event.latlng
                setPoiLat(lat.toFixed(5).toString());
                setPoiLng(lng.toFixed(5).toString());

            }
        });

        return null;
    }

    const resetFields = () => {
        setPoiName('');
        setPoiLat('');
        setPoiLng('');
        setPoiType('');
        setPoiKeywords([]);
        setPoiProfile('');
        setHyperlinks([]);
        setPoiProcTec('');
        setPoiTeachTools('');
        setPoiLMore('');
        setPoiCards([]);
    }

    const handleSendPOI = async () => {
        if (poiName === "" || poiLat === "" || poiLng === "" || poiType === "") {
            setMessageInfo(snackBarMessage.incompleteInfo);
            setOpenSnackbar(true);
        }

        else if (poiKeywords.length === 0) {
            setMessageInfo(snackBarMessage.noKeywords);
            setOpenSnackbar(true);
        }

        else {
            setLoading(true)
            await createPointOfInterest({
                id: -1,
                name: poiName,
                lat: parseFloat(poiLat),
                lng: parseFloat(poiLng),
                type: poiType,
                profile: poiProfile,
                processTechniques: poiProcTec,
                keywords: poiKeywords,
                teachingTools: poiTeachTools,
                learnMore: poiLMore,
                hyperlinks: hyperlinks,
                cards: poiCards
            }).then((createdPOI) => {
                setPois([...pois,createdPOI]);
                getAllKeywords();
                setMessageInfo(snackBarMessage.poiCreated);
                setOpenSnackbar(true);
                resetFields();
                setLoading(false);
            }).catch( ({response}) => {
                if (response.status === 409) {
                    setMessageInfo(snackBarMessage.poiExists);
                    setOpenSnackbar(true);
                }
            });
        }
    }

    const handleAddKeyword = () => {
        if (currentKeyword === '') {
            setMessageInfo(snackBarMessage.emptyKeyword);
            setOpenSnackbar(true);
        }
        else if (poiKeywords.includes(currentKeyword)) {
            setMessageInfo(snackBarMessage.repeatedKeyword);
            setOpenSnackbar(true);
        }
        else {
            setPoiKeywords([...poiKeywords, currentKeyword]);
            setCurrentKeyword('');
        }
    }

    const removeKeyword = (index:number) => {
        poiKeywords.splice(index,1);
        setPoiKeywords([...poiKeywords]);
    }

    const handleAddHyperlink = (currentHyperlink: string, setCurrentHyperlink: React.Dispatch<React.SetStateAction<string>>, currentHlTitle: string, setCurrentHlTitle: React.Dispatch<React.SetStateAction<string>>, field: string) => {
        if (currentHyperlink === '') {
            setMessageInfo(snackBarMessage.emptyHyperlink);
            setOpenSnackbar(true);
        }
        else if (currentHlTitle === '') {
            setMessageInfo(snackBarMessage.emptyTitleHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find((hl) => hl.url === currentHyperlink && hl.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find((hl) => hl.title === currentHlTitle && hl.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHlTitle);
            setOpenSnackbar(true);
        }
        else {
            setHyperlinks([...hyperlinks, {title: currentHlTitle, url: currentHyperlink, field: field}]);
            setCurrentHyperlink('');
            setCurrentHlTitle('');

        }
    }

    const removeHyperlink = (hl: IHyperlink) => {
        hyperlinks.splice(hyperlinks.indexOf(hl), 1);
        setHyperlinks([...hyperlinks]);
    }


    const handleAddCard = (card: ICard) => {
        if (card && !poiCards.find((currentCard) => currentCard.id === card.id)) {
            setPoiCards((prevPoiCards) => [...prevPoiCards, card]);
        }
    };

    const handleRemoveCard = (card: ICard) => {
        setPoiCards((prevPoiCards) => prevPoiCards.filter((c) => c !== card));
    };

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={7}>
                    <MapContainer center={[39.505, -8.09]} zoom={5}>
                        {/* <TileLayer
                            // attribution='&copy; <a href="http://stamen.com">Stamen</a> contributors'
                            attribution='&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                        /> */}
                        <TileLayer
                            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
                            noWrap={true}
                        />
                        <MapEventComponent></MapEventComponent>
                        <MapComponent pois={pois}/>

                        {poiLat && poiLng &&
                        <Marker
                            icon = {customIcon}
                            position={[parseFloat(poiLat),parseFloat(poiLng)]}
                            interactive={true}

                        />
                        }

                    </MapContainer>
                </Grid>

                <Grid item xs={5} height={'91vh'} overflow={'auto'}>
                    <Container sx={{paddingTop:'67px'}} maxWidth={"xl"}>
                        <Box justifyContent={"center"} sx={{ marginTop: '2%', height:'5%', display: 'flex' }}>
                            <Typography variant = "h5" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                Create Your Point Of Interest
                            </Typography>
                        </Box>
                            {
                                loading && <Container className='loadingContainerCard'>
                                <div className='loadingDiv'>
                                  <CircularProgress color="inherit" />
                                  <div>Loading...</div>
                                </div>
                              </Container>
                            }
                            {!loading &&
                            <Box>
                                <Typography variant="h6" pt={1} align={"left"} sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Identification Info
                                </Typography>
                                <Box pt={1} height={'100%'} width={'100%'}>
                                        <Box height={'56%'}>
                                            <TextField
                                                fullWidth
                                                required
                                                label="Name"
                                                value={poiName}
                                                onChange={(e) => setPoiName(e.currentTarget.value)} />
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"} style={{ width: '100%' }}>

                                                <TextField style={{ width: '45%' }}
                                                    required
                                                    label="Latitude"
                                                    value={poiLat}
                                                    onChange={(e) => setPoiLat(e.currentTarget.value)} />
                                                <TextField style={{ width: '45%' }}
                                                    required
                                                    label="Longitude"
                                                    value={poiLng}
                                                    onChange={(e) => setPoiLng(e.currentTarget.value)} />

                                                <Tooltip title={"Click on the map to select the location you want or just type the coordinates yourself"}>
                                                    <Mouse sx={{ marginLeft: '1%', paddingBottom: '1%', alignSelf: "center" }}></Mouse>
                                                </Tooltip>

                                            </Box>
                                            <Box pt={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    select
                                                    label="Type"
                                                    value={poiType}
                                                    onChange={(e) => setPoiType(e.target.value)}
                                                    helperText="Please select the type of your Point of Interest"
                                                >

                                                    <MenuItem value={'Artist/Studio'}>
                                                        Artist/Studio
                                                    </MenuItem>
                                                    <MenuItem value={'Manufacturer'}>
                                                        Manufacturer
                                                    </MenuItem>
                                                    <MenuItem value={'Museum/Collection'}>
                                                        Museum/collection
                                                    </MenuItem>
                                                    <MenuItem value={'Supplier'}>
                                                        Supplier
                                                    </MenuItem>
                                                    <MenuItem value={'Education'}>
                                                        Education
                                                    </MenuItem>
                                                    <MenuItem value={'Residency'}>
                                                        Residency
                                                    </MenuItem>
                                                    <MenuItem value={'Gallery/Design Store'}>
                                                        Gallery/Design Store
                                                    </MenuItem>
                                                    <MenuItem value={'Craft Councils'}>
                                                        Craft Councils
                                                    </MenuItem>

                                                </TextField>
                                            </Box>

                                            {keywords && <Box>
                                                <Typography variant="h6" pt={1} align={"left"} sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                                    Keywords
                                                </Typography>

                                                <Box className={'keyword-box-w-ac'}>

                                                    <Box width={'70%'} pt={1}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={keywords.map((keyword) => { return (keyword.name); })} //.sort((a, b) => (a > b) ? 1 : -1)} //sort alphabetically
                                                            value={currentKeyword}
                                                            onChange={(_, value) => value && setCurrentKeyword(value)}
                                                            isOptionEqualToValue={() => true} //to avoid warning since '' is not present in options
                                                            renderInput={(params) => <TextField {...params} onChange={(e) => setCurrentKeyword(e.currentTarget.value)} label="Select or write a keyword" helperText={"Specific concept that describes the artist/place."} />} />
                                                    </Box>
                                                    <Button onClick={handleAddKeyword} variant={"contained"} color={"success"} className={'add-keyword-but'} sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}>Add Keyword</Button>

                                                </Box>

                                                {poiKeywords.length !== 0 && <Box>
                                                    {poiKeywords.map((kw, index) => {
                                                        return (
                                                            <Box pt={1} width={'70%'} className={'same-row'} key={kw}>
                                                                <Button variant={"contained"} color="secondary" sx={{ cursor: 'default', textTransform: 'none', height: '5%', marginRight: '1%', borderRadius: '2em' }}>{kw}</Button>
                                                                <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeKeyword(index)} />
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>}

                                            </Box>}

                                            <Typography variant="h6" pt={1} align={"left"} sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                                Craft Specific Info
                                            </Typography>
                                            <Box pt={1}>
                                                <TextField fullWidth
                                                    multiline
                                                    maxRows={2}
                                                    label="Profile"
                                                    helperText="Introduce yourself or your institution."
                                                    inputProps={{
                                                        maxLength: 500,
                                                    }}
                                                    value={poiProfile}
                                                    onChange={(e) => setPoiProfile(e.currentTarget.value)} />
                                            </Box>

                                            <Box className={'keyword-box-w-ac'}>

                                                <Box width={'70%'}>
                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Profile Hyperlink Title"
                                                            value={currentProfHlTitle}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentProfHlTitle(e.currentTarget.value)} />
                                                    </Box>

                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Profile Hyperlink"
                                                            helperText="Clickable hyperlink to add to the profile field."
                                                            value={currentProfHyperlink}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentProfHyperlink(e.currentTarget.value)} />
                                                    </Box>
                                                </Box>

                                                <Button onClick={() => handleAddHyperlink(currentProfHyperlink, setCurrentProfHyperlink, currentProfHlTitle, setCurrentProfHlTitle, hyperlinkTypes.profile)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{ marginTop: '1%', marginBottom: '1%', placeSelf: 'center' }}>Add Hyperlink</Button>

                                            </Box>

                                            {hyperlinks.filter((hl) => hl.field === hyperlinkTypes.profile).map((phl, index) => {
                                                return (
                                                    <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                                        <Box>
                                                            <Typography sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}> {phl.title}</Typography>
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                    window.open(phl.url, '_blank');
                                                                }}
                                                            >
                                                                {phl.url}
                                                            </Link>
                                                        </Box>
                                                        <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(phl)} />
                                                    </Box>
                                                );
                                            })}

                                            <Box pt={2}>
                                                <TextField fullWidth
                                                    multiline
                                                    maxRows={2}
                                                    label="Process/Techniques"
                                                    helperText="Describe your specialist process(es)."
                                                    inputProps={{
                                                        maxLength: 500,
                                                    }}
                                                    value={poiProcTec}
                                                    onChange={(e) => setPoiProcTec(e.currentTarget.value)} />
                                            </Box>

                                            <Box className={'keyword-box-w-ac'}>
                                                <Box width={'70%'}>
                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Process/Techniques Hyperlink Title"
                                                            value={currentPTHlTitle}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentPTHlTitle(e.currentTarget.value)} />
                                                    </Box>

                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Process/Techniques Hyperlink"
                                                            helperText="Clickable hyperlink to add to the process/techniques field."
                                                            value={currentPTHyperlink}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentPTHyperlink(e.currentTarget.value)} />
                                                    </Box>
                                                </Box>
                                                <Button onClick={() => handleAddHyperlink(currentPTHyperlink, setCurrentPTHyperlink, currentPTHlTitle, setCurrentPTHlTitle, hyperlinkTypes.processTechniques)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{ marginTop: '1%', marginBottom: '1%', placeSelf: 'center' }}>Add Hyperlink</Button>

                                            </Box>

                                            {hyperlinks.filter((hl) => hl.field === hyperlinkTypes.processTechniques).map((pthl, index) => {
                                                return (
                                                    <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                                        <Box>
                                                            <Typography sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}> {pthl.title}</Typography>
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                    window.open(pthl.url, '_blank');
                                                                } }
                                                            >
                                                                {pthl.url}
                                                            </Link>
                                                        </Box>
                                                        <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(pthl)} />
                                                    </Box>
                                                );
                                            })}

                                            <Box pt={2}>
                                                <TextField fullWidth
                                                    multiline
                                                    maxRows={2}
                                                    label="Teaching tools"
                                                    helperText="Place teaching resources here."
                                                    inputProps={{
                                                        maxLength: 450, //originally 150 but as there can be more than one, it is x3
                                                    }}
                                                    value={poiTeachTools}
                                                    onChange={(e) => setPoiTeachTools(e.currentTarget.value)} />
                                            </Box>

                                            <Box className={'keyword-box-w-ac'}>

                                                <Box width={'70%'}>
                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Teaching tools Hyperlink Title"
                                                            value={currentTTHlTitle}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentTTHlTitle(e.currentTarget.value)} />
                                                    </Box>

                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Teaching tools Hyperlink"
                                                            helperText="Clickable hyperlink to add to the teaching tools field."
                                                            value={currentTTHyperlink}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentTTHyperlink(e.currentTarget.value)} />
                                                    </Box>
                                                </Box>

                                                <Button onClick={() => handleAddHyperlink(currentTTHyperlink, setCurrentTTHyperlink, currentTTHlTitle, setCurrentTTHlTitle, hyperlinkTypes.teachingTools)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{ marginTop: '1%', marginBottom: '1%', placeSelf: 'center' }}>Add Hyperlink</Button>

                                            </Box>

                                            {hyperlinks.filter((hl) => hl.field === hyperlinkTypes.teachingTools).map((tthl, index) => {
                                                return (
                                                    <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                                        <Box>
                                                            <Typography sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}> {tthl.title}</Typography>
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                    window.open(tthl.url, '_blank');
                                                                }}
                                                            >
                                                                {tthl.url}
                                                            </Link>
                                                        </Box>
                                                        <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(tthl)} />
                                                    </Box>
                                                );
                                            })}

                                            <Box pt={2}>
                                                <TextField fullWidth
                                                    multiline
                                                    maxRows={2}
                                                    label="Learn More"
                                                    helperText="Further describe your field of work and /or place further study materials in the links section."
                                                    inputProps={{
                                                        maxLength: 1000,
                                                    }}
                                                    value={poiLMore}
                                                    onChange={(e) => setPoiLMore(e.currentTarget.value)} />
                                            </Box>

                                            <Box className={'keyword-box-w-ac'}>
                                                <Box width={'70%'}>
                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Learn More Hyperlink Title"
                                                            value={currentLMHlTitle}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentLMHlTitle(e.currentTarget.value)} />
                                                    </Box>

                                                    <Box pt={1}>
                                                        <TextField fullWidth
                                                            label="Learn More Hyperlink"
                                                            helperText="Clickable hyperlink to add to the learn more field."
                                                            value={currentLMHyperlink}
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            onChange={(e) => setCurrentLMHyperlink(e.currentTarget.value)} />
                                                    </Box>
                                                </Box>
                                                <Button onClick={() => handleAddHyperlink(currentLMHyperlink, setCurrentLMHyperlink, currentLMHlTitle, setCurrentLMHlTitle, hyperlinkTypes.learnMore)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{ marginTop: '1%', marginBottom: '1%', placeSelf: 'center' }}>Add Hyperlink</Button>

                                            </Box>

                                            {hyperlinks.filter((hl) => hl.field === hyperlinkTypes.learnMore).map((lmhl, index) => {
                                                return (
                                                    <Box pt={1} width={'70%'} className={'same-row'} key={index}>
                                                        <Box>
                                                            <Typography sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}> {lmhl.title}</Typography>
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                    window.open(lmhl.url, '_blank');
                                                                }}
                                                            >
                                                                {lmhl.url}
                                                            </Link>
                                                        </Box>
                                                        <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                                    </Box>
                                                );
                                            })}

                                            <Box>
                                                <Typography variant="h6" pt={1} align="left" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                                    Associated Cards
                                                </Typography>

                                                <Box className="card-association-box">
                                                    <Box width="70%" pt={1}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={cardsTitle}
                                                            getOptionLabel={(option) => option.title}
                                                            value={undefined}
                                                            onChange={(_, value) => value && setCurrentCard(value)}
                                                            isOptionEqualToValue={() => true}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Select cards" helperText="Choose cards to associate with the point of interest" />
                                                            )} />
                                                    </Box>
                                                    <Button
                                                        onClick={() => {
                                                            // Perform any necessary action with the selected cards, such as saving them to the backend
                                                            if (currentCard)
                                                                handleAddCard(currentCard);
                                                        }}
                                                        variant="contained"
                                                        color="success"
                                                        className="add-card-button"
                                                        sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}
                                                    >
                                                        Add Card
                                                    </Button>
                                                </Box>

                                                {poiCards.length !== 0 && (
                                                    <Box>
                                                        {poiCards.map((card, index) => (
                                                            <Box pt={1} width="70%" className="same-row" key={"card" + index}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    sx={{ cursor: 'default', textTransform: 'none', height: '5%', marginRight: '1%', borderRadius: '2em' }}
                                                                >
                                                                    {card.title}
                                                                </Button>
                                                                <Clear cursor="pointer" fontSize="large" color="action" className="center-icon" onClick={() => handleRemoveCard(card)} />
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>

                                        </Box>
                                        <Button onClick={handleSendPOI} variant={"contained"} className="submit-button" sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}>Create</Button>
                                        <Snackbar
                                            open={openSnackbar}
                                            autoHideDuration={2000}
                                            message={messageInfo}
                                            onClose={handleClose}/>
                                    </Box>
                            </Box>
                            }
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

import {Box, Button, Card, CardContent, Container, Snackbar, TextField, Typography} from "@mui/material";
// import background from "../../assets/general_background.png";
import React, {useContext, useState} from "react";
import {useAuthentication} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {AuthContext, AuthType} from "../contexts/AuthContext";
import jwtDecode from 'jwt-decode';

const snackBarMessage = {
    not_found: 'User not found!',
    password: 'Credentials are incorrect!',
}


export const Login = () => {

    const [email,setEmail] = useState<string>('');
    const [password,setPassword] = useState<string>('');
    const [messageInfo,setMessageInfo] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const {signIn} = useAuthentication();
    const navigate = useNavigate();
    const {setAuth} = useContext(AuthContext) as AuthType;

    const handleSignIn = async () => {
        try {
          const response = await signIn({ email: email, password: password });
      
          if (response.status === 200) {
            const { token } = response.data;
      
            // Store the token
            localStorage.setItem('token', token);
      
            // Decode the token to extract expiration timestamp
            const decodedToken: { exp?: number } = jwtDecode(token);
            const expirationTimestamp = decodedToken.exp;
      
                  // Store the expiration timestamp
            if (expirationTimestamp) {
                localStorage.setItem('tokenExpiration', expirationTimestamp.toString());
            }
            // Check if token has expired
            const isTokenValid = expirationTimestamp ? Date.now() < expirationTimestamp * 1000 : false;
      
            if (isTokenValid) {
              setAuth(true);
              navigate('/');
            } else {
              // Token has expired
              setMessageInfo('Your session has expired. Please sign in again.');
              setOpenSnackbar(true);
              // Perform any additional actions like clearing user data, redirecting to sign-in page, etc.
            }
          } else if (response.status === 404) {
            // User doesn't exist
            setMessageInfo(snackBarMessage.not_found);
            setOpenSnackbar(true);
          } else if (response.status === 403) {
            // Password is wrong
            setMessageInfo(snackBarMessage.password);
            setOpenSnackbar(true);
          } else {
            // Handle other responses
            setMessageInfo('An unexpected error occurred during sign in');
            setOpenSnackbar(true);
          }
        } catch (error) {
          // Handle network or other error
          console.log('Error:', error);
          setMessageInfo('An error occurred during sign in');
          setOpenSnackbar(true);
        }
    }  
    
    return  <Container maxWidth={"xl"} sx={{marginTop:'67px', height: '100%'}}>
                <Box display={'flex'} justifyContent={'center'} paddingTop={'5%'}>
                    <Card sx={{margin: 0, width: 400, height: 350}}>
                        <CardContent>
                            <Typography variant={'h5'} paddingBottom={'10%'} textAlign={'center'} fontWeight={'bold'} gutterBottom>
                                Login
                            </Typography>
                            <Box pb = "5%">
                                <TextField fullWidth
                                           label="E-mail"
                                           value={email}
                                           required
                                           onChange={(e) => setEmail(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pb = "8%">
                            <TextField fullWidth
                                label="Password"
                                value={password}
                                type="password"
                                required
                                onChange={(e) => setPassword(e.currentTarget.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSignIn();
                                    }
                                }}
                            />
                            </Box>
                        </CardContent>
                        <Box textAlign={'center'}>
                            <Button onClick={handleSignIn} variant={"contained"} sx={{backgroundColor:'#2699FB', borderRadius: '2em', width: '90%'}}>Sign in</Button>
                        </Box>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={2000}
                            message={messageInfo}
                            onClose = {() => setOpenSnackbar(false)}
                        />

                    </Card>
                </Box>
            </Container>

}
import {useCallback, useState} from 'react'
import {PointOfInterestService} from "../services";
import {IPointOfInterest, IPointOfInterestModify, IVideo} from "../interfaces";

export const usePointOfInterest = () => {
    const [pois, setPois] = useState<IPointOfInterest[]>([]);
    const [poisName, setPoisName] = useState<IPointOfInterest[]>([]);

    const getAllPointsOfInterest = useCallback(async () => {

        const {status,data} = await PointOfInterestService.getAllPointsOfInterest();

        if (status !== 200) throw new Error();
        setPois( data.sort(() => Math.random() - 0.5));

    }, []); //only activates when var in dep changes
                //empty means it is activated only once

    const getAllPointsOfInterestName = useCallback(async () => {

        const {status,data} = await PointOfInterestService.getAllPointsOfInterestName();

        if (status !== 200) throw new Error();
        setPoisName(data);

    }, []); //only activates when var in dep changes
                //empty means it is activated only once

    const getPointOfInterestById = useCallback(async (id:number) => {
        
        const {status,data} = await PointOfInterestService.getPointOfInterestById(id);
    
        if (status !== 200) throw new Error();
        setPois(prevPois => {
            const newPois = [...prevPois];
            const poiIndex = newPois.findIndex((poi) => poi.id === id);
            newPois[poiIndex] = data;
            return newPois;
        });
        return data;
    }, []); // pois removed from dependencies
                

    const getPointOfInterestByName = useCallback(async (name:string) => {

        const {status,data} = await PointOfInterestService.getPointOfInterestByName(name);
    
        if (status !== 200) throw new Error();
        setPois(prevPois => {
            const newPois = [...prevPois];
            const poiIndex = newPois.findIndex((poi) => poi.name === name);
            newPois[poiIndex] = data;
            return newPois;
        });
        return data;
    }, []); // pois removed from dependencies

    // const getPointOfInterestByName = useCallback(async (name:string) => {

    //     const {status,data} = await PointOfInterestService.getPointOfInterestByName(name);

    //     if (status !== 200) throw new Error();
    //     const poiIndex = pois.findIndex((poi) => poi.name === name);
    //     pois.splice(poiIndex,1,data);
    //     setPois(pois);
    //     return data;

    // }, [pois]);

    const createPointOfInterest = useCallback( async (poi: IPointOfInterestModify) => {
            const {status, data} = await PointOfInterestService.createPointOfInterest(poi);

            if(status !== 201) throw new Error();

            return data;
        },
        []);

    const updatePointOfInterest = useCallback( async (poi: IPointOfInterestModify) => {
            const {status} = await PointOfInterestService.updatePointOfInterest(poi);

            if(status !== 200) throw new Error();
        },
        []);

    const deletePointOfInterest = useCallback( async (id: number) => {
            const {status} = await PointOfInterestService.deletePointOfInterest(id);

            if(status !== 200) throw new Error();
        },
        []);

    const updatePointOfInterestVideos = useCallback( async (poiName: string, videos: IVideo[]) => {
            const {status} = await PointOfInterestService.updatePointOfInterestVideos(poiName, videos);

            if(status !== 201) throw new Error()
        },
        []);

    const deletePointOfInterestVideo = useCallback( async (poiName: string, videoUrl: string) => {
            const {status} = await PointOfInterestService.deletePointOfInterestVideo(poiName, videoUrl);

            if(status !== 200) throw new Error()
        },
        []);

    const removeKeywordFromPOI = useCallback( async (poiName:string, keyword:string) => {
        const {status} = await PointOfInterestService.removeKeywordFromPOI(poiName, keyword);

        if(status !== 200) throw new Error();

    }, []);

    const removeHyperlinkFromPOI = useCallback( async (poiName:string, field:string, title:string) => {
        const {status} = await PointOfInterestService.removeHyperlinkFromPOI(poiName, field, title);

        if(status !== 200) throw new Error();

    }, []);

    return {
        pois,
        setPois,
        poisName,
        setPoisName,
        getAllPointsOfInterest,
        getAllPointsOfInterestName,
        getPointOfInterestById,
        getPointOfInterestByName,
        createPointOfInterest,
        updatePointOfInterest,
        deletePointOfInterest,
        updatePointOfInterestVideos,
        deletePointOfInterestVideo,
        removeKeywordFromPOI,
        removeHyperlinkFromPOI
    }
}
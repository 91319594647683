import { Box, Grid, Link, Typography } from '@mui/material';
import './Footer.css'
import logo from '../assets/1nBVzbQw.png';
import logo_craft from '../assets/craft_white.png';
import erasmus from '../assets/erasmusplus.jpg';
import cc from '../assets/cc/cc.xlarge.png'
import by from '../assets/cc/by.xlarge.png'
import nc from '../assets/cc/nc.xlarge.png'
import nd from '../assets/cc/nd.xlarge.png'

function Footer() {

  return (
    <Box className='footerContainer'>
        <Grid container spacing={4} className='linkGridContainer'>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
              <Link href="/" className='footerLink'>Home</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
                <Link href="/cards" className='footerLink'>Decode</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
                <Link href="/map" className='footerLink'>Map</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
                <Link href="/meet" className='footerLink'>Team</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
                <Link href="/join" className='footerLink'>Join Us</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body2" component="div">
                <Link href="/help" className='footerLink'>Help</Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{padding:'12px'}} className='bottomGridContainer'>
            <Grid item xs={12} sm={6} md={2}>
                <img
                    src={logo}
                    alt="decoding Logo"
                    style={{
                    height: '100%', // Adjust the height as needed
                    width: 'auto'
                    }}
                />
                <img
                    src={logo_craft}
                    alt="CRAFT Logo"
                    style={{
                    marginTop: '40px',
                    height: '90%', // Adjust the height as needed
                    width: 'auto'
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <img
                    src={erasmus}
                    alt="Erasmus Logo"
                    style={{
                      height: 'auto',
                      maxHeight: '40px',
                      width: 'auto',
                      marginRight: '10px',
                    }}
                />
                <br/>
                <Typography variant='caption'>
                  Project Reference:
                  <br/>
                  2020-1-UK01-KA203-079249
                </Typography>
                <br/>
                <br/>
              <Grid container className='ccGridContainer'>
                    <Grid item>
                      <img
                        alt='cc'
                        src={cc}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        alt='by'
                        src={by}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        alt='nc'
                        src={nc}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        alt='nd'
                        src={nd}
                      />
                    </Grid>
              </Grid>
              <Typography variant='caption'>
                  This work is licensed under CC-BY-NC-ND 4.0
                  <br/>
                  This license requires that reusers give credit to the creator. It allows to copy and distribute the material in any medium or format in unadapted form and for noncommercial purposes only.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant='caption'><b>Decoding Ceramics</b> is the output of the three-year strategic partnership CRAFT: Activating Pedagogy for Ceramic Education Futures and was co-funded by the Erasmus + programme 
                  of the European Union under Grant agreement (2020-1-UK01-KA203-079249). 
                  The European Commission’s support for the production of this project does not constitute an 
                  endorsement of the contents, which reflect the views only of the authors. The Commission 
                  cannot be held responsible for any use which may be made of the information contained therein.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant='body1'>
                  <b>
                    Project Management and Implementation:
                  </b>
                </Typography>
                <Typography variant='body2'>
                  <a className='mailTo' href = "mailto: a.quinn@csm.arts.ac.uk">a.quinn@csm.arts.ac.uk</a>
                </Typography>
                <br/>
              <Typography variant='body1' >
                <b>
                  Email us:
                </b>
              </Typography>
              <Typography variant='body2' >
                Do you have any questions, suggestions or contributions about the CRAFT project
                or the Decoding Ceramics Platform? Then feel free to contact us: <a className='mailTo' href = "mailto: decoding_ceramics@arts.ac.uk">decoding_ceramics@arts.ac.uk</a>
              </Typography>
            </Grid>
        </Grid>
    </Box>
  );
}

export default Footer;

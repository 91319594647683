import React from 'react';
import {Marker, Popup} from "react-leaflet";
import {Box, List} from "@mui/material";
import {IPointOfInterest} from "../../interfaces";
import { Icon } from 'leaflet';

export const customIcon = new Icon({
    iconUrl: require('../../assets/map_icon.png'),
    iconSize: [26, 42], // size of the icon
    iconAnchor: [13, 42], // point of the icon which will correspond to marker's location
  });
  
interface Props {
    poi: IPointOfInterest
}

export const MarkerComponent: React.FC < Props > = ({poi}) => {

    return <Marker icon={customIcon} position={[poi.lat, poi.lng]}>
                <Popup>
                    <Box sx={{height:'30%', width: '30%'}}>
                        {poi.images && poi.images.length !==0 &&
                            <img src={poi.images[0].uuid as string}
                                 srcSet={`${poi.images[0].uuid as string}?w=64&h=64&fit=crop&auto=format&dpr=2 2x`}
                                 alt={poi.name}
                                 loading="lazy"/>

                        }
                    </Box>
                    Here stands <b>{poi.name}</b> <br/>
                    <br/>
                    <b> CRAFT INFO: </b> <br/>
                    <br/>
                    <b>Profile:</b>
                    {poi.profile}
                    <br/>
                    <b>Process/Techniques:</b>
                    {poi.processTechniques}
                    <br/>
                    <b>Searching Keywords:</b>
                    <List>
                        {poi.keywords.map((k) => {
                                return (<b key={k.name}>{k.name}</b>)
                            }
                        )}
                    </List>
                    <br/>
                    <b>Teaching Tools:</b>
                    {poi.teachingTools}
                    <br/>
                    <b>Learn More:</b>
                    {poi.learnMore}

                </Popup>
        </Marker>;
}
import {useCallback, useState} from 'react'
import {IKeyword, IPointOfInterest} from "../interfaces";
import {KeywordService} from "../services";

export const useKeyword = () => {

    const [keywords, setKeywords] = useState<IKeyword[]>([]);
    const [popularKeywords, setPopularKeywords] = useState<IKeyword[]>();
    const [uniqueKeywords, setUniqueKeywords] = useState<IKeyword[]>();

    const getAllKeywords = useCallback( async () => {
        const {status,data} = await KeywordService.getAllKeywords();

        if(status !== 200) throw new Error();
        setKeywords(data);

    }, [],);

    const getPopularKeywords = useCallback( async () => {
        const {status,data} = await KeywordService.getPopularKeywords();

        if(status !== 200) throw new Error();
        setPopularKeywords(data);

    }, [],);

    const getUniqueKeywords = useCallback( async () => {
        const {status,data} = await KeywordService.getUniqueKeywords();

        if(status !== 200) throw new Error();
        setUniqueKeywords(data);

    }, [],);

    const getPoisByKeyword = useCallback( async (keyword:string, setPoisByKeyword: React.Dispatch<React.SetStateAction<IPointOfInterest[] | undefined>>) => {
        const {status,data} = await KeywordService.getPoisByKeyword(keyword);

        if(status !== 200) throw new Error();
        setPoisByKeyword(data);

    }, [],);

    return {
        keywords,
        popularKeywords,
        uniqueKeywords,
        getAllKeywords,
        getPopularKeywords,
        getUniqueKeywords,
        getPoisByKeyword,
    }
}
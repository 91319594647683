import {useNavigate} from "react-router-dom";
import {Fragment, useContext, useState} from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {AuthContext, AuthType} from "./contexts/AuthContext";
import { Collapse, IconButton } from "@mui/material";
import "../custom-font.css";
import "./Drawer.css";
import backgroundImage from "../assets/general_background.jpeg"
import vase from '../assets/vase_1.png';
import vase_w from '../assets/vase_w_1.png';


interface Props {
    logoColor: number;
}

export const TemporaryDrawer: React.FC <Props> = ({logoColor}) => {
    const navigate = useNavigate();

    const {auth, setAuth} = useContext(AuthContext) as AuthType;

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        setAuth(false);
        navigate('/');
    }

  const [state, setState] = useState(false);

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState(open);
    };
    
    const list = () => (
        <Box
            sx={{ width: 'auto' , color: 'white', display: 'block'}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem key={'home'} disablePadding>
                    <ListItemButton 
                        sx={{ my: 2 }}
                        onClick={ () => navigate('/')}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
                            fontSize: {
                                xs: '16px',  // phones
                                sm: '20px',  // tablets
                                md: '30px',  // small laptops
                                lg: '50px',  // desktops
                                xl: '50px'   // large desktops
                            },
                            '&:hover': {
                                fontWeight: 'bold'
                            }
                        }}} primary='Home' />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'map'} disablePadding>
                    <ListItemButton 
                        sx={{ my: 2 }}
                        onClick={ () => navigate('/map')}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Map' />
                    </ListItemButton>
                </ListItem>
                <Collapse in={auth} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            onClick={() => navigate('/point-of-interest/create')}
                            sx={{pl: 4}}
                            className="listItemButton"
                        >
                            <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary="Create Point of Interest" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => navigate('/point-of-interest/update')}
                            sx={{pl: 4}}
                            className="listItemButton"
                        >
                            <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary="Update Point of Interest" />
                        </ListItemButton>
                    </List>
                </Collapse>
                
                <ListItem key={'cards'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/cards')}
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Decode' />
                    </ListItemButton>
                </ListItem>

                <Collapse in={auth} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            onClick={() => navigate('/cards/create')}
                            sx={{pl: 4}}
                            className="listItemButton"
                        >
                            <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary="Create Card" />
                        </ListItemButton>
                    </List>
                </Collapse>
                </List>
                <Divider />
                <List>

                <ListItem key={'tool'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/toollist')}
                        sx={{ my: 2}}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
                        fontSize: {
                            xs: '16px',  // phones
                            sm: '20px',  // tablets
                            md: '30px',  // small laptops
                            lg: '50px',  // desktops
                            xl: '50px'   // large desktops
                        },
                        '&:hover': {
                            fontWeight: 'bold'
                        }
                    }}} primary='Tool List' />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'meet'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/meet')}
                        sx={{ my: 2}}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Team' />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'join'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/join')}
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Join us' />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'help'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/help')}
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Help' />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'health'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/health')}
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Health & Safety' />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                {auth? <ListItem key={'signout'} disablePadding>
                    <ListItemButton 
                        onClick={ logout }
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Sign out' />
                    </ListItemButton>
                </ListItem> 
                : <ListItem key={'signin'} disablePadding>
                    <ListItemButton 
                        onClick={ () => navigate('/login') }
                        sx={{ my: 2 }}
                        className="listItemButton"
                    >
                        <ListItemText className="drawerItem" primaryTypographyProps={{sx: {
        fontSize: {
            xs: '16px',  // phones
            sm: '20px',  // tablets
            md: '30px',  // small laptops
            lg: '50px',  // desktops
            xl: '50px'   // large desktops
        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }}} primary='Sign in' />
                    </ListItemButton>
                </ListItem>
                }
            </List>
        </Box>
    );

    return (
        <Fragment key={"drawer"}>
            <IconButton
            className="iconButtonVase"
            onClick={toggleDrawer(true)} 
            sx={{ my: 2, color: logoColor===2?'white':'#0000b2', display: 'block' , fontFamily: 'KUniforma'
            ,'&:hover': {
                backgroundColor: 'inherit !important', 
              },
            }}
            >
                <img
                    className="vaseImage"
                    src={logoColor===2?vase_w:vase}
                    alt='Vase Menu'
                    style={{
                    height: '30px', // Adjust the height as needed
                    width: 'auto'
                    }}
                />
            </IconButton>
            <Drawer
                anchor='right'
                open={state}
                onClose={toggleDrawer(false)}
                PaperProps={{
                sx: { width: "70%" , backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover'},
                }}
            >
                {list()}
            </Drawer>
        </Fragment>
    );
}
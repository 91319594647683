import {useCallback, useState} from 'react'
import {CardService} from "../services";
import {ICard, IVideo} from "../interfaces";

export const useCard = () => {
    const [cards, setCards] = useState<ICard[]>([]);
    const [cardsTitle, setCardsTitle] = useState<ICard[]>([]);

    const getAllCards = useCallback(async () => {
        const {status, data} = await CardService.getAllCards();

        if (status !== 200) throw new Error();
        setCards(data);

        return data
    }, []);

    const getAllCardsTitle = useCallback(async () => {
        const {status, data} = await CardService.getAllCardsTitle();

        if (status !== 200) throw new Error();
        setCardsTitle(data);
        return data
    }, []);

    const getCardById = useCallback(async (id:number) => {
        const {status,data} = await CardService.getCardById(id);
        if (status !== 200) throw new Error();
        setCards(prevCards => {
            const cardIndex = prevCards.findIndex((card) => card.id === id);
            return [...prevCards.slice(0, cardIndex), data, ...prevCards.slice(cardIndex + 1)];
        });
        return data;
    }, []);

    const getCardByTitle = useCallback(async (title:string) => {

        const {status,data} = await CardService.getCardByTitle(title);

        if (status !== 200) throw new Error();
        setCards(prevCards => {
            const cardIndex = prevCards.findIndex((card) => card.title === title);
            return [...prevCards.slice(0, cardIndex), data, ...prevCards.slice(cardIndex + 1)];
        });
        return data;
    }, []);

    const createCard = useCallback( async (card: ICard, images:File[]) => {
        const formData = new FormData();
        formData.append("cardDTO", new Blob([JSON.stringify(card)], { type: 'application/json' }));
        images.forEach((image) => {
            formData.append(`images`, image);
          });
        const {status, data} = await CardService.createCard(formData);

        if( status !== 201 ) throw new Error();

        return data;
    }, [])

    const updateCard = useCallback( async (card: ICard) => {
        const {status} = await CardService.updateCard(card);

        if( status !== 200 ) throw new Error()
    }, []);

    const deleteCard = useCallback( async (id: number) => {
        const {status} = await CardService.deleteCard(id);

        if (status !== 200) throw new Error();
    }, []);

    const updateCardVideos = useCallback( async (id: number, videos: IVideo[]) => {
        const {status} = await CardService.updateCardVideos(id, videos);

        if(status !== 201) throw new Error()
    },
    []);

    const deleteCardVideo = useCallback( async (id: number, videoUrl: string) => {
        const {status} = await CardService.deleteCardVideo(id, videoUrl);

        if(status !== 200) throw new Error()
    },
    []);

    const removeKeywordFromCard = useCallback( async (id:number, keyword:string) => {
        const {status} = await CardService.removeKeywordFromCard(id, keyword);

        if(status !== 200) throw new Error();

    }, []);

    const removeHyperlinkFromCard = useCallback( async (id:number, field:string, title:string) => {
        const {status} = await CardService.removeHyperlinkFromCard(id, field, title);

        if(status !== 200) throw new Error();

    }, []);

    const getAllAuthors = useCallback( async () => {
        const {status, data} = await CardService.getAllAuthors();
        if (status !== 200) throw new Error();

        return data
    }, [])

    return {
        cards,
        setCards,
        cardsTitle,
        setCardsTitle,
        getAllCards,
        getAllCardsTitle,
        getCardById,
        getCardByTitle,
        createCard,
        updateCard,
        deleteCard,
        updateCardVideos,
        deleteCardVideo,
        removeHyperlinkFromCard,
        removeKeywordFromCard,
        getAllAuthors
    }
}
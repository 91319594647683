import { Box, Container, Typography} from "@mui/material";
import background from "../assets/partners.png";
import './Help.css'

export const Health: React.FC = () => {
    return (
        <Container className='mainHelpContainer'>
            <Box className='gridHelpPageWrap'>
                <Box className='helpTitleBox'>
                    <Typography variant="h2" className='helpTitle'>Health & Safety</Typography>
                    <Box className='helpBriefBox'>
                        <Typography variant="h6" className='helpBrief'>
                            <br/>
                            The sheer breadth of techniques, processes and materials used in ceramics mean there are many health and safety considerations.
                            <br/>
                            <br/>
                            Many of the precautions are common sense, whilst some require planning your process and workspace in advance.
                            <br/>
                            <br/>
                            Here we list some general Health & Safety considerations. 
                        </Typography>
                    </Box>
                </Box>
                <Box className='helpImageBox' style={{ backgroundImage: `url(${background})` }}>
                    {/* Image will be placed here as a background */}
                </Box>
            </Box>
            <Box className='helpBodyBox'>
                <Typography variant="body1" className='helpBody1'>
                    Risk Assessment
                </Typography>
                <br/>
                <br/>
                When you are working with dangerous machinery or hazardous substances, it is important to conduct a proper Risk Assessment of your work environment.
                A Risk Assessment is a formal analysis of your work environment and your working process.
                It analyses all the factors involved in undertaking a specific activity and formally logs the risks and the actions required to mitigate the risk.
                Look at the space you are using, are tools safely stored, heavy materials at a safe lifting height, toxic materials in sealed labelled containers, is there risk of slipping or tripping, are machines correctly maintained, emergency stop buttons easily accessible, set up instructions visible close by.
                Addressing these questions with a formal risk assessment will result in a very safe working environment.
                <br/>
                <br/>
                Risk assessments are used widely in industrial, large studio or academic settings, and as such require an honest and rigorous approach to the process.
                They can be time consuming to complete but are essential in decoding the workspace practice for optimum Health & Safety.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    The space
                </Typography>
                <br/>
                <br/>
                When setting up your workshop consider the workflow of your practice.
                If your workspace is large then you can create zones or spaces for particular processes, which will simplify health & safety considerations relating to similar techniques.
                If your workspace is small and therefor multi-functional you will need to be more thoughtful in how you plan and structure your activities and processes in order to not create health & safety problems in the management of clay, water, dust, glaze, toxic or heavy materials.
                This is possible if you develop a well thought through working day, allowing time for cleaning up and switching between activities.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Protective Clothing (PPE)
                </Typography>
                <br/>
                <br/>
                Many potential problems can be prevented by adopting good working practice at all times.
                Always wear protective clothing, such as overalls or aprons; these should be made from a polyester-type material that does not hold dust in the same way as natural fabrics, which should be avoided.
                <ul className='helpList'>
                    <li>Long hair should be tied back when using any equipment and machinery, especially throwing wheels.</li>
                    <br/>
                    <li>Appropriate safety glasses should be worn when cleaning and chipping bits off glaze shelves.</li>
                    <br/>
                    <li>Wear appropriate footwear, with full foot cover with a good non-slip sole, when in workshops, especially when loading kilns and climbing ladders.</li>
                    <br/>
                    <li>Always wear appropriate eye protectors when looking into kiln spy holes.</li>
                    <br/>
                    <li>Wear foundry gloves to unpack kilns, and surgical gloves to protect skin and prevent cuts when using ceramic materials.</li>
                    <br/>
                    <li>Never remove protection guards from equipment or override any safety cut-off devices.</li>
                </ul>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Glazing
                </Typography>
                <br/>
                <br/>
                Glaze materials present a very serious risk to the ceramicist, as many materials are toxic or can cause irritation to eyes and skin.
                Therefore how you manage these materials is essential for a safe working environment. 
                When mixing glazes, it is best to work under extraction hoods if the workshop has them, if not a mask with a respirator is the best form of protection against inhaling dusts and toxins, for occasional use a dust mask is the minimum requirement.
                You should also wear surgical gloves to prevent any skin irritation.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Cleaning
                </Typography>
                <br/>
                <br/>
                <ul className='helpList'>
                    <li>Clean workbenches and equipment, including spray booths, after each activity by wet sponging.</li>
                    <br/>
                    <li>Wash all tools after each session. Oil if needed to prevent rust.</li>
                    <br/>
                    <li>Wet wipe all canvas after use and wash it periodically.</li>
                    <br/>
                    <li>Wet mop dusty floors daily rather than sweeping them, which simply creates clouds of dust that will stay airborne for hours. Small bits of clay that fall on the floor should be swept up immediately with a dustpan and brush.</li>
                    <br/>
                    <li>Rinse out water spray misters weekly.</li>
                </ul>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Manual handling
                </Typography>
                <br/>
                <br/>
                Bags of Clay, Glaze materials and Plaster are heavy! Make sure that you observe safe lifting procedures when handling heavy bags.
                When loading a kiln, assess how you are going to pack the shelves to prevent overstretching.
                Adequate shelving is a key part of any studio and you must ensure that the height does not exceed what you can comfortably reach.
                Again, health and safety guidelines should be consulted to find out what the maximum shelving height recommended is.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Dust
                </Typography>
                <br/>
                <br/>
                Probably the biggest health and safety issue in the ceramics workshop is the dust, which can range from an irritant to toxic.
                Clay has tiny silica particles, which are particularly dangerous if inhaled because they build up in the lungs and can cause silicosis, traditionally considered a potter's disease.
                Silicosis and such problems are easily preventable by wearing respirators or masks and switching on an extractor fan.
                Always wet wash benches and floors.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Machinery
                </Typography>
                <br/>
                <br/>
                Wheels, lathes, pug mills, kilns and many more machines associated with ceramics are all potentially dangerous; consult the Safe Systems of Work sheet for any machine before you use it, because this will give you the safe working specification of the machine and details of any features such as emergency stop switches.
                It is important to familiarize yourself with all machines before you use them; ideally, get someone to demonstrate its use.
                Always wear safety goggles and overalls or an apron and secure any loose clothing or long hair so that it cannot be drawn into moving parts of the machine.
                Make sure that machines are turned off before fixing or adjusting them.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Kilns and firing
                </Typography>
                <br/>
                <br/>
                Kilns are very important pieces of workshop equipment and must be loaded and operated correctly each time.
                Follow kiln packing procedures at all times and only use the supplied kiln furniture for firing.
                Do not touch the kiln exterior while firing is in progress, since it can get very hot.
                Give careful consideration to where the kiln is placed.
                Kilns should never be in contact with surrounding walls.
                Suppliers will tell you how far from the wall they can be and provide other useful safety information.
                Ideally, kilns should be placed in a separate room; where this proves difficult, access should be restricted - especially in schools, where they are often placed in a cage.
                The metal surface areas will be very hot during and after firing and could cause burns.
                <ul className='helpList'>
                    <li>If the kiln is indoors, excellent ventilation is required to remove gases, fumes
                    and smoke directly from the kiln. This is normally achieved by installing
                    extractor hoods above the kiln; if this cannot be done, open grilled windows or
                    extractor fans will help. If you are using a kiln in a small studio, fire at night so
                    that you can avoid working in the atmosphere.</li>
                    <br/>
                    <li>Kiln fumes can be harmful if inhaled, since firings give off different gases and
                    fumes. Enamels, lustres and transfer cover coat are particularly noxious and
                    are to be avoided.</li>
                    <br/>
                    <li>Small to medium front-loading kilns should be placed on rollers, to enable
                    access to the back to repair and change the elements and contacts.</li>
                    <br/>
                    <li>Ideally, kilns should be placed on concrete slabs, floors or ceramic tiles - and
                    never on a wooden floor.</li>
                    <br/>
                    <li>When firing is in progress, there should be no combustible materials - for
                    example, wooden boards on which work is drying - in the vicinity.</li>
                    <br/>
                    <li>Use only heavy-duty foundry gloves to unpack work from a warm kiln or to
                    open vent bung bricks and covered spy holes.</li>
                </ul>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Electric kilns
                </Typography>
                <br/>
                <br/>
                All kilns should be fitted by a qualified electrician and should have relevant isolating safety control cut-off switches.
                <ul className='helpList'>
                    <li>Do not have water near any part of the kiln.</li>
                    <br/>
                    <li>When cleaning surfaces, use only a damp sponge and always switch the kiln off.</li>
                    <br/>
                    <li>Purchase the appropriate fire extinguisher for electric fires.</li>
                    <br/>
                    <li>All electric kilns should be fitted with padlocks or safety cut-off electric key
                    locks. New kilns will be fitted with relevant safety cut-off devices.</li>
                    <br/>
                    <li>If the kiln is not reaching temperature, it is likely that at least one of the
                    element banks has failed and needs to be changed. Once the kiln has been
                    switched off and isolated, this is a straightforward procedure. If more than one
                    bank has failed, replace the whole set.</li>
                </ul>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Gas kilns
                </Typography>
                <br/>
                <br/>
                Always check the terms of any local planning regulations, restrictions and laws in relation to installing and using a gas kiln, as well your personal insurance.
                <ul className='helpList'>
                    <li>Full access is required all around gas kilns so that checking and maintenance can be carried out.</li>
                    <br/>
                    <li>You must have regular checks made on all pipework and burners to
                    ensure there are no leaks that may cause an explosion.</li>
                    <br/>
                    <li>Gas is heavier than air and will sit at the lowest level, especially in corners
                    where it can get trapped. Excellent ventilation is essential to allow any gas
                    to disperse.</li>
                    <br/>
                    <li>Burners should have safety cutout devices to prevent gas going into the
                    kiln if not alight.</li>
                    <br/>
                    <li>Explosions will occur if unlit gas enters the chamber at a low temperature
                    and is then lit.</li>
                </ul>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Ceramic fibre
                </Typography>
                <br/>
                <br/>
                This is extensively used in kiln building for its excellent thermal insulation properties.
                Keep up to date with current health and safety regulations regarding its use, since it
                is rated as carcinogenic and a skin irritant. New ones are being developed which are
                safer but not heat rated beyond 1250° C (2280° F).
                <br/>
                <br/>
                Ceramic fibre is a mixture of alumina, silica flux and china clay. It is easy to use but
                should always be handled with care wearing a dust mask and protective gloves.
                Always keep the material dry, because it will break down when wet. There is a liquid
                available that is painted on the surface to provide a bonding coat to help prevent the
                fibre from breaking down so easily.
                Wood, salt and soda firings Consult local planning regulations to see if you are
                allowed to do these firings. More makers are exploring soda firing, as it is believed to
                be considerably less toxic than salt and is tolerated in built-up communities.
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Raku firing
                </Typography>
                <br/>
                <br/>
                This involves working in direct contact with ceramics at very high temperatures.
                All precautions should be taken regarding the siting of the kiln. Loose pipework
                should be covered to prevent trip hazards and burners being pulled out of position.
                Wearing appropriate footwear and protective clothing - gloves, goggles, masks - is
                essential. Have buckets of water and a fire extinguisher close at hand and undertake
                any other fire precautions.
                <br/>
                <br/>
                <br/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                If you are working with other people, carry out a full induction to cover safe working
                procedures and ensure everyone knows their individual role and responsibilities.
                </Typography>
                <br/>
                <br/>
                
            </Box>
        </Container>
    );
}
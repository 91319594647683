import React from 'react';
import { VisualizePOIs } from './Map/VisualizePOIs';
import { CardListPage } from './Card/Cards';
import { IKeyword } from '../interfaces';
import { Box, Button, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import './HomePage.css';
import Footer from './Footer';
import logo from '../assets/blue2_DECODING-CERAMICS_sketch_02.png';

interface HomeProps {
  keywords: IKeyword[];
}

export const HomePage: React.FC<HomeProps> = ({ keywords }) => {
  // Handle just the first 3 POIs and cards for homepage preview
  
  return (
    <div>
      <Box>
        <Box>
            <Typography variant="h5" className='decodingBrief'>
              <b>DECODING CERAMICS: Accessible Ceramic Knowledge</b>
              <br/>
              <br/>
              Decoding Ceramics is a comprehensive and ambitious project, which gathers knowledge and expertise and makes it open and useful to all learners and teachers, at whatever level. 
              <br/>
              <br/>
              Drawing on technical, material and cultural expertise from across Europe, Decoding Ceramics has identified places of specialist knowledge from artist’s studios to micro factories, museums, manufacturers, art schools and research centres. 
              <br/>
              <br/>
              ‘Decoding Cards’ provide step-by-step information of techniques to learn, timing required, materials used, or things to make. Video demonstrations, tools, material and expert tips are included. 
              <br/>
              <br/>
              Our mission is to preserve ceramic knowledge, skills and expertise, to make them easier to understand, learn and apply.
              <br/>
              <br/>
              Decoding Ceramics was generously supported by the Erasmus + programme of the European Union. 
            </Typography>
        </Box>
        <Box>
          <img
              src={logo}
              alt='Decoging Ceramics Logo'
              style={{
              height: 'auto', // Adjust the height as needed
              width: '100%'
              }}
          />
        </Box>
        <Box className='mapsListBox'>
          <VisualizePOIs keywords={keywords} isPreview={true}/>
          <RouterLink className='link' to="/map">
            <Button className='mapButton' >
              Go to Full Map
            </Button>
          </RouterLink>
        </Box>

        <Box className='cardsListBox'>
          <CardListPage unFilteredKeywords={keywords} isPreview={true}/>
          <Box className='buttonBox'>
            <RouterLink className='link' to="/cards">
              <Button className='cardsButton'>
                  Go to Full Card List
              </Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </div>
  );
};

import { AuthApi } from "../axios-config";
import { Login } from "../interfaces";

const authURLprefix = '/authentication';

const signIn = (loginData: Login) => AuthApi.post(authURLprefix + '/login', loginData);
const validateToken = (token: string) => AuthApi.post(authURLprefix + '/validate-token', { token });
const refreshToken = (token: string) => AuthApi.post(authURLprefix + '/refresh-token', { token }, {headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`}});

export const AuthenticationService = {
  signIn,
  validateToken,
  refreshToken,
};

import { Api } from '../axios-config';

const fileURLprefix = '/image';

const uploadFile = (type:string, formData: FormData, id: number) => {return Api.post<string>(type + fileURLprefix + '/' + id, formData, { headers: {'Content-Type': 'multipart/form-data'}})}

const getFile = (type:string, uuid:string) => {return Api.get<File>(type + fileURLprefix + '/' + uuid, { responseType:"blob" })};

const getFilePreview = (type:string, uuid:string) => {return Api.get<File>(type + fileURLprefix + '/' + uuid + '/preview', { responseType:"blob" })};

const deleteFile = (type:string, uuid:string) => {return Api.delete(type + fileURLprefix + '/' + uuid)};

export const FileTransferService = {
    uploadFile,
    deleteFile,
    getFile,
    getFilePreview
}
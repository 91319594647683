import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';

export const ToolList: React.FC = () => {
//   const iframeStyle = {
//     width: '100%',
//     height: 'calc(100vh - 32px)', // Adjust the height as needed
//     border: 'none',
//   };

const [zoom, setZoom] = useState(1);

useEffect(() => {
  const screenWidth = window.innerWidth;

  // Define different zoom levels for different screen sizes
  let calculatedZoom;
  if (screenWidth < 600) { // Phones
    calculatedZoom = 0.1;
  } else if (screenWidth < 960) { // Tablets
    calculatedZoom = 0.5;
  } else if (screenWidth < 1280) { // Laptops
    calculatedZoom = 0.8;
  } else { // Desktops
    calculatedZoom = 1;
  }

  setZoom(calculatedZoom);
}, []);

const iframeStyle = {
  width: '100%',
  height: 'calc(100vh - 32px)', // Adjust the height as needed
  border: 'none',
//   transform: `scale(${zoom})`, // Apply the zoom level
  transformOrigin: 'top left', // Set the origin to the top left corner
};

  return (
    <Container sx={{ padding: 2, paddingTop: 10 }}>
      <iframe
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQkcCZUhMKXkxUKzJg1rnP3Jde4XQV6jcV0l24sANeH9ORXJSj6kYrpZ5KBoyYkNA/pubhtml?gid=1048510615&amp;single=true&amp;widget=true&amp;headers=false&zoom=${zoom}"
        title="Embedded Google Sheet"
        style={iframeStyle}
      ></iframe>
    </Container>
  );
};
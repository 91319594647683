import {Api} from '../axios-config'
import {IKeyword, IPointOfInterest, IPointOfInterestModify, IVideo} from "../interfaces";

const poiURLprefix = '/point-of-interest';

const getAllPointsOfInterest = () => Api.get<IPointOfInterest[]>(poiURLprefix +'/all');

const getAllPointsOfInterestName = () => Api.get<IPointOfInterest[]>(poiURLprefix +'/all/name');

const getPointOfInterestById = (id:number) => Api.get<IPointOfInterest>(poiURLprefix,{ params: { id: id } });

const getPointOfInterestByName = (name:string) => Api.get<IPointOfInterest>(poiURLprefix + '/name',{ params: { name: name } });

const createPointOfInterest = (poi: IPointOfInterestModify) => Api.post(poiURLprefix, poi);

const updatePointOfInterest = (poi: IPointOfInterestModify) => Api.put(poiURLprefix, poi);

const deletePointOfInterest = (id: number) => Api.delete(poiURLprefix + `/${id}`,{ params: { id: id } });

const updatePointOfInterestVideos = (poiName: string, videos: IVideo[]) => Api.post(poiURLprefix + `/${poiName}/add-videos`, videos);

const deletePointOfInterestVideo = (poiName: string, videoUrl: string) => Api.delete(poiURLprefix + `/${poiName}/delete-video`,{ params: { videoUrl: videoUrl } });

const getPointsOfInterestByKeyword = (keyword:string) => Api.get<IPointOfInterest[]>(poiURLprefix + '/keyword', { params: { keyword: keyword } });

const getPointsOfInterestKeywords = () => Api.get<string[]>(poiURLprefix + '/keywords');

const getPopularKeywords = () => Api.get<IKeyword[]>(poiURLprefix + '/popular-keywords');

const getUniqueKeywords = () => Api.get<string[]>(poiURLprefix + '/unique-keywords');

const removeKeywordFromPOI = (poiName:string, keyword:string) => Api.delete(poiURLprefix + `/${poiName}/delete-keyword`, { params: { keyword: keyword } });

const removeHyperlinkFromPOI = (poiName:string, field:string, title:string) => Api.delete(poiURLprefix + `/${poiName}/delete-hyperlink`, { params: { field: field, title: title } });


export const PointOfInterestService = {
    getAllPointsOfInterest,
    getAllPointsOfInterestName,
    getPointOfInterestById,
    getPointOfInterestByName,
    getPointsOfInterestByKeyword,
    getPointsOfInterestKeywords,
    getPopularKeywords,
    getUniqueKeywords,
    createPointOfInterest,
    updatePointOfInterest,
    deletePointOfInterest,
    updatePointOfInterestVideos,
    deletePointOfInterestVideo,
    removeKeywordFromPOI,
    removeHyperlinkFromPOI
}
import {IPointOfInterest} from "../../interfaces";
import React, {useEffect} from "react";
import {LatLng, Map} from "leaflet";
import {useMap} from "react-leaflet";
import {linkHorizontal, max, min, select, Selection} from "d3";
import { poiTypeColor } from "./VisualizePOIs";

interface LinkData {
    source:number[],
    target:number[]
}

interface MapProps {
    pois: IPointOfInterest[],
    setPoi?: React.Dispatch<React.SetStateAction<IPointOfInterest | undefined>>
    poisByKeyword?: IPointOfInterest[],
}

export const MapComponent: React.FC<MapProps> = (({pois, setPoi, poisByKeyword}) => {

    const mapRef: Map | null = useMap();
    mapRef.options.worldCopyJump = true;

    const assignTypeColor = (poiType: string) => {
        const foundType = poiTypeColor.find(item => item.type === poiType);
        return foundType ? foundType.color : 'black';
    };
    
    // const assignTypeColor = (poiType: string) => {
    //     switch(poiType) {
    //         case 'Artist/Studio':
    //             return '#9ec2b2';
    //         case 'Manufacturer':
    //             return 'green';
    //         case 'Museum/Collection':
    //             return 'brown';
    //         case 'Supplier':
    //             return 'orange';
    //         case 'Education':
    //             return 'red';
    //         case 'Residency':
    //             return 'yellow';
    //         case 'Gallery/Design Store':
    //             return 'darkblue';
    //         case 'Craft Councils':
    //             return 'gray';
    //         default:
    //             return 'black';
    //     }
    // }

    useEffect(() => {

        if (mapRef && pois?.length !== 0) {

            const projectPoint = ( (x: number, y: number) => {
                return mapRef.latLngToLayerPoint(new LatLng(y, x));
            });

            const latArr = pois.map((p) => {
                return p.lat
            })

            const lngArr = pois.map((p) => {
                return p.lng
            })
            const borderThreshold = 25; //a threshold is added so that the circles or labels (ex: wytham studio) in the borders don't get cut

            const topBnd = max(latArr)! + borderThreshold;
            const bottomBnd = min(latArr)! - borderThreshold;
            const leftBnd = min(lngArr)! - borderThreshold;
            const rightBnd = max(lngArr)! + borderThreshold;

            select("svg").remove(); //to avoid duplicates
            const svg: Selection<SVGSVGElement, unknown, null, undefined> = select(mapRef.getPanes().overlayPane).append("svg");
            //svg.style('position','relative')

            const g = svg.append("g")
                .attr("class", "leaflet-zoom-hide");

            const nodes = g.selectAll(".node")
                .data(pois)
                .enter().append("g");
            
            nodes.append("text")
                .style("text-anchor", "middle")
                .style("fill", "#9ec2b2")
                .style("font-family", "KUniforma")
                .style("font-size", 12)
                .style("font-weight",600);

            const circles = nodes.append("circle")
                .attr("r", "5")
                .attr("fill", (d) => {
                    return assignTypeColor(d.type);
                })
                .style("pointer-events","visible"); //facilitates pointer events

            const reset = () => {
                const bottomLeft = projectPoint(leftBnd!, bottomBnd!);
                const topRight = projectPoint(rightBnd!, topBnd!);

                svg.attr("width", topRight.x - bottomLeft.x)
                    .attr("height", bottomLeft.y - topRight.y)
                    .style("margin-left", bottomLeft.x + "px")
                    .style("margin-top", topRight.y + "px");
                g.attr("transform", "translate(" + -bottomLeft.x + "," + -topRight.y + ")");

                circles.attr("cx", (d) => {
                        return projectPoint(d.lng, d.lat).x;
                    })
                    .attr("cy", (d) => {
                        return projectPoint(d.lng, d.lat).y;
                    })

                let keywordCircles: Selection<SVGCircleElement, IPointOfInterest, SVGGElement, unknown> | null = null;
                const handleMouseover = (node: Selection<SVGGElement, IPointOfInterest, null, unknown>) => {
                    node.select('text')
                        .text(function(d) {return d.name;})
                        .attr("x", function(d) {return projectPoint(d.lng, d.lat).x;})
                        .attr("y", function(d) {return projectPoint(d.lng, d.lat).y - 25;}); //- 25 so the text stays above the circle and not on top

                    const selectedCircle = node.select('circle');

                    if(!keywordCircles) {  //if there is no keyword visualization, opacity changes, otherwise leaves it as it was
                        circles.attr('opacity', '0.1'); //apply less opacity to all the pois
                        selectedCircle
                            .attr('opacity', '1');     //but the one hovered
                    }

                    selectedCircle
                        .transition().duration(500).attr("r", 20); //makes the circle bigger for better visualization

                }

                const handleMouseout = (node: Selection<SVGGElement, IPointOfInterest, null, unknown>) => {
                    node.select('text') //remove text
                        .text(null);

                    if(!keywordCircles) //if there is no keyword visualization, opacity changes, otherwise leaves it as it was
                        circles.attr('opacity', '1');

                    node.select('circle').transition().duration(500).attr("r", 5); //circle size comes back to normal
                }

                nodes.nodes().forEach((c) => {
                    const selected: Selection<SVGGElement, IPointOfInterest, null, unknown> = select(c);
                    const circle = selected.select('circle');
                    circle.on("mouseover", () => handleMouseover(selected))

                    circle.on("mouseout", () => handleMouseout(selected))

                    circle.on("click", (e) => {
                        e.stopPropagation(); // to prevent error -> cannot read properties of null reading ('_leaflet_disable_click')
                        if (setPoi)
                            setPoi(selected.data()[0]);
                    });
                });

                if (poisByKeyword) {
                    g.selectAll('path').remove(); //clean possible lines

                    const poisByKeywordNames: string[] = poisByKeyword.map((p) => {
                        return p.name;
                    })
                    keywordCircles = circles.filter((d) => {
                        return poisByKeywordNames.includes(d.name)
                    });

                    circles.attr('opacity', '0.1'); //apply less opacity to all the pois
                    keywordCircles.attr('opacity', '1'); //but the selected ones

                    if (poisByKeyword.length > 1) { //connections only make sense if there is at least one, that means more than one point

                        for (let i = 0; i < keywordCircles.nodes().length; i++) {
                            const c1 = keywordCircles.nodes()[i];
                            for (let j = 1; j < keywordCircles.nodes().length; j++) {
                                const c2 = keywordCircles.nodes()[j];
                                /*const line1: Selection<SVGLineElement, unknown, null, undefined> = g.append("line") normal line
                                    .attr("x1", c1.cx.baseVal.value)
                                    .attr("y1", c1.cy.baseVal.value)
                                    .attr("x2", c2.cx.baseVal.value)
                                    .attr("y2", c2.cy.baseVal.value)
                                    .style("stroke-width", 2)
                                    .style("stroke", "teal")*/
                                /* const lineFunc = line<{x:number, y:number}>() curved line but only works with more than 2 points
                                     .x(function(d) { return d.x })
                                     .y(function(d) { return d.y })
                                     .curve(curveBumpY())
                                   g.append("path").attr("d", lineFunc(data))
                                                   .attr('stroke', 'black')
                                                   .attr('fill', 'none');*/

                                const linkData: LinkData[] = [{
                                    source: [c1.cx.baseVal.value, c1.cy.baseVal.value],
                                    target: [c2.cx.baseVal.value, c2.cy.baseVal.value]
                                }];

                                const link = linkHorizontal<any, LinkData, [number, number]>();

                                g.append("path")
                                    .data(linkData)
                                    .attr("d", link)
                                    .attr('stroke', 'teal')
                                    .attr('fill', 'none') //important because otherwise in between the links there is color
                            }
                        }
                    }
                }
            }
            reset(); //when pois are loaded and the map is set, call the method to initialize svg
            mapRef.on('moveend', reset);
        }

    }, [mapRef, pois, poisByKeyword, setPoi]);

    return null;
});
import {Api} from '../axios-config'
import {IKeyword, IPointOfInterest} from "../interfaces";

const keywordURLprefix = '/keyword';

const getAllKeywords = () => Api.get<IKeyword[]>(keywordURLprefix +'/all');

const getPopularKeywords = () => Api.get<IKeyword[]>(keywordURLprefix + '/popular-keywords');

const getUniqueKeywords = () => Api.get<IKeyword[]>(keywordURLprefix + '/unique-keywords'); //could be just with names to make the call less heavy

const getPoisByKeyword = (keyword:string) => Api.get<IPointOfInterest[]>(keywordURLprefix + '/' + keyword + '/pois');

export const KeywordService = {
    getAllKeywords,
    getPopularKeywords,
    getUniqueKeywords,
    getPoisByKeyword,
}
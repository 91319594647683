import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, Box, IconButton, CardMedia, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import penIcon from '../../assets/pen-icon-01.png'; 
import preview from "../../assets/default_poi_preview.jpeg";
import { CardType } from './Card';
import { cardTypeColor } from './Cards';
import { ICard } from '../../interfaces';
import './CardPreview.css'

const backend_url = process.env.REACT_APP_API_URL;

interface CardPreviewProps {
    card: ICard;
    auth: boolean;
    navigate: (path: string) => void;
  }

  
const CardPreview: React.FC<CardPreviewProps> = ({ card, auth, navigate }) => {
    
const handleTypeColor = (type: string) => {
    if (type === CardType.ACTIVITY)
        return cardTypeColor.activity
    else if (type === CardType.LECTURE)
        return cardTypeColor.lecture
    else if (type === CardType.PROJECT)
        return cardTypeColor.project
    else if (type === CardType.TUTORIAL)
        return cardTypeColor.tutorial
    else
        return 'transparent'
    }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={card.id} className="gridItem">
      <Link to={`/card/${card.id}`}>
        <Card className="cardMuiCard">
          <Box position="relative">
            <CircleIcon sx={{ color: handleTypeColor(card.type), zIndex: '1000', position: 'absolute', width: '30px', height: '30px' }} />
            {auth && (
              <IconButton
                className="penIcon"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/card/update/${card.id}`);
                }}
              >
                <img src={penIcon} alt="Update Card" />
              </IconButton>
            )}
            <CardMedia
              className="imageCardMedia"
              component="img"
              height="400"
              image={card.images.length === 0 ? preview : backend_url + `/card/image/${card.images[0].uuid}/preview`}
              alt="Card Image"
            />
          </Box>
          <Box className="cardTitleBox">
            <Typography className="cardTitleTypo" variant="h5">{card.title}</Typography>
          </Box>
        </Card>
      </Link>
    </Grid>
  );
};

export default CardPreview;

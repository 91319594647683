import {Api} from '../axios-config'
import {IVideo, ICard} from '../interfaces';
import { IAuthor } from '../interfaces/IAuthor';

const cardURLprefix = '/card';

const getAllCards = () => Api.get<ICard[]>(cardURLprefix +'/all');

const getAllCardsTitle = () => Api.get<ICard[]>(cardURLprefix +'/all/title');

const getCardById = (id:number) => Api.get<ICard>(cardURLprefix,{ params: { id: id } });

const getCardByTitle = (title:string) => Api.get<ICard>(cardURLprefix + '/title',{ params: { title: title } });

const createCard = (formData: FormData) => Api.post(cardURLprefix, formData, {headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'multipart/form-data'}});

const updateCard = (card: ICard) => Api.put(cardURLprefix, card);

const deleteCard = (id: number) => Api.delete(cardURLprefix + `/${id}`);

const updateCardVideos = (id: number, videos: IVideo[]) => Api.post(cardURLprefix + `/${id}/add-videos`, videos);

const deleteCardVideo = (id: number, videoUrl: string) => Api.delete(cardURLprefix + `/${id}/delete-video`,{ params: { videoUrl: videoUrl } });

const removeHyperlinkFromCard = (id: number, field:string, title:string) => Api.delete(cardURLprefix + `/${id}/delete-hyperlink`, { params: { field: field, title: title } });

const removeKeywordFromCard = (id:number, keyword:string) => Api.delete(cardURLprefix + `/${id}/delete-keyword`, { params: { keyword: keyword } });

const getAllAuthors = () => Api.get<IAuthor[]>(cardURLprefix +'/authors/all');

export const CardService = {
    getAllCards,
    getAllCardsTitle,
    getCardById,
    getCardByTitle,
    createCard,
    updateCard,
    deleteCard,
    updateCardVideos,
    deleteCardVideo,
    removeHyperlinkFromCard,
    removeKeywordFromCard,
    getAllAuthors
}
import jwtDecode from "jwt-decode";
import {Login} from "../interfaces";
import {AuthenticationService} from "../services";
import {useCallback} from "react";


export const useAuthentication = () => {
    const signIn = useCallback((loginData: Login) => {
      return AuthenticationService.signIn(loginData);
    }, []);
  
    const checkTokenValidity = useCallback(async (token: string) => {
      try {
        const response = await AuthenticationService.validateToken(token);
        return response.data.valid;
      } catch (error) {
        console.error('Token validation failed:', error);
        return false;
      }
    }, []);

    const refreshAccessToken = useCallback(async (token: string) => {
      try {
        const response = await AuthenticationService.refreshToken(token);
    
        if (response.status === 200) {
          const { token } = response.data;
    
          // Store the new token
          localStorage.setItem('token', token);
    
          // Decode the token to extract expiration timestamp
          const decodedToken: { exp?: number } = jwtDecode(token);
          const expirationTimestamp = decodedToken.exp;
    
          // Store the expiration timestamp
          if (expirationTimestamp) {
            localStorage.setItem('tokenExpiration', expirationTimestamp.toString());
          }
    
          // Return the refreshed token and expiration time
          return { token, expirationTimestamp };
        } else if (response.status === 401) {
          // Token refresh failed or expired token was invalid
          return null;
        } else {
          // Handle other responses
          console.error('Unexpected response:', response);
          return null;
        }
      } catch (error) {
        // Handle network or other error
        console.error('Error:', error);
        return null;
      }
    }, []);
      
    return {
      signIn,
      checkTokenValidity,
      refreshAccessToken,
    };
  };
  
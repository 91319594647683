import { Box, Container, Typography} from "@mui/material";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import background from "../assets/partners.png";
import './Help.css'

export const Help: React.FC = () => {
    return (
        <Container className='mainHelpContainer'>
            <Box className='gridHelpPageWrap'>
                <Box className='helpTitleBox'>
                    <Typography variant="h2" className='helpTitle'>How to use</Typography>
                    <Box className='helpBriefBox'>
                        <Typography variant="h6" className='helpBrief'>
                            <br/>
                            There are two ways to navigate this platform.
                            <br/>
                            <br/>
                            If you're curious to learn but not sure what you want to do, we suggest starting with the Map. 
                        </Typography>
                    </Box>
                </Box>
                <Box className='helpImageBox' style={{ backgroundImage: `url(${background})` }}>
                    {/* Image will be placed here as a background */}
                </Box>
            </Box>
            <Box className='helpBodyBox'>
                <Typography variant="body1" className='helpBody1'>
                    Click on a point of interest in the map or 
                    <br/>
                    search via inputting a name or keyword or type.
                </Typography>
                <br/>
                <ExpandCircleDownIcon fontSize='large'/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    You will be directed to a page that:
                </Typography>
                <ul className='helpList'>
                    <li>describes the Point of Interest (POI)</li>
                    <br/>
                    <li>any specialist process or techniques</li>
                    <br/>
                    <li>directs you to any existing teaching tools</li>
                    <br/>
                    <li>directs you to where you can learn more about this POI or the processes</li>
                    <br/>
                    <li>Links you to a relevant decoding card so you learn yourself or teach others.</li>
                </ul>
                <br/>
                <ExpandCircleDownIcon fontSize='large'/>
                <br/>
                <br/>
                If you have an idea of a process to use or an outcome you want to achieve, then <b>start here</b>. 
                <br/>
                <br/>
                <ExpandCircleDownIcon fontSize='large'/>
                <br/>
                <br/>
                Scroll down below the map:
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    Type in the filter bars:
                </Typography>
                <Typography variant='body1' className="helpFakeButtons">
                    What you want to learn
                </Typography>
                <br/>
                <Typography variant='body1' className="helpFakeButtons">
                    Level of experience from beginner to expert
                </Typography>
                <br/>
                <Typography variant='body1' className="helpFakeButtons">
                    How much time you have
                </Typography>
                <br/>
                <br/>
                <ExpandCircleDownIcon fontSize='large'/>
                <br/>
                <Typography variant="body1" className='helpBody1'>
                    You will be directed to a page that:
                </Typography>
                <ul className='helpList'>
                    <li>Gives you an overview of the technique/process</li>
                    <br/>
                    <li>Provides an instructible video or slide show</li>
                    <br/>
                    <li>Tells you what tools and materials you will need</li>
                    <br/>
                    <li>How much time</li>
                    <br/>
                    <li>Gives you step by step instruction</li>
                    <br/>
                    <li>Links to supporting videos that illuminate the potential of the process.</li>
                    <br/>
                    <li>Link to relevant POI on the map to exemplify the technique</li>
                </ul>
            </Box>
        </Container>
    );
}
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCard } from '../../hooks/useCard';
import { ICard, IKeyword } from '../../interfaces';
import { Autocomplete, Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, Slider, TextField, Typography } from '@mui/material';
import { AuthContext, AuthType } from "../contexts/AuthContext";
import './Cards.css'
import Footer from '../Footer';
import { CardLevel, TimeUnits, CardType } from './Card';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import CardPreview from './CardPreview';

interface Props {
  unFilteredKeywords: IKeyword[];
  isPreview: boolean;
}

export const cardTypeColor = {
  activity:'red',
  lecture:'#0000b2',
  project:'#9ec2b2',
  tutorial:'black'
}

export const CardListPage: React.FC<Props> = ({ unFilteredKeywords, isPreview }) => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [keywords, setKeywords] = useState<IKeyword[]>([]);
  const [maxNumVisible, setMaxNumVisible] = useState<number>(8)
  const [maxNumPreviewVisible, setMaxNumPreviewVisible] = useState<number>(3)
  const [visibleCards, setVisibleCards] = useState(cards.slice(0, maxNumVisible)); 
  const [end, setEnd] = useState(maxNumVisible);
  const { getAllCards } = useCard();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext) as AuthType;
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [maxTime, setMaxTime] = useState(31);
  const [timeUnitFilter, setTimeUnitFilter] = useState('')
  const [time, setTime] = React.useState<number[]>([0, maxTime]);
  const [loading, setLoading] = useState(true);
  const [filterSize, setFilterSize] = useState<number>(0);
  const { keyword } = useParams<{ keyword: string }>();
  const [isLoadingMore, setIsLoadingMore] = useState(false); 

  const cardTypes = [
    { type: CardType.ACTIVITY, color: cardTypeColor.activity },
    { type: CardType.LECTURE, color: cardTypeColor.lecture },
    { type: CardType.PROJECT, color: cardTypeColor.project },
    { type: CardType.TUTORIAL, color: cardTypeColor.tutorial },
  ];
  

  useEffect(() => {
    // Function to handle screen size change
    const handleResize = () => {
      const { innerWidth } = window; // Get the inner width of the window
      let newEnd = end;
      // Determine the screen size based on the inner width
      if (innerWidth < 576) {
        if(isPreview)
          setMaxNumPreviewVisible(3);
        else {
          setMaxNumVisible(8);
          while(newEnd % 4 !== 0) 
            newEnd+=1;
        }
      } else if (innerWidth < 768) {
        if(isPreview)
          setMaxNumPreviewVisible(3);
        else {
          setMaxNumVisible(8);
          while(newEnd % 4 !== 0) 
            newEnd+=1;
        }
      } else if (innerWidth < 992) {
        if(isPreview)
          setMaxNumPreviewVisible(3);
        else {
          setMaxNumVisible(8);
          while(newEnd % 4 !== 0) 
            newEnd+=1;
        }
      } else if (innerWidth < 1200) {
        if(isPreview)
          setMaxNumPreviewVisible(3);
        else {
          setMaxNumVisible(9);
          while(newEnd % 3 !== 0) 
            newEnd+=1;
        }
      } else {
        if(isPreview)
          setMaxNumPreviewVisible(4);
        else {
          while(newEnd % 4 !== 0) 
            newEnd+=1;
          setMaxNumVisible(8);
        }
      }
      setEnd(newEnd);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Initial call to set the screen size on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [end, isPreview]);

  useEffect(() => {
    // Initially set the filter based on the keyword query parameter.
    if (keyword) {
      setSelectedKeywords([keyword]);
    }
  }, [keyword]);

  useEffect(() => {
    getAllCards().then((res: ICard[]) => {
      const shuffledCards = res.sort(() => Math.random() - 0.5);
      setCards(shuffledCards);
      setLoading(false)
    });
  }, [getAllCards]);

  useEffect(()=> {
    const filteredKeywords = unFilteredKeywords.filter((keyword) => 
      cards.some((card) => card.keywords.includes(keyword.name))
    );
    setKeywords(filteredKeywords);
  }, [unFilteredKeywords, cards])

  const showMoreCards = () => {
    setIsLoadingMore(true);
    setTimeout(() => {
      setEnd(prevEnd => prevEnd + maxNumVisible);
      setIsLoadingMore(false);
    }, 100); // delay to simulate network request
  }

  useEffect(()=> {
    let tmpMaxTime: number;
    if (timeUnitFilter === TimeUnits.DAYS)
      tmpMaxTime = 31;
    else if (timeUnitFilter === TimeUnits.WEEKS)
      tmpMaxTime = 4;
    else if (timeUnitFilter === TimeUnits.MONTHS)
      tmpMaxTime = 6;
    else if (timeUnitFilter === TimeUnits.SEMESTERS)
      tmpMaxTime = 12;
    else
      tmpMaxTime = 0;

    setMaxTime(tmpMaxTime);
    setTime([0,tmpMaxTime]);
    
  }, [timeUnitFilter]);

  const filterByType = useCallback((card: ICard) => {
    if (card.type === typeFilter)
      return true
    else
      return false
  }, [typeFilter]);

  const filterByTime = useCallback((card: ICard) => {
    let timeInDays = 0;
    let timeFilterInDays = [0,0];
  
    if (card.timeUnit === TimeUnits.DAYS) {
      timeInDays = card.timeToComplete;
    }
    else if (card.timeUnit === TimeUnits.WEEKS) {
      timeInDays = card.timeToComplete * 7;
    }
    else if (card.timeUnit === TimeUnits.MONTHS) {
      timeInDays = card.timeToComplete * 31;
    }
    else if (card.timeUnit === TimeUnits.SEMESTERS) {
      timeInDays = card.timeToComplete * 186;
    }
  
    if (timeUnitFilter === TimeUnits.DAYS)
      timeFilterInDays = time
    else if (timeUnitFilter === TimeUnits.WEEKS) {
      timeFilterInDays[0] = time[0] * 7
      timeFilterInDays[1] = time[1] * 7
    }
    else if (timeUnitFilter === TimeUnits.MONTHS){
      timeFilterInDays[0] = time[0] * 31
      timeFilterInDays[1] = time[1] * 31
    }
    else if (timeUnitFilter === TimeUnits.SEMESTERS){
      timeFilterInDays[0] = time[0] * 186
      timeFilterInDays[1] = time[1] * 186
    }
  
    if(Math.max(...timeFilterInDays) >= timeInDays && Math.min(...timeFilterInDays) <= timeInDays )
      return true
    else
      return false
  
  }, [timeUnitFilter, time]);

  useEffect(() => {
    const filteredCards = cards.filter((card) => 
      (selectedKeywords.length ? selectedKeywords.every((selectedKeyword) => card.keywords.includes(selectedKeyword)) : true) && 
      (levelFilter ? card.level === levelFilter : true ) &&
      (titleFilter ? card.title.toLowerCase().includes(titleFilter.toLowerCase()) : true) &&
      (timeUnitFilter ? filterByTime(card) : true) &&
      (typeFilter ? filterByType(card) : true)
    );
    if(filteredCards.length < cards.length) {
      setFilterSize(cards.length - filteredCards.length);
    }
    else {
      setFilterSize(0);
    }
    setVisibleCards(isPreview? filteredCards.slice(0, maxNumPreviewVisible):filteredCards.slice(0, end));
  }, [isPreview,maxNumPreviewVisible, end, cards, selectedKeywords, titleFilter, levelFilter, timeUnitFilter, time, filterByTime, typeFilter, filterByType]);

  const handleChange = (event: any, value: string[]) => {
    setSelectedKeywords(value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleFilter(event.target.value);
  };

  const handleTimeChange = (event: Event, newValue: number | number[]) => {
    setTime(newValue as number[]);
  };

  const handleTypeFilter = (type: string) => {
    setTypeFilter(typeFilter === type ? '' : type);
  };

  if (loading && cards.length === 0) {
    return (
      <Container className='loadingContainerCard'>
        <div className='loadingDiv'>
          <CircularProgress color="inherit" />
          <div>Loading...</div>
        </div>
      </Container>
    );
  }

  if(!loading && cards.length === 0) {
    return (
      <Container className='loadingContainerCard'>
        <div className='loadingDiv'>
          <div>No cards available!</div>
        </div>
      </Container>
    );
  }

  return (
    <Container className='mainContainer' sx={{ paddingTop:isPreview?'0px':'67px', minHeight: isPreview? '100%' : '100vh' }}>
      <Box className="titleBox">
        <Typography className="titleTypo" variant="h3">Decoding Cards</Typography>
        <Typography className="titleSub" variant="subtitle1">teaching methods & pedagogical approaches</Typography>
        {/* Filter */}
        <Grid container spacing={8} alignItems="center">
          <Grid item>
            <TextField
              label="Search by title"
              variant="outlined"
              className='filterTitleField'
              value={titleFilter}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              className='filterBox'
              options={keywords.map((keyword) => keyword.name).sort()}
              value={selectedKeywords}
              renderInput={(params: any) => <TextField {...params} label="What do you want to learn?" variant="outlined" className='filterTextField'  />}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth className='filterForm'>
                <InputLabel sx={{fontFamily:'KUniforma'}}>Who's this for</InputLabel>
                <Select
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                className='selectFilterForm'
                >
                <MenuItem value={''}>All</MenuItem>
                <MenuItem value={CardLevel.BEGINNER}>{CardLevel.BEGINNER}</MenuItem>
                <MenuItem value={CardLevel.INTERMEDIATE}>{CardLevel.INTERMEDIATE}</MenuItem>
                <MenuItem value={CardLevel.ADVANCED}>{CardLevel.ADVANCED}</MenuItem>
                <MenuItem value={CardLevel.EXPERT}>{CardLevel.EXPERT}</MenuItem>
                </Select>
            </FormControl>
          </Grid>

          {/* <Grid item sx={{width:'500px', margin:'16px', marginTop:'32px'}}> */}
          <Grid item>
            <Typography id="input-slider" gutterBottom>
              How long do you have?
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {timeUnitFilter &&
              <Grid item>
                <Slider
                className='filterTimeSlider'
                getAriaLabel={() => 'Time range'}
                value={time}
                onChange={handleTimeChange}
                valueLabelDisplay="auto"
                disableSwap
                min={0}
                max={maxTime}
                />
              </Grid>
              }
              <Grid item>
                <FormControl className='filterTimeUnit'>
                  <InputLabel>Duration</InputLabel>
                  <Select
                  value={timeUnitFilter}
                  onChange={(e) => setTimeUnitFilter(e.target.value)}
                  className='selectFilterForm'
                  >
                  <MenuItem value={''}>All the time in the world</MenuItem>
                  <MenuItem value={TimeUnits.DAYS}>{TimeUnits.DAYS}</MenuItem>
                  <MenuItem value={TimeUnits.WEEKS}>{TimeUnits.WEEKS}</MenuItem>
                  <MenuItem value={TimeUnits.MONTHS}>{TimeUnits.MONTHS}</MenuItem>
                  <MenuItem value={TimeUnits.SEMESTERS}>{TimeUnits.SEMESTERS}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center" className="gridType">
          {cardTypes.map((cardType) => (
            <Grid item key={cardType.type}>
              <IconButton
                className="iconCardType"
                sx={{
                  fontWeight: typeFilter === cardType.type ? "bold" : '',
                  '&:hover': {
                    fontWeight: 'bold',
                    backgroundColor: 'inherit !important', 
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleTypeFilter(cardType.type);
                }}
              >
                <CircleIcon sx={{ color: cardType.color }} />
                {' '+cardType.type}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box id="cards-container">
        <Grid container spacing={2}>
        {visibleCards.map((card: ICard) => {
          return (
            <CardPreview key={card.id} card={card} auth={auth} navigate={navigate} />
            );
        })}
        </Grid>
        {(visibleCards.length < (cards.length-filterSize)) && !isPreview && (
          <Box className='buttonBox'>
            <Button className='loadMoreButton' onClick={showMoreCards} disabled={isLoadingMore}>
              {isLoadingMore ? "Loading..." : "Load More"}
            </Button>
          </Box>
        )}
      </Box>
      {!isPreview && <Footer />}
    </Container>
  );
};
import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Container,
    Typography,
    Grid, Button,
    Dialog,
    Paper, useTheme,
    MobileStepper, DialogActions, Tooltip, Link, Card, CardActionArea, CardMedia, CardContent, CardActions, CircularProgress
} from "@mui/material";
import { ArrowBack, Info, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useParams, useNavigate, Link as RouterLink} from "react-router-dom";
import {usePointOfInterest} from "../../hooks";
import {ICard, IFile, IPointOfInterest, IVideo} from "../../interfaces";
import "./POIOverview.css";
import { hyperlinkTypes } from '../Map/CreatePOI';
import Footer from '../Footer';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { customIcon } from '../Map/MarkerComponent'
import CardPreview from '../Card/CardPreview';

const type = "point-of-interest";

const backend_url = process.env.REACT_APP_API_URL;


export const POIOverview: React.FC = () => {
  
  let {id} = useParams<string>();
  const [poi,setPoi] = useState<IPointOfInterest | null>(null);
    
  const {getPointOfInterestById} = usePointOfInterest();

  interface VideoGridProps {
    videos: IVideo[];
    onVideoClick: (index: number) => void;
  }  

  interface ImageGridProps {
    images: IFile[];
    onImageClick: (index: number) => void;
  }

  interface SimpleDialogProps {
    open: boolean;
    closeDialog: () => void;
    videos: IVideo[];
    index: number;
  }

  interface ImageDialogProps {
    open: boolean;
    closeDialog: () => void;
    images: IFile[];
    index: number;
  }
  const navigate = useNavigate();
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setSelectedImage(index);
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleVideoClick = (index: number) => {
    setSelectedVideo(index);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const ImageGrid = ({ images, onImageClick }: ImageGridProps) => {  
    return (
      <Grid className='imageVideoGrid' container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={'image_grid_'+index} xs={12} sm={6} md={4}>
            <Card className='imageCard'>
              <CardActionArea onClick={() => onImageClick(index)}>
                <CardMedia
                  className='imageCardMedia'
                  component="img"
                  height="315"
                  src={backend_url + '/'+type+`/image/${image.uuid}/preview`}
                  title={image.caption}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {image.caption}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  function ImageDialog(props: ImageDialogProps) {
    const { open, closeDialog, images, index } = props;
  
    const close = () => {
        closeDialog()
    }
  
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(index);
    const maxSteps = images.length;
    
    const handleImageNext = useCallback(() => {
      if(activeStep < maxSteps - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }, [activeStep, maxSteps]);
    
    const handleImageBack = useCallback(() => {
      if(activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }, [activeStep]);
    
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'ArrowRight') {
            handleImageNext();
          } else if (event.key === 'ArrowLeft') {
            handleImageBack();
          }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
  }, [handleImageNext, handleImageBack]);
  
    return (
      <Dialog className='poiDialogImgCont' open={open} maxWidth="lg" fullWidth={true} onClose={close}>
        <Box className='poiDialogImgBox'>
          {images.length !== 0 && <Paper
              square
              elevation={0}
          >
            <img
              className='poiImg'
              // src={backend_url + `/card/image/${images[activeStep].uuid}`}
              src={backend_url + '/'+type+`/image/${images[activeStep].uuid}`}
              alt={images[activeStep].caption}
            />
            <Typography variant='h5' className={"poiSubtitleImgTitle"}>{images[activeStep]?.caption}</Typography>
            <Typography variant='caption' className={"poiSubtitleImgSource"}>{images[activeStep]?.source}</Typography>
          </Paper>}
  
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleImageNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleImageBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
        <DialogActions>
          <Button autoFocus onClick={close}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  

  const VideoGrid = ({ videos, onVideoClick }: VideoGridProps) => {
    return (
      <Grid className='imageVideoGrid' container spacing={2}>
        {videos.map((video, index) => (
          <Grid item key={"video_grid_"+index} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea onClick={() => onVideoClick(index)}>
                <CardMedia
                  component="iframe"
                  height="315"
                  src={video.url}
                  title={video.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {video.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {video.credits}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link href={video.url} target="_blank" rel="noopener">
                    Source
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

    
  function SimpleDialog(props: SimpleDialogProps) {
    const { open, closeDialog, videos, index } = props;

    const close = () => {
        closeDialog()
    }

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(index);
    const maxSteps = videos.length;

    const handleNext = useCallback(() => {
      if(activeStep < maxSteps - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }, [activeStep, maxSteps]);
    
    const handleBack = useCallback(() => {
      if(activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }, [activeStep]);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'ArrowRight') {
              handleNext();
          } else if (event.key === 'ArrowLeft') {
              handleBack();
          }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleNext, handleBack]); 

    return (
        <Dialog className='poiDialogCont' open={open} maxWidth="lg" fullWidth={true} onClose={close}>
            <Box className='poiDialogIframeBox'>
                {videos.length !== 0 && <Paper
                    square
                    elevation={0}
                >
                    <Typography className={"poiSubtitleYttitle"}>{videos[activeStep]?.title}</Typography>
                    <iframe
                        className='poiIframeVideo'
                        title="youtube video"
                        src={videos[activeStep].url}
                        allow="autoplay; encrypted-media"
                        allowFullScreen>

                        </iframe>
                    <Typography className={"poiSubtitleYttitle"}>Credits: {videos[activeStep]?.credits}</Typography>
                </Paper>}

                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </Box>
            <DialogActions>
                <Button autoFocus onClick={close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
  }  
  useEffect(() => {
    if (id) {
      getPointOfInterestById(Number(id))
        .then((res: IPointOfInterest) => {
          setPoi(res);
        })
        .catch((err) => {
          console.error(err);
          setPoi(null);
        });
    }
  }, [id, getPointOfInterestById]);

  if (!id) {
    return (
      <Container className='poiLoadingContainer'>
        <div className='poiLoadingDiv'>
          <div>No Point of Interest Specified!</div>
        </div>
      </Container>
    );
  }


  if (!poi) {
    return (
      <Container className='poiLoadingContainer'>
        <div className='poiLoadingDiv'>
          <CircularProgress color="inherit" />
          <div>Loading...</div>
        </div>
      </Container>
    );
  }

  return (
    <Container
      className='main-container'
    >
      <Box className='poiPageWrap'>
        <Box className='poiGridWrapBox'>
          <Grid container className='poiGridContainer' spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={7}>
              <Box className='poiTitleBriefBox' sx={{paddingTop:'80px'}}>
                <RouterLink className='poiGoBackLink' to="/map">
                  <ArrowBack className='poiArrowBack' />
                  <Typography variant="body1">Back to map</Typography>
                </RouterLink>
              {/* <button className="poiGoBackLink" onClick={window.close}>
                <CloseIcon className="poiCloseIcon" />
                <Typography variant="body1">Close</Typography>
              </button> */}
                <Typography variant="h3" className='poiTitle'>{poi.name}</Typography>
                <Typography variant="caption" className='poiTtype'>{poi.type}</Typography>
                <Box className='poiBoxWithKeywords'>
                  {poi.keywords.map((keyword, index) => (
                    <RouterLink key={index} className='keywordsLink' to={`/map/${keyword.name}`}>
                      <Box key={'keyword_'+index} className='poiKeywordsBox'>{keyword.name}</Box>
                    </RouterLink>
                  ))}
                </Box>
                {poi.profile &&
                <Box className='poiContainerBoxSection'>
                  <Box display={"flex"}>
                    <Typography height={'10%'} variant = "h4" className={"poiSubtitle"}>Profile</Typography>
                    <Tooltip title={"Introduction of the artist or institution"} placement="right-start">
                        <Info fontSize={"small"} sx={{color:'black', marginLeft: '1%',alignSelf: "center"}}></Info>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="body1" className='poiContent'>{poi.profile}</Typography>
                  </Box>
                  {poi.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.profile).map((link, index) => (
                    <Box key={"profile_link"+index}>
                        <Link
                          className='poiLink'
                          component="button"
                          variant="body2"
                          onClick={() => {
                              window.open(link.url, '_blank');
                          }}
                          >
                          {link.title}
                      </Link>
                    </Box>
                  ))}
                </Box>
                }
                {poi.processTechniques &&
                <Box className='poiContainerBoxSection'>
                  <Box display={"flex"}>
                    <Typography height={'10%'} variant = "h4" className={"poiSubtitle"}>Process and Techniques</Typography>
                    <Tooltip title={"Description of the specialist process(es)"} placement="right-start">
                        <Info fontSize={"small"} sx={{color:'black', marginLeft: '1%',alignSelf: "center"}}></Info>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="body1" className='poiContent'>{poi.processTechniques}</Typography>
                  </Box>
                  {poi.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.processTechniques).map((link, index) => (
                    <Box key={"pt_link"+index}>
                        <Link
                          className='poiLink'
                          component="button"
                          variant="body2"
                          onClick={() => {
                              window.open(link.url, '_blank');
                          }}
                          >
                          {link.title}
                      </Link>
                    </Box>
                  ))}
                </Box>
                }
                {poi.teachingTools &&
                <Box className='poiContainerBoxSection'>
                  <Box display={"flex"}>
                    <Typography height={'10%'} variant = "h4" className={"poiSubtitle"}>Teaching Tools</Typography>
                    <Tooltip title={"List of teaching resources"} placement="right-start">
                        <Info fontSize={"small"} sx={{color:'black', marginLeft: '1%',alignSelf: "center"}}></Info>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="body1" className='poiContent'>{poi.teachingTools}</Typography>
                  </Box>
                  {poi.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.teachingTools).map((link, index) => (
                    <Box key={"tt_link"+index}>
                        <Link
                          className='poiLink'
                          component="button"
                          variant="body2"
                          onClick={() => {
                              window.open(link.url, '_blank');
                          }}
                          >
                          {link.title}
                      </Link>
                    </Box>
                  ))}
                </Box>
                }
                {poi.learnMore &&
                <Box className='poiContainerBoxSection'>
                  <Box display={"flex"}>
                    <Typography height={'10%'} variant = "h4" className={"poiSubtitle"}>Learn More</Typography>
                    <Tooltip title={"Further description of the field of work and /or further study materials in the links section"} placement="right-start">
                        <Info fontSize={"small"} sx={{color:'black', marginLeft: '1%',alignSelf: "center"}}></Info>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="body1" className='poiContent'>{poi.learnMore}</Typography>
                  </Box>
                  {poi.hyperlinks.filter((hl) => hl.field === hyperlinkTypes.learnMore).map((link, index) => (
                    <Box key={"lm_link"+index}>
                        <Link
                          className='poiLink'
                          component="button"
                          variant="body2"
                          onClick={() => {
                              window.open(link.url, '_blank');
                          }}
                          >
                          {link.title}
                      </Link>
                    </Box>
                  ))}
                </Box>
                }
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={5} className='poiMapGrid'>
              <Box>
                  <MapContainer center={[poi.lat, poi.lng]} zoom={4} scrollWheelZoom={false}>
                      {/* <TileLayer
                          attribution='&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                      /> */}
                      <TileLayer
                            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
                            noWrap={true}
                        />
                      <Marker icon={customIcon} position={[poi.lat, poi.lng]} />
                  </MapContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {poi.images.length > 0 && 
          <Box className='poiImagesBoxContainer'> 
            <Typography variant="h4" className='poiSectionTitle'>Images</Typography>
            <ImageGrid images={poi.images} onImageClick={handleImageClick} />
              <ImageDialog
                open={imageOpen}
                closeDialog={handleImageClose}
                images={poi.images}
                index={selectedImage}
                />
          </Box>}

        {poi.videos.length > 0 && 
        <Box className='poiVideosBoxContainer'> 
          <Typography variant="h4" className='poiSectionTitle'>Videos</Typography>
          <VideoGrid videos={poi.videos} onVideoClick={handleVideoClick} />
          <SimpleDialog
            open={dialogOpen}
            closeDialog={handleDialogClose}
            videos={poi.videos}
            index={selectedVideo}
            />
        </Box>
        }

        {poi.cards.length > 0 &&
          <Box id="poi-cards-container">
            <Typography variant="h4" className='poiSectionTitle'>Decoding Cards</Typography>
            <Grid container spacing={10}>
              {poi.cards.map((card: ICard) => {
                return (
                  <CardPreview key={card.id} card={card} auth={false} navigate={navigate} />
                  );
                })}
            </Grid>
          </Box>
        }

      </Box>
      <Footer></Footer>
    </Container>
  );
};


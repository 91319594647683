import axios from 'axios';
import {errorInterceptor, responseInterceptor} from "../interceptors";

const backend_url = process.env.REACT_APP_API_URL || 'https://craft.di.fct.unl.pt/api'; // provide a fallback value

export const Api = axios.create({
    baseURL: backend_url,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
});

Api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            // Ensure headers exist
            config.headers = config.headers || {};
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const AuthApi = axios.create({
    baseURL: backend_url,
    // headers: {
    //     'Content-Type': 'application/json',
    //     // 'Access-Control-Allow-Origin': 'https://craft.di.fct.unl.pt', // Update with your frontend URL
    //     'Access-Control-Allow-Origin': 'http://localhost:3000', // Update with your frontend URL
    // },
    // withCredentials: true, // Include this option to send cookies with the requests
    validateStatus: function (status) {
        // Consider 403 and 404 as valid status codes, along with 2xx
        return (status >= 200 && status < 300) || status === 403 || status === 404;
    },
});


// export const AuthApi = axios.create({
//     baseURL: backend_url,
//     headers: {
//         'Content-Type': 'application/json'
//     },
//     validateStatus: function (status) {
//     // Consider 403 and 404 as valid status codes, along with 2xx
//     return (status >= 200 && status < 300) || status === 403 || status === 404;
//     },
// });


export const FileApi = axios.create({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/octet-stream'
    }
});

export const WordApi = axios.create({
    baseURL: 'api.datamuse.com/words',
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
});

// Apply interceptors to all instances
[Api, FileApi, WordApi].forEach(instance => {
    instance.interceptors.response.use(
        response => responseInterceptor(response),
        error => errorInterceptor(error),
    );
});

AuthApi.interceptors.response.use(undefined, errorInterceptor);
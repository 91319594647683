import {Navigate, Outlet} from "react-router-dom";
import {useContext} from "react";
import {AuthContext, AuthType} from "../contexts/AuthContext";

export const PrivateRoutes = () => {
    const {auth} = useContext(AuthContext) as AuthType;

    return (
        auth ? <Outlet/> : <Navigate to="/login"/>
    )
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IHyperlink} from "../interfaces/IHyperlink";

interface IHyperlinkDB {
    hyperlink: IHyperlink
    isInDB: boolean
}

export interface DialogProps {
    isOpen: boolean,
    title: string,
    subTitle: string,
    execFunc: (param: number | IHyperlinkDB) => void,
    funcParam: number | IHyperlinkDB
}

export interface ConfirmationDialogProps {
    confirmDialog: DialogProps,
    setConfirmDialog: React.Dispatch<React.SetStateAction<DialogProps>>
}

export default function ConfirmationDialog({confirmDialog, setConfirmDialog}: ConfirmationDialogProps) {

    const handleClose = () => {
        setConfirmDialog({...confirmDialog, isOpen: false})
        confirmDialog.execFunc(confirmDialog.funcParam);
    }

    return (
        <Dialog
            open={confirmDialog.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {confirmDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirmDialog.subTitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDialog({...confirmDialog, isOpen: false})}>Cancel</Button>
                <Button onClick={() => handleClose()} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import { Box, Container, Typography,  Grid, Card, CardMedia, CardContent} from "@mui/material";

import './MeetPage.css'
import background from "../assets/partners.png";
import nova_logo from "../assets/partners_logos/NOVA-logo-w-padding.png"
import khio_logo from "../assets/partners_logos/khio-logo.png"
import ual_logo from "../assets/partners_logos/ual-logo.png"
import umprum_logo from "../assets/partners_logos/umprum-logo.png"
import weiss_logo from "../assets/partners_logos/weissensee-logo.png"


const MeetPage: React.FC = () => {
    
    const partners = [
        {
          logo: ual_logo,
          name: 'University of the Arts London (UAL)',
          description: 'UAL ceramics department is the largest and one of the oldest ceramic design courses in Europe teaching at BA/MA/PHD levels interconnecting material research, art and design practices and processes with the creative industries. With extensive expertise in practice-based design research, learning through making and pedagogical approaches to a historical discipline. The Faculty comprises leading practitioners spanning the breadth of the subject discipline, including ceramic design and production, civic regeneration, architectural ceramics, interaction design, community building in extreme contexts, and participatory learning. Staff publish peer-reviewed research in journal and symposia and exhibit art, craft and design practice in galleries, museums and exhibitions worldwide.',
        },
        {
          name: 'Academy of Arts, Architecture and Design in Prague (UMPRUM)',
          logo: umprum_logo,
          description: 'UMPRUM has a one-hundred-year tradition of teaching ceramics linked to industry. The school has a high level of expertise in face-to-face training. Educational methods are based on close interaction between students, teachers, and workshop masters. Students are educated in traditional crafts methods, especially those typical for UMPRUM’s cultural regions. Education is led by the concept of 3C (craft, concept, context). UMPRUM links the craft with the concept and also analyses the work in the context. The need for awareness of the social context is a crucial moment for the product or project. The ceramic studio raises awareness of the extensive possibilities of the original material and its various transformation potentials in all possible historical and technological contexts.',
        },
        {
            name: 'Oslo National Academy of the Arts (KHiO)',
            logo: khio_logo,
            description: 'Within the KHiO Art and Craft department, the workshop forms a central arena for teaching, artistic development and research. Craft-based investigations of materials and the development of relevant conceptual frameworks are central to the teaching methodology. The emphasis lies on developing artistic independence and encouraging an experimental and interdisciplinary approach. An international group of practising artists with specialist knowledge within their respective fields form the staff team. Writing and reflection are important aspects of the studies, and the teaching staff impart their expertise and artistic research through practical demonstrations, specialist courses and individual supervision.',
        },
        {
            name: 'NOVA University of Lisbon (NOVA)',
            logo: nova_logo,
            description: 'NOVA has linked the departments of Conservation and Restoration and the Department of Computer Sciences, and the Research Unit VICARTE – Vidro e Cerâmica para as Artes and NOVA LINCS from the School of Science and Technology. VICARTE promotes transdisciplinary research applied to glass and ceramics, connecting the present and the past, by studying traditional and historical practices, developing new materials and exploring different artistic concepts. Research activities generate new creative paths and innovations in artistic and scientific production, raising the consciousness of the importance of glass and ceramics in art, science, history, design and industry. NOVA LINCS’ mission is to develop cutting-edge scientific research in key areas of Computer Science and Informatics. Research is focused on methods and tools ‘to create, access, and interact with all forms of digital content in any device or scenario”. NOVA LINCS has worked extensively with conservation research centres, designers, social sciences researchers and public and private cultural institutions such as museums.',
        },
        {
          name: 'Berlin Weissensee School of Art (KHB)',
          logo: weiss_logo,
          description: 'Projects with ceramic materials are an integral part of the KHB Product Design School, where practical experience and theoretical reflection on process and practice play a particularly important role. The KHB ceramic workshop is an interdisciplinary interface, a place of exchange between departments and mutual inspiration. Ceramic techniques are closely linked with computer-aided technologies such as CNC milling, laser cutting and FDM printing. Ceramic 3D printing is part of the workshop repertoire and product designs, material experiments and artistic works are often realized in a sequence of digital and analogue steps. Also, experimental work with robotics and porcelain has taken place. The work with glass, which has become a regular part of the teaching in product design in recent years and for which the model and mould making can be carried out in the ceramics workshop, is also supported by practice partners.',
        }
    ];
      

    return (
        <Container className='mainMeetContainer'>
            <Box className='gridMeetPageWrap'>
                <Box className='meetTitleBox'>
                    <Typography variant="h2" className='meetTitle'>Meet the Partners</Typography>
                    <Box className='meetBriefBox'>
                        <Typography variant="body1" className='meetBrief'>
                        DECODING CERAMICS is the final output of CRAFT: Activating Pedagogy for Ceramic Education Futures and was co-funded by the Erasmus + programme of the European Union under Grant agreement (2020-1-UK01-KA203-079249) a 3-year strategic partnership, bringing together 5 institutions; UAL London, NOVA Lisbon, UMPRUM Prague, KHiO Oslo and KHB Berlin with the intention to: 
                        <ul>
                            <li>map ceramic knowledge across Europe,</li>
                            <li>assess the salience of practice to place,</li>
                            <li>build a visual and oral record of specialist processes and techniques,</li>
                            <li>develop methods to effectively share this knowledge across the teaching and expert network to ensure heritage crafts skills are relevant to future practice.</li>
                        </ul>
                        Decoding Ceramics is an open network, if you would like to contribute to the map or provide decoding cards, please email us here: <a className='mailTo' href = "mailto: decoding_ceramics@arts.ac.uk">decoding_ceramics@arts.ac.uk</a>
                        <br/>
                        <br/>
                        Further project information can be found here: <a className='mailTo' href = "https://craft.arts.ac.uk/">https://craft.arts.ac.uk/</a>

                        </Typography>
                    </Box>
                </Box>
                <Box className='meetImageBox' style={{ backgroundImage: `url(${background})` }}>
                    {/* Image will be placed here as a background */}
                </Box>
            </Box>
            <Grid container spacing={3} className="gridPartners">
                {partners.map((partner, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                    <Card className="partners-card">
                        <CardMedia
                        className="partners-media"
                        component="img"
                        image={partner.logo}
                        alt={partner.name}
                        />
                        <CardContent>
                        <Typography variant="h5" component="div">
                            {partner.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {partner.description}
                        </Typography>
                        </CardContent>
                    </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
 
export default MeetPage;
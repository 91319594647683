import React, {useCallback, useEffect, useState} from 'react';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {IKeyword, IPointOfInterest} from "../../interfaces";

import {
    Grid,
    TextField,
    Autocomplete,
    Typography,
    Box,
    Button,
    Select,
    MenuItem,
    FormControl, InputLabel, Container, CircularProgress, IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useKeyword, usePointOfInterest} from "../../hooks";
import "./VisualizePOIs.css";
import {POIPreview} from "../Information/POIPreview";
import {MapComponent} from "./MapComponent";
import Footer from '../Footer';
import { customIcon } from './MarkerComponent'
import { poiTypes } from './CreatePOI';
import CircleIcon from '@mui/icons-material/Circle';
import { useParams } from 'react-router-dom';

interface Props {
    keywords: IKeyword[];
    isPreview: boolean;
}

export const poiTypeColor = [
    {type: 'Artist/Studio', color: '#0000b2'},
    {type: 'Manufacturer', color: '#a5c4f0'},
    {type: 'Museum/Collection', color: '#b273d3'},
    {type: 'Supplier', color: '#ffa11c'},
    {type: 'Education', color: '#9ec2b2'},
    {type: 'Residency', color: '#ff96a0'},
    {type: 'Gallery/Design Store', color: '#ffe377'},
    {type: 'Craft Councils', color: 'red'}
]

export const VisualizePOIs: React.FC < Props > = ({keywords, isPreview}) => {

    const ALL_KEYWORDS = 1;
    const MOST_USED_KEYWORDS = 2;
    const UNIQUE_KEYWORDS = 3;
    
    const {pois, getAllPointsOfInterest} = usePointOfInterest();
    const {popularKeywords, uniqueKeywords, getPopularKeywords, getUniqueKeywords} = useKeyword();
    const { filterkeyword } = useParams<{ filterkeyword: string }>();

    const [poiPreview, setPoiPreview] = useState<IPointOfInterest | undefined>();
    const [poisByKeyword, setPoisByKeyword] = useState<IPointOfInterest[]>();
    const [keywordsOptions, setKeywordsOptions] = useState<string[]>();
    const [currentKeyword, setCurrentKeyword] = useState<string>('');
    const [maxNumVisible, setMaxNumVisible] = useState<number>(8)
    const [maxNumPreviewVisible, setMaxNumPreviewVisible] = useState<number>(3)
    const [visiblePois, setVisiblePois] = useState(pois.slice(0, maxNumVisible)); 
    const [end, setEnd] = useState(maxNumVisible);
    const [loading, setLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);       
    const [typeFilter, setTypeFilter] = useState('');
    const [filterSize, setFilterSize] = useState<number>(0);


    useEffect(() => {
        // Function to handle screen size change
        const handleResize = () => {
          const { innerWidth } = window; // Get the inner width of the window
          let newEnd = end;
          // Determine the screen size based on the inner width
          if (innerWidth < 576) {
            if(isPreview)
              setMaxNumPreviewVisible(3);
            else {
              setMaxNumVisible(8);
              while(newEnd % 4 !== 0) 
                newEnd+=1;
            }
          } else if (innerWidth < 768) {
            if(isPreview)
              setMaxNumPreviewVisible(3);
            else {
              setMaxNumVisible(8);
              while(newEnd % 4 !== 0) 
                newEnd+=1;
            }
          } else if (innerWidth < 992) {
            if(isPreview)
              setMaxNumPreviewVisible(3);
            else {
              setMaxNumVisible(8);
              while(newEnd % 4 !== 0) 
                newEnd+=1;
            }
          } else if (innerWidth < 1200) {
            if(isPreview)
              setMaxNumPreviewVisible(3);
            else {
              setMaxNumVisible(9);
              while(newEnd % 3 !== 0) 
                newEnd+=1;
            }
          } else {
            if(isPreview)
              setMaxNumPreviewVisible(4);
            else {
              while(newEnd % 4 !== 0) 
                newEnd+=1;
              setMaxNumVisible(8);
            }
          }
          setEnd(newEnd);
        };
    
        // Attach the resize event listener
        window.addEventListener('resize', handleResize);
    
        // Initial call to set the screen size on component mount
        handleResize();
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [end, isPreview]);

    useEffect(() => {
        getAllPointsOfInterest();
        getPopularKeywords();
        getUniqueKeywords();
        setLoading(false);
    }, [getAllPointsOfInterest, getPopularKeywords, getUniqueKeywords]);

    useEffect(()=>{
        if(filterkeyword) {
            setKeywordsOptions(keywords?.map((k)=> k.name));
            setCurrentKeyword(filterkeyword);
        }
    }, [filterkeyword, keywords]);

    const loadMorePois = () => {
        setIsLoadingMore(true);
        setTimeout(() => {
            setEnd(prevEnd => prevEnd + maxNumVisible);
            setIsLoadingMore(false);
        }, 100); // delay to simulate network request
    }
    
    const filterByType = useCallback((poi: IPointOfInterest) => {
        if (poi.type === typeFilter)
          return true
        else
          return false
    }, [typeFilter]);

    const filterbyKeyword = useCallback((poi: IPointOfInterest) => {
        if(poi.keywords.some((keyword) => (keyword.name === currentKeyword) ))
            return true
        else
            return false
    }, [currentKeyword]);

    useEffect(() => {
        let filteredPois: IPointOfInterest[];
        if(currentKeyword) {
            filteredPois = pois.filter((poi) => 
                (currentKeyword ? filterbyKeyword(poi) : true) &&
                (typeFilter ? filterByType(poi) : true)
            )
            setPoisByKeyword(filteredPois);
            setVisiblePois(isPreview ? filteredPois.slice(0, maxNumPreviewVisible) : filteredPois.slice(0, end));  // Adjust visible POIs here as well
        } else {
            filteredPois = pois.filter((poi) => 
                typeFilter ? filterByType(poi) : true
            )
            setVisiblePois(isPreview ? filteredPois.slice(0, maxNumPreviewVisible) : filteredPois.slice(0, end));  // Adjust visible POIs here as well
        }
        if(filteredPois.length < pois.length)
            setFilterSize(pois.length - filteredPois.length);
        else 
            setFilterSize(0);

    }, [end, pois, maxNumPreviewVisible, currentKeyword, filterbyKeyword, isPreview, typeFilter, filterByType]);

    const handleKeywordFilter = (selection: number) => {
        setCurrentKeyword('');
        setPoisByKeyword(undefined);

        if(selection === ALL_KEYWORDS)
            setKeywordsOptions(keywords?.map((k)=> k.name));
        else if(selection === MOST_USED_KEYWORDS)
            setKeywordsOptions(popularKeywords?.map((pk) => pk.name));
            // setKeywordsOptions(popularKeywords?.map((pk) => '' + pk.name + ' (' + pk.count + ')'));
        else if(selection === UNIQUE_KEYWORDS)
            setKeywordsOptions(uniqueKeywords?.map((uk) => uk.name));
    }

    const handleTypeFilter = (type: string) => {
        setTypeFilter(typeFilter === type ? '' : type);
      };

    const cleanConnections = () => {
        setPoisByKeyword(undefined);
        setCurrentKeyword('');
    }

    return  (
        <Container className='mainContainer'>    
            <Box position="relative">
                <Box>
                    <MapContainer center={[49.61070, 9.31640]} zoom={4} scrollWheelZoom={false}>
                    {/* var Stadia_StamenToner = L.tileLayer('https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.{ext}', {
                            minZoom: 0,
                            maxZoom: 20,
                            attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                            ext: 'png'
}); */}
                        {/* <TileLayer
                            attribution='&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                            
                            noWrap={true}
                        /> */}
                        <TileLayer
                            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
                            noWrap={true}
                        />
                        <MapComponent pois={pois} poisByKeyword={poisByKeyword} setPoi={setPoiPreview} />
                        {poiPreview && (
                            <Marker icon={customIcon} position={[poiPreview.lat, poiPreview.lng]} interactive={true} />
                        )}
                    </MapContainer>
                </Box>
                {poiPreview && (
                    <Box className='boxPoiPreview'>
                        <POIPreview poi={poiPreview} description={true}/>
                        <IconButton
                            className="closePreviewIcon"
                            onClick={(e) => {
                            e.preventDefault();
                            setPoiPreview(undefined);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}

                <Grid container spacing={2} alignItems="center" className="gridPoiType">
                    {poiTypeColor.map((poiType) => (
                        <Grid item key={poiType.type}>
                        <IconButton
                            className="iconPoiType"
                            sx={{
                            '&:hover': {
                                backgroundColor: 'inherit !important', 
                            },
                            }}
                            onClick={(e) => {
                            e.preventDefault();
                            handleTypeFilter(poiType.type);
                            }}
                        >
                            <CircleIcon sx={{ color: poiType.color }} />
                            <Typography sx={{fontWeight: typeFilter === poiType.type ? 'bold' : ''}} className='iconPoiTypeTypo' variant="caption">{poiType.type}</Typography>
                        </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {(pois.length === 0)? 
                (loading?
                <Container className='loadingContainer'>
                    <div className='loadingDiv'>
                    <CircularProgress color="inherit" />
                    <div>Loading...</div>
                    </div>
                </Container>:
                    <Container className='loadingContainer'>
                    <div className='loadingDiv'>
                    <div>No points of interest!</div>
                    </div>
                </Container>
                ):

            <Box className='mapPoisContainer'>
                <Box className="mapBox">
                    <Typography className="MapTypo" variant="h3">Map</Typography>
                    <Typography className="MapSub" variant="subtitle1">skills, knowledge & expertise</Typography>
                </Box>
                <Grid container spacing={2} className='gridFilters'>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                        className='autocompletePois'
                        disablePortal
                        options={pois.map((poi) => poi.name).sort()}
                        value={poiPreview ? poiPreview.name : ''}
                        isOptionEqualToValue={() => true}
                        onChange={(_, value) => {
                            if (value && value !== '') {
                            const poi: IPointOfInterest = pois.find((poi) => poi.name === value) as IPointOfInterest;
                            setPoiPreview(poi);
                            }
                        }}
                        onInputChange={(_, value, reason) => {
                            if (reason === 'clear') {
                                // Handle clear action here
                                setPoiPreview(undefined);  // for example
                            }
                        }}
                        renderInput={(params) => <TextField {...params} className='filterTextField' label="Find a Point of Interest" />}
                        />
                    </Grid>

                    {keywords && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl className='formcontrolKeyword'>
                            <InputLabel>Keyword Filter</InputLabel>
                            <Select
                            className='selectFilterForm'
                            label="Keyword Filter"
                            defaultValue={0}
                            onChange={(e) => handleKeywordFilter(e.target.value as number)}
                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All Keywords</MenuItem>
                            <MenuItem value={2}>Most Used Keywords</MenuItem>
                            <MenuItem value={3}>Unique Keywords</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    )}

                    {keywordsOptions && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                            disablePortal
                            options={keywordsOptions.sort()}
                            className='autocompletePois'
                            value={currentKeyword}
                            isOptionEqualToValue={() => true}
                            onInputChange={(_, value, reason) => {
                                if (reason === 'clear') {
                                    // Handle clear action here
                                    cleanConnections();
                                }
                            }}
                            onChange={async (_, value) => {
                            if (value && value !== '') {
                                setCurrentKeyword(value);
                                if (value.includes(' (')) {
                                // Remove frequency from selected value
                                value = value.substring(0, value.lastIndexOf(' ('));
                                }
                                // await getPoisByKeyword(value, setPoisByKeyword);
                            }
                            }}
                            renderInput={(params) => <TextField className='filterTextField' {...params} label="Choose a Keyword" />}
                        />
                    </Grid>                    
                    )}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                            disablePortal
                            options={poiTypes}
                            className='autocompletePois'
                            value={typeFilter}
                            isOptionEqualToValue={() => true}
                            onInputChange={(_, value, reason) => {
                                if (reason === 'clear') {
                                    // Handle clear action here
                                    setTypeFilter('');  // for example
                                }
                            }}
                            onChange={(_, value) => {
                            if (value && value !== '') {
                                setTypeFilter(value);
                            }
                            }}
                            renderInput={(params) => <TextField className='filterTextField' {...params} label="Choose a type" />}
                        />
                    </Grid> 
                </Grid>
                
                {poisByKeyword?
                <Box className='visiblePois'>
                    <Grid container spacing={2}>     
                    {poisByKeyword.map((poi:IPointOfInterest, index) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                <POIPreview key={poi.name} poi={poi} description={false}/>
                            </Grid>
                        
                    )})}
                    </Grid>
                </Box>
                :  <Box className='visiblePois'>
                        <Box>
                            <Grid container spacing={2}>
                                {visiblePois.map((poi, index) => (
                                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                    <POIPreview key={poi.name} poi={poi} description={false} />
                                </Grid>
                                ))}
                            </Grid>
                        </Box>
                        {(visiblePois.length < (pois.length-filterSize) ) && !isPreview && 
                        <Box className='buttonBox'>
                            <Button className='loadMoreButton' onClick={loadMorePois} disabled={isLoadingMore}>
                            {isLoadingMore ? "Loading..." : "Load More"}
                            </Button>
                        </Box>
                        }
                </Box>
            }
            </Box>}
            {!isPreview && <Footer />}
        </Container>
    );
}
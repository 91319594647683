import React, {createContext, useEffect, useState} from 'react'
import { useAuthentication } from '../../hooks';
import { useLocation } from 'react-router-dom';


export type AuthType = {
    auth: boolean,
    setAuth: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = createContext<AuthType | null>(null);

interface Props {
    children: React.ReactNode
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const { checkTokenValidity, refreshAccessToken } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    const validateToken = async () => {
      if (!token || !checkTokenExpiration()) {
        handleLogout();
        return;
      }
  
      const isValid = await checkTokenValidity(token);
  
      if (!isValid) {
        handleLogout();
        return;
      }
  
      setAuth(true);
  
      const refreshedToken = await refreshAccessToken(token);
  
      if (refreshedToken) {
        localStorage.setItem('token', refreshedToken.token);
  
        if (refreshedToken.expirationTimestamp) {
          localStorage.setItem('tokenExpiration', refreshedToken.expirationTimestamp.toString());
        }
      } 
      // else {
      //   handleLogout();
      // }
    };
  
    validateToken();
  }, [location.pathname, checkTokenValidity, refreshAccessToken]);

  
  const checkTokenExpiration = () => {
    const expirationTimestamp = localStorage.getItem('tokenExpiration');

    if (expirationTimestamp) {
      const currentTime = Date.now();
      const expirationTime = parseInt(expirationTimestamp) * 1000;
      return currentTime < expirationTime;
    }
    return false;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setAuth(false);
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
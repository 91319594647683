import React, {useEffect, useState} from 'react';
import {ICard, IFile, IFileUpdate, IKeyword, IPointOfInterest, IVideo} from "../../interfaces";
import {useCard, useFile, usePointOfInterest} from "../../hooks";
import {MapContainer, TileLayer} from "react-leaflet";
import {
    Box,
    Button,
    Container,
    Grid,
    Snackbar,
    TextField,
    Typography,
    Tabs,
    Tab,
    Autocomplete, MenuItem, Link, CircularProgress,
} from "@mui/material";
// import background from "../../assets/general_background.png";
import {FileUpload} from "../File/FileUpload";
import "./UpdatePOI.css";
import {useParams, useNavigate} from "react-router-dom";
import {Add, Clear, Delete} from "@mui/icons-material";
import {MapComponent} from "./MapComponent";
import ConfirmationDialog, {DialogProps} from "../ConfirmationDialog";
import {IHyperlink} from "../../interfaces/IHyperlink";
import {hyperlinkTypes} from "./CreatePOI";

const snackBarMessage = {
    info: 'Point of Interest updated successfully!',
    delete: 'Point of Interest deleted successfully!',
    images: 'Images updated successfully!',
    imageDeleted: 'Image was deleted successfully!',
    videos: 'Videos updated successfully!',
    maxVideos: 'The maximum number of videos allowed is 5.',
    videoDeleted: 'Video was deleted successfully!',
    keywordDeleted: 'Keyword was deleted successfully!',
    hyperlinkDeleted: 'Hyperlink was deleted successfully!',
    emptyKeyword: 'Please write or select a keyword to add first.',
    emptyHyperlink: 'Please write a hyperlink to add first.',
    emptyTitleHyperlink: 'Please give the hyperlink a title first.',
    noPOIChosen: 'Please select the POI that you want to update.',
    videoWrongUrlFormat: 'Please put video url in the original format (with embed)',
    videoUpdateWrongUrl: 'Please make sure you only update videos that have a valid url.',
    repeatedKeyword: 'Cannot add the same keyword twice.',
    repeatedHyperlink: 'Cannot add the same hyperlink twice.',
    repeatedHlTitle: 'Cannot add the same hyperlink title twice.'

}

export const dialogInfo = {
    subTitle: 'This operation cannot be undone.',
    poiTitle: 'Delete this POI?',
    videoTitle: 'Delete this video?',
    imageTitle: 'Delete this image?',
    keywordTitle: 'Delete this keyword?',
    hyperlinkTitle: 'Delete this hyperlink?'
}

const type = "point-of-interest";

interface Props {
    keywords: IKeyword[],
    getAllKeywords: () => Promise<void>
}

export const UpdatePOI: React.FC<Props> = ({keywords, getAllKeywords}) => {
    let {tab} = useParams<string>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const [openGenSnackbar, setOpenGenSnackbar] = useState(false);
    const [messageInfo, setMessageInfo] = React.useState<string>('');

    const [poiToUpdate, setPoiToUpdate] = useState<IPointOfInterest>();

    const {pois, setPois, getAllPointsOfInterest, getPointOfInterestById, updatePointOfInterest, deletePointOfInterest} = usePointOfInterest();
    const { cardsTitle, getAllCardsTitle } = useCard();
    const {uploadAndRetrieveUrls} = useFile();

    useEffect(() => {
        getAllPointsOfInterest();
        getAllCardsTitle();
    }, [getAllPointsOfInterest, getAllCardsTitle]);

    const updatePOIState = async (sbMessage: string, updateKeyword: boolean) => {
        console.log("Inside updatePOIState")
        await getPointOfInterestById(poiToUpdate!.id).then((updatedPOI) => {
            console.log("Received new POI")
            // Create a shallow clone of the updated object
            const updatedPoiClone = { ...updatedPOI };

            // Update the state with the cloned object
            setPoiToUpdate(updatedPoiClone);

            const poiIndex = pois.findIndex((poi) => poi.id === updatedPOI.id);
            pois.splice(poiIndex,1,updatedPOI);
            const newArr = [...pois]; //make array immutable. this will trigger useEffect on child components when pois prop changes. only mutating the array would not trigger useEffect when setting pois
            setPois(newArr);    //update pois
            if(updateKeyword)
                getAllKeywords(); //update keywords state
            setMessageInfo(sbMessage);
            setOpenGenSnackbar(true);
            setLoading(false);
        });
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                {...other}
            >
                {value === index && (
                    <Container>
                        <Box>
                            {children}
                        </Box>
                    </Container>
                )}
            </div>
        );
    }

    interface UpdatePoiTabsProps {
        poiToUpdate:IPointOfInterest | undefined
    }
    interface IHyperlinkDB {
        hyperlink: IHyperlink
        isInDB: boolean
    }

    const UpdatePOITabs: React.FC<UpdatePoiTabsProps> = ({poiToUpdate}) => {
        let navigate = useNavigate();

        const [confirmDialog, setConfirmDialog] = useState<DialogProps>({isOpen: false, title: '', subTitle: '' , execFunc: () => {}, funcParam: 0});

        const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false); //important because if it was in the parent component it would delete possible user input, therefore we do not use the general one here
        const [localSnackbarMsg, setLocalSnackbarMsg] = useState('');

        const [images, setImages] = useState<IFile[]>([]);
        const [videos, setVideos] = useState<IVideo[]>([]);

        const [poiName,setPoiName] = useState<string>('');
        const [poiProfile,setPoiProfile] = useState<string>('');
        const [poiType, setPoiType] = useState<string>('');
        const [poiProcTec,setPoiProcTec] = useState<string>('');
        const [poiKeywords,setPoiKeywords] = useState<(string|IKeyword)[]>([]);
        const [poiTeachTools,setPoiTeachTools] = useState<string>('');
        const [poiLMore,setPoiLMore] = useState<string>('');
        const [poiHyperlinks,setPoiHyperlinks] = useState<IHyperlinkDB[]>([]);
        const [poiLat,setPoiLat] = useState<string>('');
        const [poiLng,setPoiLng] = useState<string>('');
        const [poiCards, setPoiCards] = useState<ICard[]>([]);

        const {removeKeywordFromPOI, removeHyperlinkFromPOI} = usePointOfInterest();
        const {deleteAllImages} = useFile();
        const [currentKeyword, setCurrentKeyword] = useState<string>('');
        const [currentProfHlTitle, setCurrentProfHlTitle] = useState<string>('');
        const [currentProfHyperlink, setCurrentProfHyperlink] = useState<string>('');
        const [currentPTHyperlink,setCurrentPTHyperlink] = useState<string>('');
        const [currentPTHlTitle, setCurrentPTHlTitle] = useState<string>('');
        const [currentTTHyperlink,setCurrentTTHyperlink] = useState<string>('');
        const [currentTTHlTitle, setCurrentTTHlTitle] = useState<string>('');
        const [currentLMHyperlink,setCurrentLMHyperlink] = useState<string>('');
        const [currentLMHlTitle, setCurrentLMHlTitle] = useState<string>('');
        const [currentCard, setCurrentCard] = useState<ICard>();

        interface VideoProps {
            videos:IVideo[]
        }

        const VideoUpload: React.FC<VideoProps> = ({videos}) => {
            interface VideoDB {
                video: IVideo
                isInDB: boolean
            }

            const [videoList, setVideoList] = useState<VideoDB[]>(videos.map((v) => {return {video: v, isInDB:true}} ));
            const {updatePointOfInterestVideos, deletePointOfInterestVideo} = usePointOfInterest();

            const [openVideoSnackbar, setOpenVideoSnackbar] = useState(false);
            const [videoSnackbarMsg, setVideoSnackbarMsg] = useState('');

            const addVideoToList = () => {
                if (videoList.length < 5)
                    setVideoList([...videoList, {video: {url: '', title: '', credits: ''}, isInDB:false}]);

                else {
                    setVideoSnackbarMsg(snackBarMessage.maxVideos);
                    setOpenVideoSnackbar(true);
                }
            }

            const updateUrlField = (index: number, newValue: string) => {
                let newArr = [...videoList];
                let newObj = newArr[index].video;
                newObj.url = newValue;
                setVideoList(newArr);
            }
            const updateTitleField = (index: number, newValue: string) => {
                let newArr = [...videoList];
                let newObj = newArr[index].video;
                newObj.title = newValue;
                setVideoList(newArr);
            }

            const updateCreditsField = (index: number, newValue: string) => {
                let newArr = [...videoList];
                let newObj = newArr[index].video;
                newObj.credits = newValue;
                setVideoList(newArr);
            }

            const deleteVideoFromBEAndArray = async (index: number) => {
                const videoUrl = videoList[index].video.url

                if (videoUrl.includes('embed/')) {
                    setLoading(true);
                    removeFromArray(index);
                    await deletePointOfInterestVideo(poiToUpdate!.name,videoUrl).then(async ()=>{
                        await updatePOIState(snackBarMessage.videoDeleted, false);
                    })
                }
                else {
                    setVideoSnackbarMsg(snackBarMessage.videoWrongUrlFormat);
                    setOpenVideoSnackbar(true);
                }
            }

            const removeFromArray = (index:number) => {
                videoList.splice(index,1)
                setVideoList([...videoList]);
            }

            const updateVideos = async () => {
                const youtubeRegExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
                const customRegExp = /^.*\/embed\?bgcolor=.*&txtcolor=.*&tracks=(\w+).*&share-token=.*$/;

                videoList.forEach((video)=>{
                    if(!video.isInDB) {
                        if (video.video.url.match(youtubeRegExp)) {
                            const match = video.video.url.match(youtubeRegExp);
                            if (match && match[2].length === 11) {
                              const videoId = match[2];
                              const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                              video.video.url = embedUrl;
                            } else {
                              video.video.url = "";
                              setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                              setOpenVideoSnackbar(true);
                            }
                          } else if (video.video.url.match(customRegExp)) {
                            const match = video.video.url.match(customRegExp);
                            if (match && match[1] === 'true') {
                              const updatedUrl = video.video.url.replace(/tracks=true/, 'tracks=false');
                              video.video.url = updatedUrl;
                            }
                          } else {
                            video.video.url = "";
                            setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                            setOpenVideoSnackbar(true);
                          }
                    }
                })
                setLoading(true);
                let updateVideos = videoList.map((v) => {return (v.video)});

                await updatePointOfInterestVideos(poiToUpdate!.name, updateVideos).then(async ()=>{
                    await updatePOIState(snackBarMessage.videos, false);
                })
                
            }

            return (
                <Box>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                            <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                Add Videos To Your Point
                            </Typography>
                            <Button size={'small'} onClick={addVideoToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                        </Box>
                        <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                            The number of videos should be a maximum of 5 and they should be already uploaded on youtube (Craft Project Channel).
                        </Typography>
                        {videoList.length !== 0 && <Box width={'100%'}>
                                {videoList.map((vidDB:VideoDB, index)=>{
                                    return (
                                        <Box key={index}>
                                            <Box className={'title-with-icon'}>
                                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                                    Video {index + 1}
                                                </Typography>
                                                {vidDB.isInDB ?
                                                    <Button onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.videoTitle, subTitle: dialogInfo.subTitle, execFunc: deleteVideoFromBEAndArray as ((param: number | IHyperlinkDB) => void), funcParam: index}) } variant={"contained"} sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%', backgroundColor: 'red'}    } >Delete Video</Button>
                                                    : <Delete onClick={() => removeFromArray(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                                }
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                           required
                                                           label="Youtube Video URL"
                                                           value={vidDB.video.url}
                                                           onChange={(e) => updateUrlField(index,e.currentTarget.value)}
                                                />
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                           required
                                                           label="Title"
                                                           value={vidDB.video.title}
                                                           onChange={(e) => updateTitleField(index,e.currentTarget.value)}
                                                />
                                            </Box>

                                            <TextField fullWidth
                                                       required
                                                       label="Credits"
                                                       value={vidDB.video.credits}
                                                       onChange={(e) => updateCreditsField(index,e.currentTarget.value)}
                                            />

                                            <Box className={'box-with-video-preview'}>
                                                {vidDB.video.url !== '' && (vidDB.video.url.includes('watch?v=') || vidDB.video.url.includes('embed/')) &&
                                                    <iframe
                                                        width="200"
                                                        height="150"    //check if it is in the format    //sometimes google adds chanel= in url so it would be prefered for people to paste directly with the embed format or without this additional chanel=
                                                        title='youtube video'
                                                        src={vidDB.video.url.includes('embed/') ? vidDB.video.url : vidDB.video.url.replace('watch?v=', 'embed/')} //transform in embed format
                                                        allow="autoplay; encrypted-media"
                                                        allowFullScreen
                                                        style = {{border: '0'}}></iframe>

                                                }
                                            </Box>
                                        </Box>
                                    )})
                                }
                            <Button onClick={updateVideos} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update Videos</Button>
                        </Box>}
                        <Snackbar
                            open={openVideoSnackbar}
                            autoHideDuration={2000}
                            message={videoSnackbarMsg}
                            onClose = {() => setOpenVideoSnackbar(false)}
                        />
                    </Box>
                </Box>
            );
        }

        const setPoiFields = (poi: IPointOfInterest) => {
            setPoiName(poi.name);
            setPoiLat(poi.lat.toFixed(5).toString());
            setPoiLng(poi.lng.toFixed(5).toString());
            setPoiType(poi.type);
            setPoiProfile(poi.profile);
            setPoiProcTec(poi.processTechniques);
            setPoiKeywords(poi.keywords);
            setPoiTeachTools(poi.teachingTools);
            setPoiLMore(poi.learnMore);
            let hyperlinksDB: IHyperlinkDB[] = poi.hyperlinks.map((hl) => {return {hyperlink: { title: hl.title, url: hl.url, field: hl.field}, isInDB: true} });
            setPoiHyperlinks(hyperlinksDB);
            setPoiCards(poi.cards)
        }

        const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue);
            navigate('/point-of-interest/update/'+ newValue);

        };

        const updatePOIImages = async (imagesToUpdate: IFileUpdate[]) => {
            setLoading(true);
            await uploadAndRetrieveUrls(imagesToUpdate, type, poiToUpdate!.id).then(async ()=>{
                await updatePOIState(snackBarMessage.images, false);
            })
          }
          

        const handleUpdatePOI = async () => {

            if(poiToUpdate) {
                setLoading(true);
                await updatePointOfInterest({
                    id: poiToUpdate.id,
                    name: poiName,
                    lat: parseFloat(poiLat),
                    lng: parseFloat(poiLng),
                    type: poiType,
                    profile: poiProfile,
                    processTechniques: poiProcTec,
                    keywords: poiKeywords.map((k) => { return (typeof k === 'string' ? k : k.name)}),
                    teachingTools: poiTeachTools,
                    learnMore: poiLMore,
                    hyperlinks: poiHyperlinks.map((hlDB) => {return (hlDB.hyperlink)}),
                    cards: poiCards
                }).then(async ()=>{
                    await updatePOIState(snackBarMessage.info,true);
                })

            }
            else {
                setMessageInfo(snackBarMessage.noPOIChosen);
                setOpenGenSnackbar(true);
            }

        }

        const deletePOI = async () => {

            if(poiToUpdate) {

                if(poiToUpdate.images.length !== 0)
                    await deleteAllImages(type, poiToUpdate.images.map((img) => { return (img.uuid as string)}));

                await deletePointOfInterest(poiToUpdate.id);

                const poiIndex = pois.findIndex((poi) => poi.id === poiToUpdate.id);
                pois.splice(poiIndex,1);
                const newArr = [...pois]; //make array immutable. this will trigger useEffect on child components when pois prop changes. only mutating the array would not trigger useEffect when setting pois
                setPois(newArr);  //update pois
                getAllKeywords(); //update keywords state
                setPoiToUpdate(undefined);
                setMessageInfo(snackBarMessage.delete);
                setOpenGenSnackbar(true);


            }
            else {
                setMessageInfo(snackBarMessage.noPOIChosen);
                setOpenGenSnackbar(true);
            }

        }

        const handleAddKeyword = () => {
            if (currentKeyword === '') {
                setLocalSnackbarMsg(snackBarMessage.emptyKeyword);
                setOpenLocalSnackbar(true);
            }
            else if (poiKeywords.find( (k) => typeof k === 'string' ? k === currentKeyword : k.name === currentKeyword)) {
                setLocalSnackbarMsg(snackBarMessage.repeatedKeyword);
                setOpenLocalSnackbar(true);
            }
            else {
                setPoiKeywords([...poiKeywords, currentKeyword]);
                setCurrentKeyword('');
            }

        }

        const removeKeyword = (index:number) => {
            poiKeywords.splice(index,1);
            setPoiKeywords([...poiKeywords]);
        }

        const removeKeywordFromDB = async (index:number) => {
            setLoading(true);
            const keyword = poiKeywords[index] as IKeyword;

            removeKeyword(index);
            await removeKeywordFromPOI(poiToUpdate!.name, keyword.name).then(async ()=> {
                await updatePOIState(snackBarMessage.keywordDeleted,true);
            })

        }

        const handleAddHyperlink = (currentHyperlink: string, setCurrentHyperlink: React.Dispatch<React.SetStateAction<string>>, currentHlTitle: string, setCurrentHlTitle: React.Dispatch<React.SetStateAction<string>>, field: string) => {
            if (currentHyperlink === '') {
                setLocalSnackbarMsg(snackBarMessage.emptyHyperlink);
                setOpenLocalSnackbar(true);
            }
            else if (currentHlTitle === '') {
                setLocalSnackbarMsg(snackBarMessage.emptyTitleHyperlink);
                setOpenLocalSnackbar(true);
            }
            else if (poiHyperlinks.find(({hyperlink}) => hyperlink.url === currentHyperlink && hyperlink.field === field)) {
                setLocalSnackbarMsg(snackBarMessage.repeatedHyperlink);
                setOpenLocalSnackbar(true);
            }
            else if (poiHyperlinks.find(({hyperlink}) => hyperlink.title === currentHlTitle && hyperlink.field === field)) {
                setLocalSnackbarMsg(snackBarMessage.repeatedHlTitle);
                setOpenLocalSnackbar(true);
            }
            else {

                setPoiHyperlinks([...poiHyperlinks, {hyperlink: {title: currentHlTitle, url: currentHyperlink, field: field}, isInDB: false}]);
                setCurrentHyperlink('');
                setCurrentHlTitle('');

            }
        }

        const removeHyperlink = (hl: IHyperlinkDB) => {
            poiHyperlinks.splice(poiHyperlinks.indexOf(hl), 1);
            setPoiHyperlinks([...poiHyperlinks]);
        }

        const removeHyperlinkFromDB = async (hl: IHyperlinkDB) => {
            setLoading(true);
            removeHyperlink(hl);
            await removeHyperlinkFromPOI(poiToUpdate!.name, hl.hyperlink.field, hl.hyperlink.title).then(async ()=> {
                await updatePOIState(snackBarMessage.hyperlinkDeleted,false);
            })
        }

        const handleAddCard = (card: ICard) => {
            if (card && !poiCards.find((currentCard) => currentCard.id === card.id)) {
                setPoiCards((prevPoiCards) => [...prevPoiCards, card]);
            }
        };
    
        const handleRemoveCard = (card: ICard) => {
            setPoiCards((prevPoiCards) => prevPoiCards.filter((c) => c !== card));
        };

        useEffect(() => { //update all fields
            console.log("inside useEffect to update poi fields")
            if (poiToUpdate) {
                setPoiFields(poiToUpdate);
                setImages(poiToUpdate.images);
                setVideos(poiToUpdate.videos);
                setLoading(false);
            }

        }, [poiToUpdate]);

        return (
            <Box height={'100%'}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="General POI Info"/>
                        <Tab label="Images"/>
                        <Tab label="Videos"/>
                        <Tab label="Delete POI"/>
                    </Tabs>
                </Box>
                <Box mt={"2%"}>
                    <TabPanel value={selectedTab} index={0} >
                        <Box>
                            <Typography variant = "h6" mb={"1%"} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                Identification Info
                            </Typography>

                            <Box pb = "2%">
                                <TextField fullWidth
                                           required
                                           label="Name"
                                           value={poiName}
                                           onChange={(e) => setPoiName(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pb = "2%" display={"flex"} justifyContent={"space-between"}>
                                <TextField style = {{width: '47%'}}
                                           required
                                           label="Latitude"
                                           value= {poiLat}
                                           onChange={(e) => setPoiLat(e.currentTarget.value)}
                                />
                                <TextField style = {{width: '47%'}}
                                           required
                                           label="Longitude"
                                           value= {poiLng}
                                           onChange={(e) => setPoiLng(e.currentTarget.value)}
                                />

                            </Box>

                            <Box pb = "2%">
                                <TextField
                                    fullWidth
                                    required
                                    select
                                    label="Type"
                                    value={poiType}
                                    onChange={(e) => setPoiType(e.target.value)}
                                    helperText="Please select the type of your Point of Interest"
                                >

                                    <MenuItem value={'Artist/Studio'}>
                                        Artist/Studio
                                    </MenuItem>
                                    <MenuItem value={'Manufacturer'}>
                                        Manufacturer
                                    </MenuItem>
                                    <MenuItem value={'Museum/Collection'}>
                                        Museum/collection
                                    </MenuItem>
                                    <MenuItem value={'Supplier'}>
                                        Supplier
                                    </MenuItem>
                                    <MenuItem value={'Education'}>
                                        Education
                                    </MenuItem>
                                    <MenuItem value={'Residency'}>
                                        Residency
                                    </MenuItem>
                                    <MenuItem value={'Gallery/Design Store'}>
                                        Gallery/Design Store
                                    </MenuItem>
                                    <MenuItem value={'Craft Councils'}>
                                        Craft Councils
                                    </MenuItem>

                                </TextField>
                            </Box>

                            {keywords && <Box>
                                <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Keywords
                                </Typography>

                                <Box className={'keyword-box-w-ac'}>

                                    <Box width={'70%'} pt={1}>
                                        <Autocomplete
                                            disablePortal
                                            options={keywords.map((keyword) => {return (keyword.name)})}
                                            value={currentKeyword}
                                            onChange={(_,value) => value && setCurrentKeyword(value)}
                                            isOptionEqualToValue={() => true} //to avoid warning since '' is not present in options
                                            renderInput={(params) => <TextField {...params} onChange={(e) => setCurrentKeyword(e.currentTarget.value)} label="Select or write a keyword" helperText={"Specific concept that describes the artist/place."}/>}
                                        />
                                    </Box>
                                    <Button onClick={handleAddKeyword} variant={"contained"} color={"success"} className={'add-keyword-but'} sx={{height:'5%', marginTop: '1%', marginBottom: '1%'}}>Add Keyword</Button>
                                    <Snackbar
                                        open={openLocalSnackbar}
                                        autoHideDuration={2000}
                                        message={localSnackbarMsg}
                                        onClose = {() => setOpenLocalSnackbar(false)}
                                    />

                                </Box>

                                {poiKeywords.length !== 0 && <Box>
                                    {poiKeywords.map((kw, index) => {
                                        return (
                                            <Box key={index}>
                                                {typeof kw === 'string' ?
                                                    (<Box pt={1} width={'70%'} className={'same-row'}>
                                                        <Button variant={"contained"} color="secondary" sx={{cursor:'default', textTransform:'none',height:'5%', marginRight: '1%', borderRadius:'2em'}}>{kw}</Button>
                                                        <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeKeyword(index)} />
                                                    </Box>)
                                                :  (<Box pt={1} width={'70%'} className={'same-row'}>
                                                        <Button variant={"contained"} color="secondary" sx={{cursor:'default', textTransform:'none',height:'5%', marginRight: '1%', borderRadius:'2em'}}>{kw.name}</Button>
                                                        <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.keywordTitle, subTitle: dialogInfo.subTitle, execFunc: removeKeywordFromDB as (param: number | IHyperlinkDB) => void, funcParam: index})}>Delete Keyword</Button>
                                                    </Box>)
                                                }
                                            </Box>
                                        );
                                    })}
                                </Box>}

                            </Box>}

                            <Typography variant = "h6" pt={"1%"} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                Craft Specific Info
                            </Typography>
                            <Box pt={2}>
                                <TextField fullWidth
                                           multiline
                                           maxRows={2}
                                           label="Profile"
                                           helperText="Introduce yourself or your institution."
                                           inputProps={{
                                               maxLength: 500,
                                           }}
                                           value={poiProfile}
                                           onChange={(e) => setPoiProfile(e.currentTarget.value)}
                                />
                            </Box>

                            <Box className={'keyword-box-w-ac'}>

                                <Box width={'80%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Profile Hyperlink Title"
                                                   value={currentProfHlTitle}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentProfHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Profile Hyperlink"
                                                   helperText="Clickable hyperlink to add to the profile field."
                                                   value={currentProfHyperlink}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentProfHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentProfHyperlink, setCurrentProfHyperlink, currentProfHlTitle, setCurrentProfHlTitle, hyperlinkTypes.profile)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginLeft: '5%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {poiHyperlinks.filter((hl) => hl.hyperlink.field === hyperlinkTypes.profile).map((phl, index) => {
                                return (
                                    <Box pt={1} width={'100%'} className={'same-row'} key={index}>
                                        <Box width={'70%'}>
                                            <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {phl.hyperlink.title}</Typography>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    window.open(phl.hyperlink.url, '_blank');
                                                }}
                                            >
                                                {phl.hyperlink.url}
                                            </Link>
                                        </Box>
                                        {!phl.isInDB ?
                                            <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeHyperlink(phl)} />
                                            : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: phl})}>Delete Hyperlink</Button>
                                        }
                                    </Box>
                                );
                            })}

                            <Box pt={2}>
                                <TextField fullWidth
                                           multiline
                                           maxRows={2}
                                           label="Process/Techniques"
                                           helperText="Describe your specialist process(es)."
                                           inputProps={{
                                               maxLength: 500,
                                           }}
                                           value={poiProcTec}
                                           onChange={(e) => setPoiProcTec(e.currentTarget.value)}
                                />
                            </Box>

                            <Box className={'keyword-box-w-ac'}>

                                <Box width={'80%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Process/Techniques Hyperlink Title"
                                                   value={currentPTHlTitle}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentPTHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Process/Techniques Hyperlink"
                                                   helperText="Clickable hyperlink to add to the process/techniques field."
                                                   value={currentPTHyperlink}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentPTHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentPTHyperlink, setCurrentPTHyperlink, currentPTHlTitle, setCurrentPTHlTitle, hyperlinkTypes.processTechniques)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginLeft: '5%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {poiHyperlinks.filter((hl) => hl.hyperlink.field === hyperlinkTypes.processTechniques).map((pthl, index) => {
                                return (
                                    <Box pt={1} width={'100%'} className={'same-row'} key={index}>
                                        <Box width={'70%'}>
                                            <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {pthl.hyperlink.title}</Typography>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    window.open(pthl.hyperlink.url, '_blank');
                                                }}
                                            >
                                                {pthl.hyperlink.url}
                                            </Link>
                                        </Box>
                                        {!pthl.isInDB ?
                                            <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeHyperlink(pthl)} />
                                            : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: pthl})}>Delete Hyperlink</Button>
                                        }
                                    </Box>
                                );
                            })}

                            <Box pt={2}>
                                <TextField fullWidth
                                           multiline
                                           maxRows={2}
                                           label="Teaching tools"
                                           helperText="Place teaching resources here."
                                           inputProps={{
                                               maxLength: 450, //originally 150 but as there can be more than one, it is x3
                                           }}
                                           value={poiTeachTools}
                                           onChange={(e) => setPoiTeachTools(e.currentTarget.value)}
                                />
                            </Box>

                            <Box className={'keyword-box-w-ac'}>

                                <Box width={'80%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Teaching tools Hyperlink Title"
                                                   value={currentTTHlTitle}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentTTHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Teaching tools Hyperlink"
                                                   helperText="Clickable hyperlink to add to the teaching tools field."
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   value={currentTTHyperlink}
                                                   onChange={(e) => setCurrentTTHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentTTHyperlink, setCurrentTTHyperlink, currentTTHlTitle, setCurrentTTHlTitle, hyperlinkTypes.teachingTools)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginLeft: '5%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {poiHyperlinks.filter((hl) => hl.hyperlink.field === hyperlinkTypes.teachingTools).map((tthl, index) => {
                                return (
                                    <Box pt={1} width={'100%'} className={'same-row'} key={index}>
                                        <Box width={'70%'}>
                                            <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {tthl.hyperlink.title}</Typography>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    window.open(tthl.hyperlink.url, '_blank');
                                                }}
                                            >
                                                {tthl.hyperlink.url}
                                            </Link>
                                        </Box>
                                        {!tthl.isInDB ?
                                            <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeHyperlink(tthl)} />
                                            : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: tthl})}>Delete Hyperlink</Button>
                                        }
                                    </Box>
                                );
                            })}

                            <Box pt={2}>
                                <TextField fullWidth
                                           multiline
                                           maxRows={2}
                                           label="Learn More"
                                           helperText="Further describe your field of work and /or place further study materials in the links section."
                                           inputProps={{
                                               maxLength: 1000,
                                           }}
                                           value={poiLMore}
                                           onChange={(e) => setPoiLMore(e.currentTarget.value)}
                                />
                            </Box>

                            <Box className={'keyword-box-w-ac'}>

                                <Box width={'80%'}>
                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Learn More Hyperlink Title"
                                                   value={currentLMHlTitle}
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   onChange={(e) => setCurrentLMHlTitle(e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box pt={1}>
                                        <TextField fullWidth
                                                   label="Learn More Hyperlink"
                                                   helperText="Clickable hyperlink to add to the learn more field."
                                                   inputProps={{
                                                       maxLength: 255,
                                                   }}
                                                   value={currentLMHyperlink}
                                                   onChange={(e) => setCurrentLMHyperlink(e.currentTarget.value)}
                                        />
                                    </Box>
                                </Box>

                                <Button onClick={() => handleAddHyperlink(currentLMHyperlink, setCurrentLMHyperlink, currentLMHlTitle, setCurrentLMHlTitle, hyperlinkTypes.learnMore)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginLeft: '5%', placeSelf: 'center'}}>Add Hyperlink</Button>

                            </Box>

                            {poiHyperlinks.filter((hl) => hl.hyperlink.field === hyperlinkTypes.learnMore).map((lmhl, index) => {
                                return (
                                    <Box pt={1} width={'100%'} className={'same-row'} key={index}>
                                        <Box width={'70%'}>
                                            <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {lmhl.hyperlink.title}</Typography>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    window.open(lmhl.hyperlink.url, '_blank');
                                                }}
                                            >
                                                {lmhl.hyperlink.url}
                                            </Link>
                                        </Box>
                                        {!lmhl.isInDB ?
                                            <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                            : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: lmhl})}>Delete Hyperlink</Button>
                                        }
                                    </Box>
                                );
                            })}

                            <Box>
                                <Typography variant="h6" pt={1} align="left" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                                    Associated Cards
                                </Typography>

                                <Box className="card-association-box">
                                    <Box width="70%" pt={1}>
                                    <Autocomplete
                                        disablePortal
                                        options={cardsTitle}
                                        getOptionLabel={(option) => option.title}
                                        value={undefined}
                                        onChange={(_, value) => value && setCurrentCard(value)}
                                        isOptionEqualToValue={() => true}
                                        renderInput={(params) => (
                                        <TextField {...params} label="Select cards" helperText="Choose cards to associate with the point of interest" />
                                        )}
                                    />
                                    </Box>
                                    <Button
                                    onClick={() => {
                                        // Perform any necessary action with the selected cards, such as saving them to the backend
                                        if(currentCard)
                                            handleAddCard(currentCard);
                                    }}
                                    variant="contained"
                                    color="success"
                                    className="add-card-button"
                                    sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}
                                    >
                                    Add Card
                                    </Button>
                                </Box>

                                {poiCards.length !== 0 && (
                                    <Box>
                                    {poiCards.map((card, index) => (
                                        <Box pt={1} width="70%" className="same-row" key={"card"+index}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ cursor: 'default', textTransform: 'none', height: '5%', marginRight: '1%', borderRadius: '2em' }}
                                        >
                                            {card.title}
                                        </Button>
                                        <Clear cursor="pointer" fontSize="large" color="action" className="center-icon" onClick={() => handleRemoveCard(card)} />
                                        {/* <Clear cursor="pointer" fontSize="large" color="action" className="center-icon" onClick={() => handleRemoveCard(card)} /> */}
                                        </Box>
                                    ))}
                                    </Box>
                                )}
                            </Box>

                            <Button onClick={handleUpdatePOI} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update</Button>
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        {/* {poiToUpdate ? <FileUpload files = {images} updatePOIImages={updatePOIImages} deletePOIImage={deletePOIImage} setConfirmDialog={setConfirmDialog}/> */}
                        {poiToUpdate ? <FileUpload files = {images} updatePOIImages={updatePOIImages} setConfirmDialog={setConfirmDialog} setLoading={setLoading}/>
                            : <Typography variant = "h6" sx={{fontFamily: 'KUniforma'}}>
                            First select the POI in which you want to add images.
                        </Typography>
                        }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        {poiToUpdate ? <VideoUpload videos = {videos}/>
                            : <Typography variant = "h6" sx={{fontFamily: 'KUniforma'}}>
                                First select the POI in which you want to add videos.
                            </Typography>
                        }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        {poiToUpdate ? <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
                                        <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '3%'}} >
                                            <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                                Delete your Point of Interest
                                            </Typography>
                                        </Box>
                                        <Button onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.poiTitle, subTitle: dialogInfo.subTitle, execFunc: deletePOI as ((param: number | IHyperlinkDB) => void), funcParam: poiToUpdate.id}) } size={'small'} variant={"contained"} sx={{width:'53%', backgroundColor: 'red'}} endIcon={<Delete/>} >Delete</Button>

                                    </Box>
                                    : <Typography variant = "h6" sx={{fontFamily: 'KUniforma'}}>
                                        First select the POI which you want to delete.
                                    </Typography>
                        }
                    </TabPanel>
                    <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}/>
                </Box>
            </Box>
        );
    }

    useEffect(() => {
        if(tab) setSelectedTab(parseInt(tab))
    }, [tab]);
    

    return (
        <Box>
            <Grid container>
                <Grid item xs={7}>
                    <MapContainer center={[39.505, -8.09]} zoom={5}>
                        {/* <TileLayer
                            // attribution='&copy; <a href="http://stamen.com">Stamen</a> contributors'
                            attribution='&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                        /> */}
                        <TileLayer
                            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
                            noWrap={true}
                        />
                        <MapComponent pois={pois} setPoi={setPoiToUpdate}/>

                    </MapContainer>
                </Grid>

                {/* <Grid item xs={5} sx={{backgroundImage: `url(${background})`}} height={'91vh'} overflow={'auto'} > */}
                <Grid item xs={5} height={'91vh'} overflow={'auto'} >
                    <Container  sx={{paddingTop:'67px'}} maxWidth={"xl"}>
                        <Box justifyContent={"center"} sx={{ marginTop: '2%', height:'5%', display: 'flex' }}>
                            <Typography variant = "h5" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                Update Your Point Of Interest
                            </Typography>
                        </Box>
                        {
                            loading && <Container className='loadingContainerCard'>
                            <div className='loadingDiv'>
                                <CircularProgress color="inherit" />
                                <div>Loading...</div>
                            </div>
                            </Container>
                        }
                        {!loading &&
                        <Box>
                            <Box className={"select-box"}>
                                {pois && <Autocomplete
                                    disablePortal
                                    options={pois.map((poi) => { return (poi.name)})}//.sort((a, b) => (a > b) ? 1 : -1)} //sort alphabetically
                                    sx={{ width: 300 }}
                                    value={poiToUpdate ? poiToUpdate.name : ''}
                                    isOptionEqualToValue={() => true} //to avoid warning since '' is not present in options
                                    onChange={(_,value) => {

                                        if(value && value !== '') {
                                            const poi: IPointOfInterest = pois.find((poi) => poi.name === value) as IPointOfInterest; //it is never undefined, it always finds it because the list given is of existing pois
                                            setPoiToUpdate(poi);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Choose a Point of Interest" />}
                                />}
                            </Box>
                            <UpdatePOITabs poiToUpdate={poiToUpdate}/>
                        </Box>
                        }
                    </Container>
                    <Snackbar
                        open={openGenSnackbar}
                        autoHideDuration={2000}
                        message={messageInfo}
                        onClose = {() => setOpenGenSnackbar(false)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';

import {
    Button,
    Box,
    Typography,
    Grid,
    TextField,
    Link,
    Input,
    Divider,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tabs,
    Tab,
    Autocomplete,
    Container,
    CircularProgress
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {useCard, useFile, usePointOfInterest} from "../../hooks";
import { CardLevel, CardType, KsFields, SsFields, TeFields, TimeUnits } from './Card';
import {ICard, IFile, IFileUpdate, IKeyword, IPointOfInterest, IVideo} from "../../interfaces";
import { Snackbar } from '@mui/material';
import {Add, Clear, Delete} from '@mui/icons-material';
import {IHyperlink} from "../../interfaces/IHyperlink";
import { useParams, useNavigate } from 'react-router-dom';
import ConfirmationDialog,  {DialogProps} from '../ConfirmationDialog';
import { hyperlinkTypes } from './Card';
import { IAuthor } from '../../interfaces/IAuthor';

const snackBarMessage = {
    info: 'Card updated successfully!',
    emptyTitle: 'Please write a title for the card.',
    emptyBrief: 'Please write a briefing.',
    emptyTime: 'Please define the time needed to complete the card.',
    emptyTimeUnit: 'Please define the time unit.',
    emptyLevel: 'Please define the level for the card.',
    emptyType: 'Please define the type of the card.',
    emptyToolsAndEquipment: 'Please define the tools and equipment or resources needed for the card.',
    emptyTeField: 'Please define if you want tools and equipment or resources for the field.',
    emptyKnowledgeAndSkills: 'Please define the Knowledge and Skills or the Linked to Pedagogical Theories of the card.',
    emptyKsField: 'Please define if you want Knowledge and Skills or the Linked to Pedagogical Theories for the field.',
    emptyMaterials: 'Please define the materials needed for the card.',
    emptyStepByStep: 'Please define at least one step for the card or one item in the Timeline.',
    emptySsField: 'Please define if you want Step by Step or Timeline for the field.',
    emptyHyperlink: 'Please write the hyperlink to add first.',
    emptyTitleHyperlink: 'Please give the hyperlink a title first.',
    delete: 'Card deleted successfully!',
    incompleteInfo: 'Please fill the identification info before creating the card.',
    noKeywords: 'Please add at least one keyword to the point.',
    repeatedKeyword: 'Cannot add the same keyword twice.',
    keywordDeleted: 'Keyword was deleted successfully!',
    emptyKeyword: 'Please write or select a keyword to add first.',
    repeatedHyperlink: 'Cannot add the same hyperlink twice.',
    hyperlinkDeleted: 'Hyperlink was deleted successfully!',
    repeatedHlTitle: 'Cannot add the same hyperlink title twice.',
    cardExists: 'A card with this name already exists.',
    images: 'Images updated successfully!',
    imageDeleted: 'Image was deleted successfully!',
    maxImages: 'The maximum number of images allowed is 9.',
    videos: 'Videos updated successfully!',
    maxVideos: 'The maximum number of videos allowed is 7.',
    videoDeleted: 'Video was deleted successfully!',
    noContent: 'Please select an image on all the images that you want to add.',
    videoWrongUrlFormat: 'Please put video url in the original format (with embed)',
    videoUpdateWrongUrl: 'Please make sure you only update videos that have a valid url.',
    noCardChosen: 'Please select an existing Card to update.',
    noImages: 'Please add at least one image to the card.',
    noVideos: 'Please add at least one video to the card.',
    noAuthor: 'Please make sure you add the name and email of the author or the author\'s insitution.',
    noHyperlinks: 'Please add at least one Inspiration and one Next Step hyperlink.'
}

const dialogInfo = {
    subTitle: 'This operation cannot be undone.',
    cardTitle: 'Delete this Card?',
    videoTitle: 'Delete this video?',
    imageTitle: 'Delete this image?',
    keywordTitle: 'Delete this keyword?',
    hyperlinkTitle: 'Delete this hyperlink?'
}

const url_type = "card";

interface Props {
    keywords: IKeyword[],
    getAllKeywords: () => Promise<void>
}

export const UpdateCardPage: React.FC<Props> = ({keywords, getAllKeywords}) => {

    const navigate = useNavigate();
    const { id } = useParams<string>();
    const cardIdNumber = Number( id );

    interface IHyperlinkDB {
        hyperlink: IHyperlink
        isInDB: boolean
    }

    interface IKeywordDB {
        keyword: string
        isInDB: boolean
    }

    interface IVideoDB {
        video: IVideo
        isInDB: boolean
    }

    const [openGenSnackbar, setOpenGenSnackbar] = useState(false);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false); //important because if it was in the parent component it would delete possible user input, therefore we do not use the general one here
    const [localSnackbarMsg, setLocalSnackbarMsg] = useState('');

    const [cardToUpdate, setCardToUpdate] = useState<ICard>();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [title,setTitle] = useState<string>('');
    const [brief, setBrief] = useState<string>('');
    const [cardKeywords, setCardKeywords] = useState<IKeywordDB[]>([]);
    const [timeToComplete, setTimeToComplete] = useState<number>(0);
    const [timeUnit, setTimeUnit] = useState<string>(TimeUnits.DAYS);
    const [level, setLevel] = useState(CardLevel.BEGINNER);
    const [type, setType] = useState(CardType.ACTIVITY);
    const [teField, setTeField] = useState<string>(TeFields.RESOURCES)
    const [toolsAndEquipment, setToolsAndEquipment] = useState<string>('');
    const [ksField, setKsField] = useState<string>(KsFields.KNOWLEDGE);
    const [knowledgeAndSkills, setKnowledgeAndSkills] = useState<string>('');
    const [materials, setMaterials] = useState<string>('');
    const [ssField, setSsField] = useState<string>(SsFields.STEP);
    const [stepByStep, setStepByStep] = useState<string[]>([]);
    const [hyperlinks, setHyperlinks] = useState<IHyperlinkDB[]>([]);
    const [cardPois, setCardPois] = useState<IPointOfInterest[]>([]); 
    const [author, setAuthor] = useState<IAuthor>({name:'',email:''});

    const [images, setImages] = useState<IFileUpdate[]>([]);
    const [imagesBlobs, setImagesBlobs] = useState<File[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [videos, setVideos] = useState<IVideoDB[]>([]);

    const [authors, setAuthors] = useState<IAuthor[]>([]);

    const [openSnackbar,setOpenSnackbar] = useState<boolean>(false);
    const [messageInfo, setMessageInfo] = useState('');
    const [confirmDialog, setConfirmDialog] = useState<DialogProps>({isOpen: false, title: '', subTitle: '' , execFunc: () => {}, funcParam: 0});
    const [openVideoSnackbar, setOpenVideoSnackbar] = useState(false);
    const [videoSnackbarMsg, setVideoSnackbarMsg] = useState('');

    const {getCardById , updateCard, deleteCard , updateCardVideos , deleteCardVideo , removeHyperlinkFromCard, removeKeywordFromCard, getAllAuthors} = useCard();
    const {deleteFile , getFile, uploadFromBlob } = useFile();
    const {poisName, getAllPointsOfInterestName} = usePointOfInterest();
    
    const getImages = useCallback( async (images: IFile[]) => {
        let filesUpdate: IFileUpdate[] = [];
        let filesBlobsUpdate: File[] = [];
        if (images) {
            const promises = images.map(file => getFile(url_type, file.uuid));
            const imagesData = await Promise.all(promises);
            imagesData.forEach((file, index) => {
                filesBlobsUpdate.push(file);
                filesUpdate.push({
                    caption: images[index].caption,
                    source: images[index].source,
                    url: URL.createObjectURL(file),
                    name: images[index].uuid,
                    isOld: true
                });
            });
            setImages(filesUpdate);
            setImagesBlobs(filesBlobsUpdate);
        }
    }, [getFile]);
    
    const fetchCard = useCallback( async () => {
        const cardData = await getCardById(cardIdNumber);
        setCardToUpdate(cardData);
    }, [cardIdNumber,getCardById]) 

    
    const fetchAuthors = useCallback(async () => {
        const result = await getAllAuthors();
        
        const filteredResult = result.filter((author: IAuthor) => author.name !== null && author.email !== null);
        
        setAuthors(filteredResult);
    }, [getAllAuthors]);
          

    useEffect(() => {
        fetchCard();
        fetchAuthors();
        getAllPointsOfInterestName();
      }, [cardIdNumber, fetchCard, fetchAuthors, getCardById, getAllPointsOfInterestName]);

    useEffect(()=> {
        if(cardToUpdate) {
            setTitle(cardToUpdate.title);
            setBrief(cardToUpdate.brief);
            let keywordsDB: IKeywordDB[] = cardToUpdate.keywords.map((keyword) => {return {keyword: keyword, isInDB: true} });
            setCardKeywords(keywordsDB);
            setTimeToComplete(cardToUpdate.timeToComplete);
            setTimeUnit(cardToUpdate.timeUnit);
            setLevel(cardToUpdate.level);
            setType(cardToUpdate.type);
            setTeField(cardToUpdate.teField);
            setToolsAndEquipment(cardToUpdate.toolsAndEquipment);
            setKsField(cardToUpdate.ksField);
            setKnowledgeAndSkills(cardToUpdate.knowledgeAndSkills);
            setMaterials(cardToUpdate.materials);
            setSsField(cardToUpdate.ssField);
            setStepByStep(cardToUpdate.stepByStep);
            let hyperlinksDB: IHyperlinkDB[] = cardToUpdate.hyperlinks.map((hl) => {return {hyperlink: { title: hl.title, url: hl.url, field: hl.field}, isInDB: true} });
            setHyperlinks(hyperlinksDB);
            if(cardToUpdate.author.name !== null && cardToUpdate.author.email !== null)
                setAuthor(cardToUpdate.author);
            
            getImages(cardToUpdate.images);
            
            let videosDB: IVideoDB[] = cardToUpdate.videos.map((video) => {return {video: video, isInDB: true}});
            setVideos(videosDB);
            setCardPois(cardToUpdate.pois);
        }
        setLoading(false);
    }, [cardToUpdate, getImages])
    /**/
    
    const [currentKeyword, setCurrentKeyword] = useState<string>('');

    const [currentCOHyperlink,setCurrentCOHyperlink] = useState<string>('');
    const [currentCOHlTitle, setCurrentCOHlTitle] = useState<string>('');
    const [currentCOHlType, setCurrentCOHlType] = useState<string>('');
    const [currentNSHyperlink,setCurrentNSHyperlink] = useState<string>('');
    const [currentNSHlTitle, setCurrentNSHlTitle] = useState<string>('');
    const [currentPoi, setCurrentPoi] = useState<IPointOfInterest>();

    const addVideoToList = () => {
        if (videos.length < 7)
            setVideos([...videos, {video: {url: '', title: '', credits: ''}, isInDB: false}]);

        else {
            setVideoSnackbarMsg(snackBarMessage.maxVideos);
            setOpenVideoSnackbar(true);
        }
    }

    const updateUrlField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.video.url = newValue;
        setVideos(newArr);
    }
    const updateTitleField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.video.title = newValue;
        setVideos(newArr);
    }

    const updateCreditsField = (index: number, newValue: string) => {
        let newArr = [...videos];
        let newObj = newArr[index];
        newObj.video.credits = newValue;
        setVideos(newArr);
    }

    const removeVideoFromArray = (index:number) => {
        videos.splice(index,1)
        setVideos([...videos]);
    }

    const removeVideo = async (index:number) => {
        if(cardToUpdate) {
            setLoading(true);
            if (videos[index].isInDB) {
                deleteCardVideo(cardToUpdate.id, videos[index].video.url).then(async ()=> {
                    removeVideoFromArray(index);
                    await updateCardState(snackBarMessage.videoDeleted, false);
                })
            }
            else {
                removeVideoFromArray(index);
            }     
        }
    }
    
    const updateVideos = useCallback(() => {
        const youtubeRegExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
        const customRegExp = /^.*\/embed\?bgcolor=.*&txtcolor=.*&tracks=(\w+).*&share-token=.*$/;

        videos.forEach((video)=>{
            if (video.video.url.match(youtubeRegExp)) {
                const match = video.video.url.match(youtubeRegExp);
                if (match && match[2].length === 11) {
                  const videoId = match[2];
                  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                  video.video.url = embedUrl;
                } else {
                  video.video.url = "";
                  setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                  setOpenVideoSnackbar(true);
                }
            } else if (video.video.url.match(customRegExp)) {
                const match = video.video.url.match(customRegExp);
                if (match && match[1] === 'true') {
                    const updatedUrl = video.video.url.replace(/tracks=true/, 'tracks=false');
                    video.video.url = updatedUrl;
                }
                } else {
                video.video.url = "";
                setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                setOpenVideoSnackbar(true);
                }
            }
        );
    
        setVideos(videos);
    }, [videos]);

    const sendUpdateVideos = async () => {
        if(cardToUpdate) {
            if (videos.some((v) => v.video.url === '')) {
                setVideoSnackbarMsg(snackBarMessage.videoUpdateWrongUrl);
                setOpenVideoSnackbar(true);
            }
            else {
                setLoading(true);
                updateCardVideos(cardToUpdate.id, videos.map((vidDB) => {return (vidDB.video)})).then(async ()=>{
                    await updateCardState(snackBarMessage.videos, false);
                })
            }
        }
    }

    useEffect(() => { //update all fields
        if (videos) {
            updateVideos();
        }
    }, [videos, updateVideos]);

    
    const addImageToList = () => {
        if (images.length < 9) {
            setImages([...images, {caption: '', source: '', url: '', name: '', isOld:false}]);
            setImagesBlobs([...imagesBlobs, new File([""], "", {type: "image/png, image/jpeg"})]);
        }
        else {
            setMessageInfo(snackBarMessage.maxImages);
            setOpenSnackbar(true);
        }
    }

    const removeImageFromArray = (index:number) => {
        images.splice(index,1)
        setImages([...images]);
        imagesBlobs.splice(index,1)
        setImagesBlobs([...imagesBlobs]);
    }

    const deleteFileFromBEAndArray = async (index: number) => {
        if (images[index].url !== "") {
            URL.revokeObjectURL(images[index].url);
        }
        if (images[index].isOld) {
            setLoading(true);
            deleteFile(url_type, images[index].name).then(async ()=>{
                removeImageFromArray(index);
                await updateCardState(snackBarMessage.imageDeleted, false);
            })
        }
        else {
            removeImageFromArray(index);
        }     
    }

    const updateCaptionField = (index: number, newValue: string) => {
        if(images[index].isOld === false) {
            let newArr = [...images];
            let newObj = newArr[index];
            newObj.caption = newValue;
            setImages(newArr);
        }
    }
    const updateSourceField = (index: number, newValue: string) => {
        if(images[index].isOld === false) {
            let newArr = [...images];
            let newObj = newArr[index];
            newObj.source = newValue;
            setImages(newArr);
        }
    }

    const selectedFileHandler = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const rawFile: File | null | undefined = event.currentTarget.files?.item(0);
    
        if (rawFile && images[index].isOld === false) {
            let tmpArr = [...imagesBlobs];
            tmpArr[index] = rawFile;
            setImagesBlobs (tmpArr);

            let newArr = [...images];
            let newObj = {...newArr[index]};
            newObj.name = rawFile.name;
            newObj.url = URL.createObjectURL(rawFile);
            newArr[index] = newObj;
            setImages(newArr);
        }
    }

    const sendFiles = async () => {
        if (!images.some((f) => f.isOld === false)) {
            setMessageInfo(snackBarMessage.noContent);
            setOpenSnackbar(true);
        }
        else {
            setLoading(true);
            let newFiles: IFileUpdate[] = [];
            let newFilesBlobs: File[] = [];
            images.forEach((file, index) => {
                if (!file.isOld) {
                    newFiles.push(file)
                    newFilesBlobs.push(imagesBlobs[index])
                }
            })
            // fileList.map((file) => file.isOld ? {} : file.isOld=true)
            await uploadFromBlob(newFiles, newFilesBlobs, url_type, cardToUpdate!.id).then(async ()=>{
                await updateCardState(snackBarMessage.images, false)
            })

        };
    }

    const clickOnFile = (index: number) => {
        document.getElementById(`file-input${index}`)?.click();
    }


    const handleAddStep = () => {
        setStepByStep([...stepByStep, '']);
    };

    const handleRemoveStep = (index: number) => {
        setStepByStep(stepByStep.filter((_, i) => i !== index));
    };

    const handleStepChange = (e: { target: { value: string; }; }, index: number) => {
        const newSteps = [...stepByStep];
        newSteps[index] = e.target.value;
        setStepByStep(newSteps);
    };
      
    const handleAddHyperlink = (currentHyperlink: string, setCurrentHyperlink: React.Dispatch<React.SetStateAction<string>>, currentHlTitle: string, setCurrentHlTitle: React.Dispatch<React.SetStateAction<string>>, field: string) => {
        if (currentHyperlink === '') {
            setMessageInfo(snackBarMessage.emptyHyperlink);
            setOpenSnackbar(true);
        }
        else if (currentHlTitle === '') {
            setMessageInfo(snackBarMessage.emptyTitleHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find(({hyperlink}) => hyperlink.url === currentHyperlink && hyperlink.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHyperlink);
            setOpenSnackbar(true);
        }
        else if (hyperlinks.find(({hyperlink}) => hyperlink.title === currentHlTitle && hyperlink.field === field)) {
            setMessageInfo(snackBarMessage.repeatedHlTitle);
            setOpenSnackbar(true);
        }
        else {
            setHyperlinks([...hyperlinks, {hyperlink: {title: currentHlTitle, url: currentHyperlink, field: field}, isInDB: false}]);
            setCurrentHyperlink('');
            setCurrentHlTitle('');
        }
    }

    const removeHyperlink = (hl: IHyperlinkDB) => {
        hyperlinks.splice(hyperlinks.indexOf(hl), 1);
        setHyperlinks([...hyperlinks]);
    }
    
    const removeHyperlinkFromDB = async (hl: IHyperlinkDB) => {
        removeHyperlink(hl);
        await removeHyperlinkFromCard(cardToUpdate!.id, hl.hyperlink.field, hl.hyperlink.title);

        await updateCardState(snackBarMessage.hyperlinkDeleted,false);
    }

    const handleAddKeyword = () => {

        if (currentKeyword === '') {
            setLocalSnackbarMsg(snackBarMessage.emptyKeyword);
            setOpenLocalSnackbar(true);
        }
        else if (cardKeywords.find( ({keyword}) => keyword === currentKeyword)) {
            setLocalSnackbarMsg(snackBarMessage.repeatedKeyword);
            setOpenLocalSnackbar(true);
        }
        else {
            setCardKeywords([...cardKeywords, {keyword: currentKeyword, isInDB: false}]);
            setCurrentKeyword('');
        }
    }
    
    const removeKeyword = (index:number) => {
        cardKeywords.splice(index,1);
        setCardKeywords([...cardKeywords]);
    }

    const removeKeywordFromDB = async (index:number) => {
        const keyword = cardKeywords[index];

        removeKeyword(index);

        if (keyword.isInDB) {
            setLoading(true);
            await removeKeywordFromCard(cardToUpdate!.id, keyword.keyword).then(async ()=> {
                await updateCardState(snackBarMessage.keywordDeleted,true);
            })
        }

    }

    const handleAddPoi = (poi: IPointOfInterest) => {
        if (poi && !cardPois.find((currentPoi) => currentPoi.id === poi.id)) {
            setCardPois((prevCardPois) => [...prevCardPois, poi]);
        }
    };

    const handleRemovePoi = (poi: IPointOfInterest) => {
        setCardPois((prevCardPois) => prevCardPois.filter((p) => p !== poi));
    };

    const handleAuthorChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        if (value !== null) {
            const selectedAuthor = authors.find((author) => author.name === value);
            if (selectedAuthor) {
                setAuthor(selectedAuthor);
            }
        }
      };

    const deleteAllCard = async () => {

        if(cardToUpdate) {
            setLoading(true)
            await deleteCard(cardToUpdate.id).then(()=> {
                setMessageInfo(snackBarMessage.delete);
                setOpenGenSnackbar(true);
                navigate('/cards');
            })
        }
        else {
            setMessageInfo(snackBarMessage.noCardChosen);
            setOpenGenSnackbar(true);
        }

    }

    const updateCardState = async (sbMessage: string, updateKeyword: boolean) => {
        await getCardById(cardToUpdate!.id).then(async (updatedCard) => {
            setCardToUpdate(updatedCard);  //update current poi
            // await getImages(updatedCard.images);
            if(updateKeyword)
                getAllKeywords(); //update keywords state
            setMessageInfo(sbMessage);
            setOpenGenSnackbar(true);
        });
    }

    const handleUpdateCardInfo = async () => {
        if(cardToUpdate) {
            if (title === "" ) {
                setMessageInfo(snackBarMessage.emptyTitle);
                setOpenSnackbar(true);
            }
            else if ( brief === "" ) {
                setMessageInfo(snackBarMessage.emptyBrief);
                setOpenSnackbar(true);
            }
            else if ( timeToComplete === 0 ) {
                setMessageInfo(snackBarMessage.emptyTime);
                setOpenSnackbar(true);
            }
            else if ( timeUnit === "" ) {
                setMessageInfo(snackBarMessage.emptyTimeUnit);
                setOpenSnackbar(true);
            }
            else if ( level === "" ) {
                setMessageInfo(snackBarMessage.emptyLevel);
                setOpenSnackbar(true);
            }
            else if ( type === "" ) {
                setMessageInfo(snackBarMessage.emptyType);
                setOpenSnackbar(true);
            }
            else if ( toolsAndEquipment === "" ) {
                setMessageInfo(snackBarMessage.emptyToolsAndEquipment);
                setOpenSnackbar(true);
            }
            else if ( teField === "" ) {
                setMessageInfo(snackBarMessage.emptyTeField);
                setOpenSnackbar(true);
            }
            else if ( knowledgeAndSkills === "" ) {
                setMessageInfo(snackBarMessage.emptyKnowledgeAndSkills);
                setOpenSnackbar(true);
            }
            else if ( ksField === "" ) {
                setMessageInfo(snackBarMessage.emptyKsField);
                setOpenSnackbar(true);
            }
            else if ( materials === "" ) {
                setMessageInfo(snackBarMessage.emptyMaterials);
                setOpenSnackbar(true);
            }
            else if ( stepByStep.length === 0 ) {
                setMessageInfo(snackBarMessage.emptyStepByStep);
                setOpenSnackbar(true);
            }
            else if ( ssField === "" ) {
                setMessageInfo(snackBarMessage.emptySsField);
                setOpenSnackbar(true);
            }

            // else if ( hyperlinks.length === 0) {
            //     setMessageInfo(snackBarMessage.noHyperlinks);
            //     setOpenSnackbar(true);
            // }
    
            // else if (images.length === 0) {
            //     setMessageInfo(snackBarMessage.noImages);
            //     setOpenSnackbar(true);
            // }
    
            else if (cardKeywords.length === 0) {
                setMessageInfo(snackBarMessage.noKeywords);
                setOpenSnackbar(true);
            }
    
            // else if (videos.length === 0) {
            //     setMessageInfo(snackBarMessage.noVideos);
            //     setOpenSnackbar(true);
            // }
    
            // else if (author.name === '' || author.email === '' ){
            //     setMessageInfo(snackBarMessage.noAuthor);
            //     setOpenSnackbar(true);
            // }
            else {
                setLoading(true);
                await updateCard({
                    id: cardToUpdate.id,
                    title: title,
                    brief: brief,
                    keywords: cardKeywords.map((kDB => {return (kDB.keyword)})),
                    timeToComplete: timeToComplete,
                    timeUnit: timeUnit,
                    level: level,
                    type: type,
                    toolsAndEquipment: toolsAndEquipment,
                    teField: teField,
                    knowledgeAndSkills: knowledgeAndSkills,
                    ksField: ksField,
                    materials: materials,
                    stepByStep: stepByStep,
                    ssField: ssField,
                    hyperlinks: hyperlinks.map((hlDB) => { return (hlDB.hyperlink)}),
                    images: [],
                    videos: [],
                    pois: cardPois,
                    author: author
                }).then(async () => await updateCardState(snackBarMessage.info,true))

            }
        }
        else {
            setMessageInfo(snackBarMessage.noCardChosen);
            setOpenGenSnackbar(true);
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    if (loading) 
    return (
      <Box sx={{ paddingTop: '67px' }}>
        <Container maxWidth={"xl"}>
          <Box justifyContent="center" sx={{ marginTop: '2%', height: '5%', display: 'flex' }}>
            <Typography variant="h5" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
              Update Card
            </Typography>
            <Container className='loadingContainerCard'>
              <div className='loadingDiv'>
                <CircularProgress color="inherit" />
                <div>Loading...</div>
              </div>
            </Container>
          </Box>
        </Container>
      </Box>
    );
    else
        return (
            <Box sx={{paddingTop:'67px'}}>
                <Box justifyContent={"center"} sx={{ marginTop: '2%', height:'5%', display: 'flex' }}>
                    <Typography variant = "h5" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                        Update Card
                    </Typography>
                </Box>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="General Card Info"/>
                    <Tab label="Images"/>
                    <Tab label="Videos"/>
                    <Tab label="Delete Card"/>
                </Tabs>
                <Box sx={{paddingLeft:'12px', paddingRight:'12px'}}>
                {selectedTab === 0 && 
                <Box height={'56%'}>
                    <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                        Identification Info
                    </Typography>
                    <TextField
                        fullWidth
                        required
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.currentTarget.value)}
                    />

                    <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                        Craft Specific Info
                    </Typography>
                    <Box pt={1}>
                        <TextField fullWidth
                                multiline
                                maxRows={10}
                                label="Briefing"
                                helperText="Introduce a briefing for the card."
                                inputProps={{
                                    maxLength: 10000,
                                }}
                                value={brief}
                                onChange={(e) => setBrief(e.currentTarget.value)}
                        />
                    </Box>

                    {keywords && 
                    <Box>
                        <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                            Keywords
                        </Typography>

                        <Box className={'keyword-box-w-ac'}>

                            <Box width={'70%'} pt={1}>
                                <Autocomplete
                                    disablePortal
                                    options={keywords.map((keyword) => { return (keyword.name)})}
                                    value={currentKeyword}
                                    onChange={(_,value) => value && setCurrentKeyword(value)}
                                    isOptionEqualToValue={() => true} //to avoid warning since '' is not present in options
                                    renderInput={(params) => <TextField {...params} onChange={(e) => setCurrentKeyword(e.currentTarget.value)} label="Select or write a keyword" helperText={"Specific concept that describes the artist/place."}/>}
                                />
                            </Box>
                            <Button onClick={handleAddKeyword} variant={"contained"} color={"success"} className={'add-keyword-but'} sx={{height:'5%', marginTop: '1%', marginBottom: '1%'}}>Add Keyword</Button>
                            <Snackbar
                                open={openLocalSnackbar}
                                autoHideDuration={2000}
                                message={localSnackbarMsg}
                                onClose = {() => setOpenLocalSnackbar(false)}
                            />
                        </Box>
                        {cardKeywords.length !== 0 && 
                        <Box>
                            {cardKeywords.map((kw, index) => {
                                return (
                                    <Box key={'keyword'+index} pt={1} width={'70%'} className={'same-row'}>
                                        <Button variant={"contained"} color="secondary" sx={{cursor:'default', textTransform:'none',height:'5%', marginRight: '1%', borderRadius:'2em'}}>{kw.keyword}</Button>
                                        <Clear cursor={'pointer'} fontSize={'large'} color="action" className="center-icon" onClick={() => removeKeywordFromDB(index)} />
                                    </Box>
                                );
                            })}
                        </Box>}
                    </Box>}

                    <Box pt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Time Value"
                                    helperText="Please insert the duration value."
                                    value={timeToComplete}
                                    onChange={(e) => setTimeToComplete(parseFloat(e.currentTarget.value))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Time Unit</InputLabel>
                                    <Select
                                    value={timeUnit}
                                    onChange={(e) => setTimeUnit(e.target.value as string)}
                                    >
                                        <MenuItem value={TimeUnits.DAYS}>Days</MenuItem>
                                        <MenuItem value={TimeUnits.WEEKS}>Weeks</MenuItem>
                                        <MenuItem value={TimeUnits.MONTHS}>Months</MenuItem>
                                        <MenuItem value={TimeUnits.SEMESTERS}>Semesters</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
               
                    <Box pt={2}>
                        <FormControl fullWidth>
                            <InputLabel>Level</InputLabel>
                            <Select
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                            >
                            <MenuItem value={CardLevel.BEGINNER}>{CardLevel.BEGINNER}</MenuItem>
                            <MenuItem value={CardLevel.INTERMEDIATE}>{CardLevel.INTERMEDIATE}</MenuItem>
                            <MenuItem value={CardLevel.ADVANCED}>{CardLevel.ADVANCED}</MenuItem>
                            <MenuItem value={CardLevel.EXPERT}>{CardLevel.EXPERT}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box pt={2}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            >
                            <MenuItem value={CardType.ACTIVITY}>{CardType.ACTIVITY}</MenuItem>
                            <MenuItem value={CardType.LECTURE}>{CardType.LECTURE}</MenuItem>
                            <MenuItem value={CardType.TUTORIAL}>{CardType.TUTORIAL}</MenuItem>
                            <MenuItem value={CardType.PROJECT}>{CardType.PROJECT}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box pt={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Tools and Equipment or Resources</InputLabel>
                                <Select
                                value={teField}
                                onChange={(e) => setTeField(e.target.value as string)}
                                >
                                    <MenuItem value={TeFields.TOOLS}>Tools and Equipment</MenuItem>
                                    <MenuItem value={TeFields.RESOURCES}>Resources</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <TextField fullWidth
                                multiline
                                maxRows={2}
                                label={teField}
                                helperText="Please insert the tools and equipment or resources needed for this card."
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                value={toolsAndEquipment}
                                onChange={(e) => setToolsAndEquipment(e.currentTarget.value)}
                        />
                    </Box>

                    <Box pt={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Knowledge and Skills or Linked to Pedagogical Theories</InputLabel>
                                <Select
                                value={ksField}
                                onChange={(e) => setKsField(e.target.value as string)}
                                >
                                    <MenuItem value={KsFields.KNOWLEDGE}>Knowledge and Skills</MenuItem>
                                    <MenuItem value={KsFields.PEDAGOGICAL}>Linked to Pedagogical Theories</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <TextField fullWidth
                                multiline
                                maxRows={2}
                                label={ksField}
                                helperText="Please insert the knowledge and skills needed for this card or the linked to pedagogical theories."
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                value={knowledgeAndSkills}
                                onChange={(e) => setKnowledgeAndSkills(e.currentTarget.value)}
                        />
                    </Box>

                    <Box pt={2}>
                        <TextField fullWidth
                                multiline
                                maxRows={2}
                                label="Materials"
                                helperText="Please insert the materials needed for this card."
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                value={materials}
                                onChange={(e) => setMaterials(e.currentTarget.value)}
                        />
                    </Box>
                    <Box pt={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Step by step or Timeline</InputLabel>
                                <Select
                                value={ssField}
                                onChange={(e) => setSsField(e.target.value as string)}
                                >
                                    <MenuItem value={SsFields.STEP}>Step by Step</MenuItem>
                                    <MenuItem value={SsFields.TIMELINE}>Timeline</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {stepByStep.map((step, index) => {
                            return (
                                <Box key={'step'+index} display="flex" alignItems="center" mb={2}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={2}
                                    label={`Step ${index + 1}`}
                                    helperText="Please insert a step for this card."
                                    inputProps={{
                                    maxLength: 1000,
                                    }}
                                    value={step}
                                    onChange={(e) => handleStepChange(e, index)}
                                />
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={() => handleRemoveStep(index)}
                                >
                                    <CloseIcon />
                                </IconButton>
                                </Box>
                            )}
                        )}
                        <Box>
                            <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleAddStep}
                            >
                            Add Step
                            </Button>
                        </Box>
                    </Box>

                    <Box className={'hypelinks-checkout'}>
                        <br></br>
                        <Typography height={'20%'} variant = "h6" className={"subtitle"} > Inspiration </Typography>

                        <Box width={'70%'}>
                            <Box pt={1}>
                                <TextField fullWidth
                                        label="Checkout Hyperlink Title"
                                        value={currentCOHlTitle}
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        onChange={(e) => setCurrentCOHlTitle(e.currentTarget.value)}
                                />
                            </Box>
                            <Box pt={1}>
                                <TextField fullWidth
                                        label="Checkout Hyperlink"
                                        helperText="Clickable hyperlink to add to the learn more field."
                                        value={currentCOHyperlink}
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        onChange={(e) => setCurrentCOHyperlink(e.currentTarget.value)}
                                />
                            </Box>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Checkout type</InputLabel>
                                    <Select
                                    value={currentCOHlType}
                                    onChange={(e) => setCurrentCOHlType(e.target.value as string)}
                                    >
                                        <MenuItem value={hyperlinkTypes.checkoutvideo}>Video</MenuItem>
                                        <MenuItem value={hyperlinkTypes.checkoutdocument}>Document</MenuItem>
                                        <MenuItem value={hyperlinkTypes.checkoutwebsite}>Website</MenuItem>
                                        <MenuItem value={hyperlinkTypes.checkoutothers}>Others</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>

                        <Button onClick={() => handleAddHyperlink(currentCOHyperlink, setCurrentCOHyperlink, currentCOHlTitle, setCurrentCOHlTitle, currentCOHlType)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginTop: '1%', marginBottom: '1%', placeSelf: 'center'}}>Add Hyperlink</Button>
                    </Box>

                    {hyperlinks.filter((hl) => (hl.hyperlink.field === hyperlinkTypes.checkoutvideo || hl.hyperlink.field === hyperlinkTypes.checkoutdocument || hl.hyperlink.field === hyperlinkTypes.checkoutwebsite || hl.hyperlink.field === hyperlinkTypes.checkoutothers)).map((lmhl, index) => {
                        return (
                            <Box pt={1} width={'70%'} className={'same-row'} key={'hyperlink'+index}>
                                <Box>
                                    <Typography className={"text-info"}> {lmhl.hyperlink.field} </Typography>
                                    <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {lmhl.hyperlink.title}</Typography>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            window.open(lmhl.hyperlink.url, '_blank');
                                        }}
                                    >
                                        {lmhl.hyperlink.url}
                                    </Link>
                                </Box>
                                {!lmhl.isInDB ?
                                    <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                    : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: lmhl})}>Delete Hyperlink</Button>
                                }
                            </Box>
                        )
                    })}
                    <Box className={'hypelinks-next-steps'}>
                        <br></br>
                        <Typography height={'20%'} variant = "h6" className={"subtitle"} > Next Steps </Typography>

                        <Box width={'70%'}>
                            <Box pt={1}>
                                <TextField fullWidth
                                        label="Next Steps Hyperlink Title"
                                        value={currentNSHlTitle}
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        onChange={(e) => setCurrentNSHlTitle(e.currentTarget.value)}
                                />
                            </Box>

                            <Box pt={1}>
                                <TextField fullWidth
                                        label="Next Steps Hyperlink"
                                        helperText="Clickable hyperlink to add as next step."
                                        value={currentNSHyperlink}
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        onChange={(e) => setCurrentNSHyperlink(e.currentTarget.value)}
                                />
                            </Box>
                        </Box>

                        <Button onClick={() => handleAddHyperlink(currentNSHyperlink, setCurrentNSHyperlink, currentNSHlTitle, setCurrentNSHlTitle, hyperlinkTypes.nextSteps)} variant={"contained"} size={"large"} color={"success"} className={'add-hyperlink-but'} sx={{marginTop: '1%', marginBottom: '1%', placeSelf: 'center'}}>Add Hyperlink</Button>
                    </Box>

                    {hyperlinks.filter((hl) => hl.hyperlink.field === hyperlinkTypes.nextSteps).map((lmhl, index) => {
                        return (
                            <Box pt={1} width={'70%'} className={'same-row'} key={'hyperlink'+index}>
                                <Box>
                                    <Typography sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}> {lmhl.hyperlink.title}</Typography>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            window.open(lmhl.hyperlink.url, '_blank');
                                        }}
                                    >
                                        {lmhl.hyperlink.url}
                                    </Link>
                                </Box>
                                {!lmhl.isInDB ?
                                    <Clear cursor={'pointer'} fontSize={'medium'} color="action" className="center-icon" onClick={() => removeHyperlink(lmhl)} />
                                    : <Button variant={"contained"} color="warning" sx={{height:'5%'}} onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.hyperlinkTitle, subTitle: dialogInfo.subTitle, execFunc: removeHyperlinkFromDB as (param: number | IHyperlinkDB) => void, funcParam: lmhl})}>Delete Hyperlink</Button>
                                }
                            </Box>
                        )
                    })}

                    <Box>
                        <Typography variant="h6" pt={1} align="left" sx={{ fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                            Associated Points of Interest
                        </Typography>

                        <Box className="poi-association-box">
                            <Box width="70%" pt={1}>
                            <Autocomplete
                                disablePortal
                                options={poisName}
                                getOptionLabel={(option) => option.name}
                                value={undefined}
                                onChange={(_, value) => value && setCurrentPoi(value)}
                                isOptionEqualToValue={() => true}
                                renderInput={(params) => (
                                <TextField {...params} label="Select Points of Interest" helperText="Choose points of interest to associate with the card" />
                                )}
                            />
                            </Box>
                            <Button
                            onClick={() => {
                                // Perform any necessary action with the selected cards, such as saving them to the backend
                                if(currentPoi)
                                    handleAddPoi(currentPoi);
                            }}
                            variant="contained"
                            color="success"
                            className="add-poi-button"
                            sx={{ height: '5%', marginTop: '1%', marginBottom: '1%' }}
                            >
                            Add Card
                            </Button>
                        </Box>

                        {cardPois.length !== 0 && (
                            <Box>
                            {cardPois.map((poi, index) => (
                                <Box pt={1} width="70%" className="same-row" key={"poi"+index}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ cursor: 'default', textTransform: 'none', height: '5%', marginRight: '1%', borderRadius: '2em' }}
                                >
                                    {poi.name}
                                </Button>
                                <Clear cursor="pointer" fontSize="large" color="action" className="center-icon" onClick={() => handleRemovePoi(poi)} />
                                </Box>
                            ))}
                            </Box>
                        )}
                    </Box>

                    <Box>
                        <Typography variant = "h6" pt={1} align={"left"} sx={{fontFamily: 'KUniforma', fontWeight: 'bold' }}>
                            Author or author's institution
                        </Typography>
                        <Autocomplete
                            freeSolo
                            id="author_name"
                            disableClearable
                            value={author?.name}
                            options={authors.map((author) => {return (author.name)})}
                            onChange={handleAuthorChange}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e) => setAuthor(prevAuthor => ({...prevAuthor, name:e.currentTarget.value}))}
                                label="Name"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        />
                        <TextField
                            label="Email"
                            value={author.email}
                            onChange={(e) => setAuthor(prevAuthor => ({...prevAuthor, email:e.currentTarget.value}))}
                        />
                    </Box>

                    <Button onClick={handleUpdateCardInfo} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update Info</Button>
                </Box>}
                {selectedTab === 1 && 
                    <Box>
                        <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                            <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                Add Images To Your Card
                            </Typography>
                            <Button size={'small'} onClick={addImageToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                        </Box>
                        <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                            The number of files should be a maximum of <b>9</b> and have the format <b>JPEG</b> or <b>PNG</b>.
                            <br/>
                            The size limit for the images is <b>2240</b> (width) x <b>1680</b> (height) px.
                        </Typography>
                        {images.length !== 0 && images.map((file:IFileUpdate, index)=>{
                            return (
                                <Box key={'image'+index}>
                                    <Box className={'title-with-icon'}>
                                        <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                            Image {index + 1}
                                        </Typography>
                                        {/* TO DO*/}
                                        <Delete onClick={() => deleteFileFromBEAndArray(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                    </Box>
                                    <Box mb={'1%'}>
                                        <TextField fullWidth
                                                    required
                                                    label="Caption"
                                                    value={file.caption}
                                                    onChange={(e) => updateCaptionField(index,e.currentTarget.value)}
                                                    InputProps={{
                                                        readOnly: file.isOld
                                                    }}
                                        />
                                    </Box>
                                        <TextField fullWidth
                                                    required
                                                    label="Source"
                                                    value={file.source}
                                                    onChange={(e) => updateSourceField(index,e.currentTarget.value)}
                                                    InputProps={{
                                                        readOnly: file.isOld
                                                    }}
                                        />

                                    <Box className={'button-with-icon-below'}>
                                        {file.url!=="" && <img src={file.url}
                                                height={64}
                                                width={64}
                                                alt={'this is a preview'}
                                                style={{borderRadius:2, marginTop:'1%', alignSelf:'center'}}
                                                //  srcSet={`${file.url}?w=64&h=64&fit=crop&auto=format&dpr=2 2x`}
                                                loading="lazy"
                                        />}                                                                                                                                                                    {/*maybe not working*/}
                                        {!file.isOld && <Input onChange={(e: ChangeEvent<HTMLInputElement>) => selectedFileHandler(index, e)} id={`file-input${index}`} type="file" inputProps={{accept:"image/png, image/jpeg"}} sx={{height: 0, width: 0, display: 'none'}}/>}
                                        {!file.isOld && <Button onClick={() => clickOnFile(index)} variant={"contained"} sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}    } >Choose Image</Button>}
                                    </Box>
                                    <Divider sx={{marginTop:'2%', marginBottom:'2%', borderBottom:'solid', color:'gray', opacity:'0.7'}}/>
                                </Box>
                            )
                        })}
                        {images.length !== 0 && <Button onClick={sendFiles} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update Images</Button>}
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={2000}
                            message={messageInfo}
                            onClose = {() => setOpenSnackbar(false)}
                        />
                    </Box>
                }
                { selectedTab === 2 && 
                    <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
                        <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                            <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                Add Videos To Your Card
                            </Typography>
                            <Button size={'small'} onClick={addVideoToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                        </Box>
                        <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                            The number of videos should be a maximum of 7 and they should be already uploaded on youtube.
                        </Typography>
                        {videos.length !== 0 && <Box width={'100%'}>
                                {videos.map((video:IVideoDB, index)=>{
                                    return (
                                        <Box key={'video'+index}>
                                            <Box className={'title-with-icon'}>
                                                <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                                    Video {index + 1}
                                                </Typography>
                                                <Delete onClick={() => removeVideo(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                        required
                                                        label="Youtube Video URL"
                                                        value={video.video.url}
                                                        onChange={(e) => updateUrlField(index,e.currentTarget.value)}
                                                />
                                            </Box>
                                            <Box mb={'1%'}>
                                                <TextField fullWidth
                                                        required
                                                        label="Title"
                                                        value={video.video.title}
                                                        onChange={(e) => updateTitleField(index,e.currentTarget.value)}
                                                />
                                            </Box>

                                            <TextField fullWidth
                                                    required
                                                    label="Credits"
                                                    value={video.video.credits}
                                                    onChange={(e) => updateCreditsField(index,e.currentTarget.value)}
                                            />

                                            <Box className={'box-with-video-preview'}>
                                                {video.video.url !== '' && (video.video.url.includes('watch?v=') || video.video.url.includes('embed/')) &&
                                                    <iframe
                                                        width="200"
                                                        height="150"    //check if it is in the format    //sometimes google adds chanel= in url so it would be prefered for people to paste directly with the embed format or without this additional chanel=
                                                        title='youtube video'
                                                        src={video.video.url.includes('embed/') ? video.video.url : video.video.url.replace('watch?v=', 'embed/')} //transform in embed format
                                                        allow="autoplay; encrypted-media"
                                                        allowFullScreen
                                                        style = {{border: '0'}}>
                                                    </iframe>
                                                }
                                            </Box>
                                            <Button onClick={sendUpdateVideos} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update Videos</Button>
                                        </Box>
                                    )})
                                }
                            {/* <Button onClick={updateVideos} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Verify Videos</Button> */}
                        </Box>}
                        <Snackbar
                            open={openVideoSnackbar}
                            autoHideDuration={2000}
                            message={videoSnackbarMsg}
                            onClose = {() => setOpenVideoSnackbar(false)}
                        />
                    </Box>
                }
                { selectedTab === 3 && 
                <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
                    {cardToUpdate ? <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
                        <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '3%'}} >
                            <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                                Delete your Card
                            </Typography>
                        </Box>
                        <Button onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.cardTitle, subTitle: dialogInfo.subTitle, execFunc: deleteAllCard as ((param: number | IHyperlinkDB) => void), funcParam: cardToUpdate.id}) } size={'small'} variant={"contained"} sx={{width:'53%', backgroundColor: 'red'}} endIcon={<Delete/>} >Delete</Button>

                    </Box>
                    : <Typography variant = "h6" sx={{fontFamily: 'KUniforma'}}>
                        First select the Card which you want to delete.
                    </Typography>
                        }
                </Box>}
                <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}/>
                <Snackbar
                    open={openGenSnackbar}
                    autoHideDuration={2000}
                    message={messageInfo}
                    onClose = {() => setOpenGenSnackbar(false)}
                />
            </Box>
        </Box>
    );

};

import {FileTransferService} from "../services";
import {IFileUpdate} from "../interfaces";
import { useCallback } from "react";

export const useFile = () => {

    const uploadAndRetrieveUrls = useCallback( async (newImages: IFileUpdate[], type: string, id: number) => {
        const uploadFilePromises = newImages.map(async file => {
          let formData = new FormData();
          let imageDTOString = JSON.stringify({
            source: file.source,
            caption: file.caption
          });
          formData.append('content', await fetch(file.url).then(async r => {
            const blob = await r.blob();
            return new File([blob], file.name, { type: "image/png, image/jpeg" });
          }));
      
          formData.append('imageDTOString', imageDTOString);
      
          // Make sure `FileTransferService.uploadFile` returns a Promise that resolves when the upload is completed.
          return FileTransferService.uploadFile(type, formData, id);
        });
      
        // This will wait for all uploads to be completed before resolving.
        return Promise.all(uploadFilePromises);
      },[])
      

    const uploadFromBlob = useCallback ( async (newImages: IFileUpdate[], newImagesBlobs: File[], type:string, id: number ) => {
        let promises = newImages.map((file, index) => {
            let formData = new FormData();
            let imageDTOString = JSON.stringify({
                source: file.source,
                caption: file.caption
            });
            formData.append('content', newImagesBlobs[index]);
            formData.append('imageDTOString', imageDTOString);
            return FileTransferService.uploadFile(type, formData, id);
        })
        await Promise.all(promises);
    }, [])

    const deleteFile = async (type: string, imgUuid: string) => {
        await FileTransferService.deleteFile(type, imgUuid);
    }

    const deleteAllImages = async (type:string, imgUuids: string[]) => {
        const deletePromises = imgUuids.map((uuid) => deleteFile(type, uuid));
        await Promise.all(deletePromises);
      }
      
    const getFileUrl = useCallback (async (type: string, imgUuid: string) => {
        const {status,data} = await FileTransferService.getFile(type, imgUuid);
        if (status !== 200) throw new Error();
        
        return URL.createObjectURL(data)
    },[])

    const getFilePreviewUrl = useCallback (async (type: string, imgUuid: string) => {
      const {status,data} = await FileTransferService.getFilePreview(type, imgUuid);
      if (status !== 200) throw new Error();
      
      return URL.createObjectURL(data)
  },[])

    const getFile = useCallback (async (type: string, imgUuid: string) => {
        const {status,data} = await FileTransferService.getFile(type, imgUuid);
        if (status !== 200) throw new Error();
        
        return data
    },[])

    return {
        uploadAndRetrieveUrls,
        uploadFromBlob,
        deleteFile,
        deleteAllImages,
        getFileUrl,
        getFilePreviewUrl,
        getFile
    }
}
import React, { useEffect, useState } from "react";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import poi_preview from "../../assets/default_poi_preview.jpeg";
import {IPointOfInterest} from "../../interfaces";
import {useFile} from "../../hooks";
import { Link } from "react-router-dom";
import './POIPreview.css'
interface Props {
    poi: IPointOfInterest
    description: boolean
}

const type = "point-of-interest"

export const POIPreview:React.FC < Props > = ({poi, description}) => {
    const { getFilePreviewUrl } = useFile();
    const [imageUrl, setImageUrl] = useState<string>('');

    useEffect(() => {
        if (poi !== null ) {
            if(poi.images.length !== 0)
                getFilePreviewUrl(type, poi.images[0].uuid).then((url)=> {
                    setImageUrl(url);
                });
            else {
                setImageUrl('');
            }
        }
    }, [poi, getFilePreviewUrl]);

    return (
        <Link to={`/${poi.id}/overview`} style={{ textDecoration: 'none' }} >
            <Card className="cardPOIPreview">
                <CardMedia
                    component="img"
                    image={imageUrl !== '' ? imageUrl as string : poi_preview}
                    alt="POI Image"
                    className="cardPOIMediaPreview"
                />
                <CardContent className="cardContentPOI">
                    <Typography gutterBottom variant="h5" component="div" className="cardPOITypo">
                            {poi.name.length > 60 ?
                                `${poi.name.substring(0,poi.name.substring(0,60).lastIndexOf(" "))}...` : poi.name}
                        </Typography>

                    {description && <Typography variant="body2" color="text.secondary">
                        {poi.profile.length === 0 ? 'No additional information available.' : poi.profile.length > 200 ?
                            `${poi.profile.substring(0,poi.profile.substring(0,200).lastIndexOf(" "))}...` : poi.profile

                        }
                    </Typography>}
                </CardContent>
            </Card>
        </Link>
    )
}

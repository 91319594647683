import React from 'react';


export const ErrorPage:
React.FC = () => {
    return (
        <div>
            ERROR! Page not found. This URL is not valid.
        </div>
    );
}
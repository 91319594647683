import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, Divider, Input, Snackbar, TextField, Typography} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {IFile, IFileUpdate} from "../../interfaces";
import './FileUpload.css'
import {useFile} from "../../hooks";
import {DialogProps} from "../ConfirmationDialog";
import {dialogInfo} from "../Map/UpdatePOI";
import {IHyperlink} from "../../interfaces/IHyperlink";

interface Props {
    files:IFile[],
    updatePOIImages: (images:IFileUpdate[]) => void,
    // deletePOIImage: (imageUrl:string) => void,
    setConfirmDialog: React.Dispatch<React.SetStateAction<DialogProps>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>

}

interface IHyperlinkDB {
    hyperlink: IHyperlink
    isInDB: boolean
}

const snackBarMessage = {
    maxImages: 'The maximum number of images allowed is 3.',
    noContent: 'Please select an image on all the images that you want to update.'
}

const type = "point-of-interest";

export const FileUpload: React.FC < Props > = ({files, updatePOIImages, setConfirmDialog, setLoading}) => {

    const [fileList, setFileList] = useState<IFileUpdate[]>([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageInfo, setMessageInfo] = React.useState<string>('');

    const {deleteFile , getFileUrl} = useFile();

    const addFileToList = () => {
        if (fileList.length < 3)
            setFileList([...fileList, {caption: '', source: '', url: '', name: '', isOld:false}])
        else {
            setMessageInfo(snackBarMessage.maxImages);
            setOpenSnackbar(true);
        }
    }

    const clickOnFile = (index: number) => {
        document.getElementById(`file-input${index}`)?.click();
    }

    const sendFiles = () => {
        if (!fileList.some((f) => f.isOld === false)) {
            setMessageInfo(snackBarMessage.noContent);
            setOpenSnackbar(true);
        }
        else {
            let newFiles: IFileUpdate[] = [];
            fileList.map((file) => file.isOld ? {} : newFiles.push(file))
            updatePOIImages(newFiles)
        };
    }
    const selectedFileHandler = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const rawFile: File | null | undefined = event.currentTarget.files?.item(0);
    
        if (rawFile && fileList[index].isOld === false) {
            let newArr = [...fileList];
            let newObj = {...newArr[index]};
            newObj.name = rawFile.name;
            newObj.url = URL.createObjectURL(rawFile);
            newArr[index] = newObj;
            setFileList(newArr);
        }
    }

    
    const updateCaptionField = (index: number, newValue: string) => {
        if(fileList[index].isOld === false){
            let newArr = [...fileList];
            let newObj = newArr[index];
            newObj.caption = newValue;
            setFileList(newArr);
        }
    }
    const updateSourceField = (index: number, newValue: string) => {
        if(fileList[index].isOld === false){
            let newArr = [...fileList];
            let newObj = newArr[index];
            newObj.source = newValue;
            setFileList(newArr);
        }
    }

    /// TO DO
    const deleteFileFromBEAndArray = (index: number) => {
        URL.revokeObjectURL(fileList[index].url)
        if (fileList[index].isOld) {
            deleteFile(type, fileList[index].name);
            removeFromArray(index);
        }
        else {
            removeFromArray(index);
        }     
        // deletePOIImage(fileUrl);
    }

    const removeFromArray = (index:number) => {
        fileList.splice(index,1)
        setFileList([...fileList]);
    }

    useEffect(() => {
        let filesUpdate: IFileUpdate[] = [];

        if(files) {
            const promises = files.map(file => getFileUrl(type, file.uuid));
            Promise.all(promises).then((urls) => {
                files.forEach((file, index) => {
                    filesUpdate.push({
                        caption: file.caption,
                        source: file.source,
                        url: urls[index],
                        name: file.uuid,
                        isOld: true
                    });
                });
                setFileList(filesUpdate);
            });
        }
    }, [files, getFileUrl]);

    return (
        <Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Box className={'title-with-icon'} sx={{width:'100%', marginBottom: '2%'}} >
                    <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold', height:'10%'}}>
                        Add Images To Your Point
                    </Typography>
                    <Button size={'small'} onClick={addFileToList} variant={"contained"} endIcon={<Add/>} >New</Button>
                </Box>
                <Typography sx={{ fontFamily: 'KUniforma', color: 'gray', fontSize: '0.81em', opacity: '.8', height:'5%', width:'100%' }}>
                    The number of files should be a maximum of <b>3</b> and have the format <b>JPEG</b> or <b>PNG</b>.
                    <br/>
                    The size limit for the images is <b>2240</b> (width) x <b>1680</b> (height) px.
                </Typography>
                <Box width={'100%'}>
                    {fileList.length !== 0 && fileList.map((file:IFileUpdate, index)=>{
                        return (
                            <Box key={index}>
                                <Box className={'title-with-icon'}>
                                    <Typography variant = "h6" sx={{fontFamily: 'KUniforma', fontWeight: 'bold'}}>
                                        Image {index + 1}
                                    </Typography>
                                    {/* TO DO*/}
                                    {file.url !== '' ?
                                        <Button onClick={() => setConfirmDialog({isOpen:true, title: dialogInfo.imageTitle, subTitle: dialogInfo.subTitle, execFunc: deleteFileFromBEAndArray as (param: number | IHyperlinkDB) => void, funcParam: index})} variant={"contained"} sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%', backgroundColor: 'red'}    } >Delete Image</Button>
                                        : <Delete onClick={() => removeFromArray(index)} fontSize={'medium'} className={'icon-vertical-center'}/>
                                    }
                                </Box>
                                <Box mb={'1%'}>
                                    <TextField fullWidth
                                                required
                                                label="Caption"
                                                value={file.caption}
                                                onChange={(e) => updateCaptionField(index,e.currentTarget.value)}
                                                InputProps={{
                                                    readOnly: file.isOld
                                                }}
                                    />
                                </Box>
                                    <TextField fullWidth
                                                required
                                                label="Source"
                                                value={file.source}
                                                onChange={(e) => updateSourceField(index,e.currentTarget.value)}
                                                InputProps={{
                                                    readOnly: file.isOld
                                                }}
                                    />

                                <Box className={'button-with-icon-below'}>
                                    {file.url!=="" && <img src={file.url}
                                             height={64}
                                             width={64}
                                             alt={'this is a preview'}
                                             style={{borderRadius:2, marginTop:'1%', alignSelf:'center'}}
                                            //  srcSet={`${file.url}?w=64&h=64&fit=crop&auto=format&dpr=2 2x`}
                                             loading="lazy"
                                    />}                                                                                                                                                                    {/*maybe not working*/}
                                    {!file.isOld && <Input onChange={(e: ChangeEvent<HTMLInputElement>) => selectedFileHandler(index, e)} id={`file-input${index}`} type="file" inputProps={{accept:"image/png, image/jpeg"}} sx={{height: 0, width: 0, display: 'none'}}/>}
                                    {!file.isOld && <Button onClick={() => clickOnFile(index)} variant={"contained"} sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}    } >Choose Image</Button>}
                                </Box>
                                <Divider sx={{marginTop:'2%', marginBottom:'2%', borderBottom:'solid', color:'gray', opacity:'0.7'}}/>
                            </Box>
                        )
                    })}

                    {fileList.length !== 0 && <Button onClick={sendFiles} variant={"contained"} className="submit-button" sx={{height:'5%', marginTop: '1%', marginBottom: '0.5%'}}>Update Images</Button>}
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        message={messageInfo}
                        onClose = {() => setOpenSnackbar(false)}
                    />
                </Box>
            </Box>
        </Box>

    );
}
import React, { useState } from 'react';
import { Grid, TextField, Button, Box, Typography } from '@mui/material';
import background_image from '../assets/contact_us_backgroung.jpeg';
import './ContactPage.css';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

const userId = process.env.REACT_APP_USER_ID;
const serviceId = process.env.REACT_APP_SERVICE_ID;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function ContactPage() {
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  function sendEmail(e : React.FormEvent<HTMLFormElement>) {

    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA.");
      return;
    }
    if (!userId || !serviceId || !templateId) {
      // Handle missing environment variables or provide default values
      console.error("Environment variables not set.");
      // You may choose to throw an error or provide default values here
      return
    }
    console.log(e.currentTarget)
    
    emailjs.init(userId);

    emailjs.sendForm(serviceId, templateId, e.currentTarget)
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={0} md={6}>
        <Box
          className='contact_image'
          component="img"
          alt="Contact Image"
          src={background_image}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className='boxTitleForm'>
            <Typography variant="h2" className='contactTitle'>Join us</Typography>
            <Box className='contactBriefBox'>
                <Typography variant="body1" className='contactBrief'>
                    If you would like to join or find out more about Decoding Ceramics, please get in touch below.
                </Typography>
            </Box>
            <form className='formContact' noValidate autoComplete="off" onSubmit={sendEmail}>
              <TextField fullWidth name="contact_name" variant="outlined" className='formField' label="Name" margin="normal" />
              <TextField fullWidth name="contact_email" variant="outlined" className='formField' label="Email" margin="normal" />
              <TextField fullWidth name="contact_subject" variant="outlined" className='formField' label="Subject" margin="normal" />
              <TextField fullWidth name="contact_message" variant="outlined" className='formField' multiline rows={4} label="Message" margin="normal" />
              {recaptchaKey?
                <>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={value => setRecaptchaValue(value)}
                  />
                  <Button type="submit" variant="contained" className='buttonContact'>Contact us</Button>
                  </>
                :
                <>Not working - {process.env.RECAPTCHA_SITE_KEY}</>
              }
            </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactPage;